import React from 'react';

import Recaptcha from 'react-recaptcha';

import CheckBox from "../utils/Checkbox";
import {
    generateChangeButtonValueForCaringChild, generateChangeButtonValueForDisabilityChild,
    generateChangeButtonValueForNotCaringChild,
    generateElementIdForCaringChild,
    generateElementIdForClubTeamSocialGroup, generateElementIdForDisabilityChild,
    generateElementIdForNotCaringChild,
    generateElementIdForTransportationForm,
    multiSelectListAnyValueChecked,
    multiSelectListValueIsChecked
} from "../utils/HelperUtils";
import {isNumber, isValidDate, isValidHeight} from "../utils/Validators";
import BenefitNotCaringChild from "../utils/BenefitNotCaringChild";

/**
 * Component representing 'Page five: Review the form and information provided' (see https://10t3ux.axshare.com/)
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
class Step22 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            /*showNameDetails: true,*/
            showFirstNameDetails: true,
            showSurnameDetails: true,
            showHasAlternativeNameDetails: true,
            showAddressDetails: true,
            showAgeDetails: true,
            showNationalInsuranceDetails: true,
            showPhysicalCharacteristicsDetails: true,
            showSocialMediaDetails: true,
            modalName: '',
            changeButtonName: 'Change',
            /*changeButtonFirstName: 'Change',
            changeButtonSurname: 'Change',
            changeButtonHasAlternativeName: 'Change',
            changeButtonDoB: 'Change',
            changeButtonAddress: 'Change',
            changeButtonNationalInsuranceNumber: 'Change',
            changeButtonPhysicalCharacteristics: 'Change',
            changeButtonSocialMedia: 'Change',*/
            isCaptchaVerified: props.isCaptchaVerified
        }

        this.toggleDetails = this.toggleDetails.bind(this);
    }

    /**
     * Used for changing mode (show/edit)
     */
    toggleDetails = event => {
        const {name, value} = event.target;
        /*let value_ = (name === 'modalName' ? value : '');

        let buttonName = ''*/
        if (value === 'firstNameDetails' || value === 'surnameDetails' || value === 'hasAlternativeNameDetails'
                || value === 'addressDetails' || value === 'ageDetails' || value === 'nationalInsuranceDetails'
                || value === 'physicalDescriptionDetails' || value === 'socialMediaDetails') {
            this.props.handleStepChange(7, value)
        } else if (value === 'isSocialSecurityScotlandEmployee' || value === 'isSocialSecurityScotlandEmployeeJobRole' || value === 'isAnotherSocialSecurityScotlandEmployeeInvolved'
                    || value === 'isAnotherSocialSecurityScotlandEmployeeInvolvedFirstName' || value === 'isAnotherSocialSecurityScotlandEmployeeInvolvedSurname'
                    || value === 'isAnotherSocialSecurityScotlandEmployeeInvolvedJobTitle' || value === 'isAnotherSocialSecurityScotlandEmployeeInvolvedPhysicalDescription'
                    || value === 'isAnotherSocialSecurityScotlandEmployeeInvolvedAddress' || value === 'isAnotherSocialSecurityScotlandEmployeeInvolvedWhatMakesYouThinkIsInvolved') {
            this.props.handleStepChange(8, value)
        } else if (value === 'disabilityClaimForAnAdultWhatDisabilityDoYouThinkThePersonIsReceivingBenefitsFor' || value === 'disabilityClaimForAnAdultReceivingAnyHelpAtHome'
                    || value === 'disabilityClaimForAnAdultReceivingAnyHelpAtHomeMoreDetails' || value === 'disabilityClaimForAnAdultCanTheyLookAfterThemselves' || value === 'disabilityClaimForAnAdultUsingAnyMobilityEquipment'
                    || value === 'disabilityClaimForAnAdultUsingAnyMobilityEquipmentMoreDetails' || value === 'disabilityClaimForAnAdultIsThePersonEmployed' || value === 'disabilityClaimForAnAdultPersonEmployedTypeOfWorkTheyDo'
                    || value === 'disabilityClaimForAnAdultPersonEmployedHowLongEmployedFor' || value === 'disabilityClaimForAnAdultPersonEmployedWhoIsTheirEmployer'
                    || value === 'disabilityClaimForAnAdultPersonEmployedDoesTheEmployerKnowAboutTheirDisability' || value === 'disabilityClaimForAnAdultPersonEmployedDidTheEmployerMakeAnyAdjustmentsToSupportWithTheirDisability'
                    || value === 'disabilityClaimForAnAdultPersonEmployedDoTheyWorkFromHome' || value === 'disabilityClaimForAnAdultPersonEmployedDoesNotWorkFromHomeHowDoTheyTravelToWork'
                    || value === 'disabilityClaimForAnAdultDailyRoutineNormalDay' || value === 'disabilityClaimForAnAdultDailyRoutineNormalDayNotEligibleForBenefit'
                    || value === 'disabilityClaimForAnAdultDoTheyBelongToClubsTeamsSocialGroups' || value === 'disabilityClaimForAnAdultMainFormsOfTransportation' || value === 'disabilityClaimForAnAdultDetailsOfTheirTransportation'
                    || value === 'disabilityClaimForAnAdultFurtherInformation') {
            this.props.handleStepChange(9, value)
        } else if (value.startsWith("doubtfulDisabilityClaimForChildrenChild", 0) || value === 'disabilityClaimForChildrenDisabilityReceivingBenefitsFor'
                    || value === 'disabilityClaimForChildrenDisabilityEligibilityDoubtingReason' || value === 'disabilityClaimForChildrenDisabilityBenefitFraudDuration'
                    || value === 'disabilityClaimForChildrenUseAnyMobilityVehicles' || value === 'disabilityClaimForChildrenMobilityVehicleDetails' || value === 'disabilityClaimForChildrenOtherInformation') {
            this.props.handleStepChange(10, value)
        } else if (value.startsWith("paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonCurrentlyCaringForChildOrChildrenChild", 0)
                    || value === 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonCurrentlyCaringForChildOrChildrenMoreDetails') {
            this.props.handleStepChange(12, value)
        } else if (value.startsWith("paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonNoLongerCaresForChildOrChildrenChild", 0)
                    || value === 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonNoLongerCaresForChildOrChildrenMoreDetails') {
            this.props.handleStepChange(13, value)
        } else if (value === 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonSaysTheyArePregnantButTheyAreNotMoreDetails') {
            this.props.handleStepChange(14, value)
        } else if (value === 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyOtherMoreDetails') {
            this.props.handleStepChange(15, value)
        } else if (value === 'benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOf' || value === 'benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOfFirstName'
                    || value === 'benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOfSurname' || value === 'benefitsReceivedForProvidingCareAddressSameAsOneOfThePersonYouAreReporting'
                    || value === 'benefitsReceivedForProvidingCareAddressOfThePersonBeingCaredOf' || value === 'benefitsReceivedForProvidingCareAnyOtherDetails') {
            this.props.handleStepChange(16, value)
        } else if (value === 'jobStartPaymentAreTheyCurrentlyEmployed' || value === 'jobStartPaymentWhereDoTheyWork' || value === 'jobStartPaymentBeenEmployedForTheLast6Months'
                    || value === 'jobStartPaymentWhereDidTheyWork' || value === 'jobStartPaymentExtraInfo') {
            this.props.handleStepChange(17, value)
        } else if (value === 'funeralSupportPaymentKnowTheNameOfTheDeceasedPerson' || value === 'funeralSupportPaymentDeceasedPersonFirstName' || value === 'funeralSupportPaymentDeceasedPersonSurname'
                    || value === 'funeralSupportPaymentKnowTheDateThePersonDied' || value === 'funeralSupportPaymentDateOfDeathOfPersonDied' || value === 'funeralSupportPaymentApproximateDateOfPersonDied'
                    || value === 'funeralSupportPaymentKnowTheDateOfTheFuneral' || value === 'funeralSupportPaymentDateOfFuneral' || value === 'funeralSupportPaymentApproximateDateOfFuneral'
                    || value === 'funeralSupportPaymentWhyApplicationMadeFalsely') {
            this.props.handleStepChange(18, value)
        } else if (value === 'usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentity' || value === 'usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentityFirstName'
                    || value === 'usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentitySurname' || value === 'usingFraudulentIdentityKnowDateOfBirthThePersonIsUsingWithThisIdentity'
                    || value === 'usingFraudulentIdentityDateOfBirthThePersonIsUsingWithThisIdentity' || value === 'usingFraudulentIdentityApproximateAgeThePersonIsUsingWithThisIdentity'
                    || value === 'usingFraudulentIdentityKnowAddressOrAddressesThePersonIsUsingWithThisIdentity' || value === 'usingFraudulentIdentityAddressUsedWithThisIdentity'
                    || value === 'usingFraudulentIdentityHasUsedFakeOrFalseDocumentsWithThisIdentity' || value === 'usingFraudulentIdentityMoreDetailsOnFakeOrFalseDocumentsUsedWithThisIdentity'
                    || value === 'usingFraudulentIdentityAnyOtherDetails') {
            this.props.handleStepChange(19, value)
        } else if (value === 'notLivingInScotlandCountryPersonIsLiving' || value === 'notLivingInScotlandCountryPersonIsLivingPermanentlyOrTemporarily' || value === 'notLivingInScotlandKnowPersonCurrentAddress'
                    || value === 'notLivingInScotlandHaveYouAlreadyGivenTheirAddressAbroad' || value === 'notLivingInScotlandCurrentAddressAbroad' || value === 'notLivingInScotlandKnowWhenTheyMovedToThisAddress'
                    || value === 'notLivingInScotlandKnowAddressInScotland' || value === 'notLivingInScotlandHaveYouAlreadyGivenTheirAddressInScotland' || value === 'notLivingInScotlandCurrentAddressInScotland'
                    || value === 'notLivingInScotlandAnyOtherDetails') {
            this.props.handleStepChange(20, value)
        } else if (value === 'additionalInformationHowDoYouKnowTheInformation' || value === 'additionalInformationWhenDidYouFirstLearnOfThisInformation'
                    || value === 'additionalInformationWhenDidYouLastKnowItToBeTrue' || value === 'additionalInformationOnlyPersonWhoKnowsTheInformation'
                    || value === 'additionalInformationHappyToActOnInformationProvided') {
            this.props.handleStepChange(21, value)
        }
        /*if (value === 'nameDetails') {
            buttonName = 'changeButtonName'
            {
                this.state.showNameDetails ?
                    this.setState({
                        ['showNameDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showNameDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        } else if (value === 'firstNameDetails') {
            buttonName = 'changeButtonFirstName'
            {
                this.state.showFirstNameDetails ?
                    this.setState({
                        ['showFirstNameDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showFirstNameDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        } else if (value === 'surnameDetails') {
            buttonName = 'changeButtonSurname'
            {
                this.state.showSurnameDetails ?
                    this.setState({
                        ['showSurnameDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showSurnameDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        } else if (value === 'hasAlternativeNameDetails') {
            buttonName = 'changeButtonHasAlternativeName'
            {
                this.state.showHasAlternativeNameDetails ?
                    this.setState({
                        ['showHasAlternativeNameDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showHasAlternativeNameDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        } else if (value === 'ageDetails') {
            buttonName = 'changeButtonDoB'
            {
                this.state.showAgeDetails ?
                    this.setState({
                        ['showAgeDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showAgeDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        } else if (value === 'addressDetails') {
            buttonName = 'changeButtonAddress'
            {
                this.state.showAddressDetails ?
                    this.setState({
                        ['showAddressDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showAddressDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        } else if (value === 'nationalInsuranceDetails') {
            buttonName = 'changeButtonNationalInsuranceNumber'
            {
                this.state.showNationalInsuranceDetails ?
                    this.setState({
                        ['showNationalInsuranceDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showNationalInsuranceDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        } else if (value === 'physicalCharacteristicsDetails') {
            buttonName = 'changeButtonPhysicalCharacteristics'
            {
                this.state.showPhysicalCharacteristicsDetails ?
                    this.setState({
                        ['showPhysicalCharacteristicsDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showPhysicalCharacteristicsDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        } else if (value === 'socialMediaDetails') {
            buttonName = 'changeButtonSocialMedia'
            {
                this.state.showSocialMediaDetails ?
                    this.setState({
                        ['showSocialMediaDetails']: false,
                        ['modalName']: value_,
                        [buttonName]: 'Save'
                    })
                    :
                    this.setState({
                        ['showSocialMediaDetails']: true,
                        ['modalName']: '',
                        [buttonName]: 'Change'
                    })
            }
        }*/

    }

    render() {
        if (this.props.currentStep !== 22) {
            return null
        } else {
            return (
                <React.Fragment>
                    <div className="step step-22">
                        <h1>Review and send report</h1>
                        <p>Before you send the report, take a couple of minutes to review the information you have given.</p>

                        {/*Personal Information*/}
                        <h2>About the person claiming benefits or awards</h2>

                        <dl className="govuk-summary-list">

                            {/*Reported Person - Personal Information: First name*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">First name</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="first-name-details">
                                        <p hidden={this.props.reportedPersonPersonalInfo.firstName === ''}>{(this.props.reportedPersonPersonalInfo.firstName !== '') ? this.props.reportedPersonPersonalInfo.firstName : '---'}</p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="firstNameDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> first name</span></button>
                                </dd>
                            </div>

                            {/*Reported Person - Personal Information: First name (with fix on the spot)*/}
                            {/*<div className="govuk-summary-list__row">

                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">First name</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {
                                        (this.state.showFirstNameDetails === false) ?
                                            <div className="vertical-inputs" id="editable-first-name-details">
                                                <label htmlFor="reportedPersonPersonalInfo.firstName" className="form-text">First name (<span className="red">required</span>)</label>
                                                <input type="text"
                                                       autoFocus
                                                       maxLength={this.props.maxLengthForText}
                                                       required={true}
                                                       name="reportedPersonPersonalInfo.firstName"
                                                       id="reportedPersonPersonalInfo.firstName"
                                                       value={this.props.reportedPersonPersonalInfo.firstName}
                                                       className={this.props.errorClass.reportedPersonPersonalInfofirstName}
                                                       onChange={this.props.handleObjectChange} />
                                            </div>
                                            :
                                            <div className="" id="first-name-details">
                                                <p hidden={this.props.reportedPersonPersonalInfo.firstName === ''}>{(this.props.reportedPersonPersonalInfo.firstName !== '') ? this.props.reportedPersonPersonalInfo.firstName : '---'}</p>
                                            </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="firstNameDetails"
                                            disabled={this.props.reportedPersonPersonalInfo.firstName === ''}
                                            onClick={this.toggleDetails}>{this.state.changeButtonFirstName}<span className="visually-hidden"> first name</span></button>
                                </dd>

                            </div>*/}

                            {/*Reported Person - Personal Information: Surname*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Surname</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="surname-details">
                                        <p hidden={this.props.reportedPersonPersonalInfo.surname === ''}>{(this.props.reportedPersonPersonalInfo.surname !== '') ? this.props.reportedPersonPersonalInfo.surname : ''}</p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="surnameDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> surname</span></button>
                                </dd>
                            </div>

                            {/*Reported Person - Personal Information: Surname (with fix on the spot)*/}
                            {/*<div className="govuk-summary-list__row">

                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Surname</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {
                                        (this.state.showSurnameDetails === false) ?
                                            <div className="vertical-inputs" id="editable-surname-details">
                                                <label htmlFor="reportedPersonPersonalInfo.surname" className="form-text">Surname (<span className="red">required</span>)</label>
                                                <input type="text"
                                                       autoFocus
                                                       maxLength={this.props.maxLengthForText}
                                                       required={true}
                                                       name="reportedPersonPersonalInfo.surname"
                                                       id="reportedPersonPersonalInfo.surname"
                                                       value={this.props.reportedPersonPersonalInfo.surname}
                                                       className={this.props.errorClass.reportedPersonPersonalInfosurname}
                                                       onChange={this.props.handleObjectChange} />
                                            </div>
                                            :
                                            <div className="" id="surname-details">
                                                <p hidden={this.props.reportedPersonPersonalInfo.surname === ''}>{(this.props.reportedPersonPersonalInfo.surname !== '') ? this.props.reportedPersonPersonalInfo.surname : '---'}</p>
                                            </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="surnameDetails"
                                            disabled={this.props.reportedPersonPersonalInfo.surname === ''}
                                            onClick={this.toggleDetails}>{this.state.changeButtonSurname}<span className="visually-hidden"> surname</span></button>
                                </dd>

                            </div>*/}

                            {/*Reported Person - Personal Information: Has alternative name - Alternative name*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Does the person go by any other name(s)?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="has-alternative-name-details">
                                        <p hidden={this.props.reportedPersonPersonalInfo.hasAlternativeName === ''}>{(this.props.reportedPersonPersonalInfo.hasAlternativeName !== '') ? this.props.reportedPersonPersonalInfo.hasAlternativeName : ''}</p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.hasAlternativeName !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.alternativeName === ''}>
                                            {(this.props.reportedPersonPersonalInfo.alternativeName !== '') ? this.props.reportedPersonPersonalInfo.alternativeName : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="hasAlternativeNameDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> alternative name</span></button>
                                </dd>
                            </div>

                            {/*Reported Person - Personal Information: Has alternative name - Alternative name (with fix on the spot)*/}
                            {/*<div className="govuk-summary-list__row">

                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Does the person go by any other name(s)?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {
                                        (this.state.showHasAlternativeNameDetails === false) ?
                                            <div className="vertical-inputs" id="editable-has-alternative-name-details">
                                                <fieldset aria-labelledby="reportedPersonPersonalInfoHasAlternativeName" className="vertical-inputs">
                                                    <legend id="reportedPersonPersonalInfoHasAlternativeName">Has alternative name?</legend>
                                                    <input type="radio"
                                                           name="reportedPersonPersonalInfo.hasAlternativeName"
                                                           id="reportedPersonPersonalInfo.hasAlternativeNameYes"
                                                           value="Yes"
                                                           checked={this.props.reportedPersonPersonalInfo.hasAlternativeName === 'Yes'}
                                                           onChange={this.props.handleObjectChange} />
                                                    <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameYes" className="form-radio">Yes</label>

                                                    <input type="radio"
                                                           name="reportedPersonPersonalInfo.hasAlternativeName"
                                                           id="reportedPersonPersonalInfo.hasAlternativeNameNo"
                                                           value="No"
                                                           checked={this.props.reportedPersonPersonalInfo.hasAlternativeName === 'No'}
                                                           onChange={this.props.handleObjectChange} />
                                                    <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameNo" className="form-radio">No</label>

                                                    <input type="radio"
                                                           name="reportedPersonPersonalInfo.hasAlternativeName"
                                                           id="reportedPersonPersonalInfo.hasAlternativeNameDoNotKnow"
                                                           value="I don't know"
                                                           checked={this.props.reportedPersonPersonalInfo.hasAlternativeName === 'I don't know'}
                                                           onChange={this.props.handleObjectChange} />
                                                    <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameDoNotKnow" className="form-radio">I don't know</label>
                                                </fieldset>

                                                <div hidden={this.props.reportedPersonPersonalInfo.hasAlternativeName !== 'Yes'}>
                                                    <label htmlFor="reportedPersonPersonalInfo.alternativeName" className="form-text">Alternative name</label>
                                                    <input type="text"
                                                           maxLength={this.props.maxLengthForText}
                                                           name="reportedPersonPersonalInfo.alternativeName"
                                                           id="reportedPersonPersonalInfo.alternativeName"
                                                           value={this.props.reportedPersonPersonalInfo.alternativeName}
                                                           onChange={this.props.handleObjectChange} />
                                                </div>
                                            </div>
                                            :
                                            <div className="" id="has-alternative-name-details">
                                                <p hidden={this.props.reportedPersonPersonalInfo.hasAlternativeName === ''}>{(this.props.reportedPersonPersonalInfo.hasAlternativeName !== '') ? this.props.reportedPersonPersonalInfo.hasAlternativeName : '---'}</p>
                                                <p hidden={this.props.reportedPersonPersonalInfo.alternativeName === ''}>{(this.props.reportedPersonPersonalInfo.alternativeName !== '') ? this.props.reportedPersonPersonalInfo.alternativeName : '---'}</p>
                                            </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="hasAlternativeNameDetails"
                                            disabled={this.props.reportedPersonPersonalInfo.hasAlternativeName === ''}
                                            onClick={this.toggleDetails}>{this.state.changeButtonHasAlternativeName}<span className="visually-hidden"> surname</span></button>
                                </dd>

                            </div>*/}

                            {/*Reported Person - Personal Information: Address*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Address</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="address-details">
                                        <p hidden={this.props.reportedPersonPersonalInfo.addressHouseNumber === ''}>{(this.props.reportedPersonPersonalInfo.addressHouseNumber !== '') ? this.props.reportedPersonPersonalInfo.addressHouseNumber : ''}</p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.addressStreetName === ''}>{(this.props.reportedPersonPersonalInfo.addressStreetName !== '') ? this.props.reportedPersonPersonalInfo.addressStreetName : ''}</p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.addressCity === ''}>{(this.props.reportedPersonPersonalInfo.addressCity !== '') ? this.props.reportedPersonPersonalInfo.addressCity : ''}</p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.addressPostcode === ''}>{(this.props.reportedPersonPersonalInfo.addressPostcode) ? this.props.reportedPersonPersonalInfo.addressPostcode : ''}</p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="addressDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> address</span></button>
                                </dd>
                            </div>

                            {/*Reported Person - Personal Information: Age*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Do you know their date of birth?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="ageDetails">
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowDateOfBirth === ''}>{(this.props.reportedPersonPersonalInfo.knowDateOfBirth !== '') ? this.props.reportedPersonPersonalInfo.knowDateOfBirth : ''}</p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowDateOfBirth === ''
                                                    && this.props.reportedPersonPersonalInfo.dateOfBirthDay === ''
                                                    && this.props.reportedPersonPersonalInfo.dateOfBirthMonth === ''
                                                    && this.props.reportedPersonPersonalInfo.dateOfBirthYear === ''
                                                    && this.props.reportedPersonPersonalInfo.ageRange === ''}>
                                                {(this.props.reportedPersonPersonalInfo.knowDateOfBirth !== ''
                                                    || this.props.reportedPersonPersonalInfo.dateOfBirthDay !== ''
                                                    || this.props.reportedPersonPersonalInfo.dateOfBirthMonth !== ''
                                                    || this.props.reportedPersonPersonalInfo.dateOfBirthYear !== ''
                                                    || this.props.reportedPersonPersonalInfo.ageRange !== '')
                                                    ? beautifyDate(this.props.reportedPersonPersonalInfo.knowDateOfBirth, this.props.reportedPersonPersonalInfo.dateOfBirthDay, this.props.reportedPersonPersonalInfo.dateOfBirthMonth, this.props.reportedPersonPersonalInfo.dateOfBirthYear, this.props.reportedPersonPersonalInfo.ageRange)
                                                    : ''}</p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="ageDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> age</span></button>
                                </dd>
                            </div>

                            {/*Reported Person - Personal Information: National Insurance Number*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Do you know their National Insurance number?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="national-insurance-details">
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowNationalInsuranceNumber === ''}>{(this.props.reportedPersonPersonalInfo.knowNationalInsuranceNumber !== '') ? this.props.reportedPersonPersonalInfo.knowNationalInsuranceNumber : ''}</p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowNationalInsuranceNumber !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.nationalInsuranceNumber === ''}>
                                            {(this.props.reportedPersonPersonalInfo.nationalInsuranceNumber !== '') ? this.props.reportedPersonPersonalInfo.nationalInsuranceNumber : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="nationalInsuranceDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> National Insurance number</span></button>
                                </dd>
                            </div>

                            {/*Reported Person - Personal Information: Physical Description*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Can you provide a physical description?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="physical-description-details">
                                        <p hidden={this.props.reportedPersonPersonalInfo.canProvidePhysicalDescription === ''}>{(this.props.reportedPersonPersonalInfo.canProvidePhysicalDescription !== '') ? this.props.reportedPersonPersonalInfo.canProvidePhysicalDescription : ''}</p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.canProvidePhysicalDescription !== 'Yes'
                                                    || (this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet === '' && this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches === '')}>
                                            {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet !== '' || this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches !== '')
                                                ? beautifyPhysicalDescriptionFeetInches(this.props.reportedPersonPersonalInfo.canProvidePhysicalDescription, this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches)
                                                : ''}
                                        </p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.canProvidePhysicalDescription !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild === ''}>
                                            {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild !== '') ? this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild : ''}
                                        </p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.canProvidePhysicalDescription !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor === ''}>
                                            {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor !== '') ? this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor : ''}
                                        </p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.canProvidePhysicalDescription !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures === ''}>
                                            {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures !== '') ? this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="physicalDescriptionDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> physical description</span></button>
                                </dd>
                            </div>

                            {/*Reported Person - Personal Information: Social Media*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Do you know any of the person's social media accounts?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="social-media-details">
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowSocialMediaAccounts === ''}>{(this.props.reportedPersonPersonalInfo.knowSocialMediaAccounts !== '') ? this.props.reportedPersonPersonalInfo.knowSocialMediaAccounts : ''}</p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowSocialMediaAccounts !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.socialMediaFacebookName === ''}>
                                            {(this.props.reportedPersonPersonalInfo.socialMediaFacebookName !== '') ? "Facebook: ".concat(this.props.reportedPersonPersonalInfo.socialMediaFacebookName) : ''}
                                        </p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowSocialMediaAccounts !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.socialMediaInstagramName === ''}>
                                            {(this.props.reportedPersonPersonalInfo.socialMediaInstagramName !== '') ? "Instagram: ".concat(this.props.reportedPersonPersonalInfo.socialMediaInstagramName) : ''}
                                        </p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowSocialMediaAccounts !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.socialMediaTwitterName === ''}>
                                            {(this.props.reportedPersonPersonalInfo.socialMediaTwitterName !== '') ? "Twitter: ".concat(this.props.reportedPersonPersonalInfo.socialMediaTwitterName) : ''}
                                        </p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowSocialMediaAccounts !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.socialMediaSnapchatName === ''}>
                                            {(this.props.reportedPersonPersonalInfo.socialMediaSnapchatName !== '') ? "Snapchat: ".concat(this.props.reportedPersonPersonalInfo.socialMediaSnapchatName) : ''}
                                        </p>
                                        <p hidden={this.props.reportedPersonPersonalInfo.knowSocialMediaAccounts !== 'Yes'
                                                    || this.props.reportedPersonPersonalInfo.socialMediaOtherName === ''}>
                                            {(this.props.reportedPersonPersonalInfo.socialMediaOtherName !== '') ? "Other: ".concat(this.props.reportedPersonPersonalInfo.socialMediaOtherName) : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="socialMediaDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> social media</span></button>
                                </dd>
                            </div>

                        </dl>

                        {/*Social Security Scotland Employees*/}
                        <h2>Social Security Scotland Employees</h2>

                        <dl className="govuk-summary-list">

                            {/*Social Security Scotland Employee: is Reported Person an SSS Employee?*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Is the person who is claiming the benefit of award an employee of Social Security Scotland?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="is-social-security-scotland-employee">
                                        <p hidden={this.props.reportedPersonIsScottishGovEmployee === ''}>{(this.props.reportedPersonIsScottishGovEmployee !== '') ? this.props.reportedPersonIsScottishGovEmployee : ''}</p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="isSocialSecurityScotlandEmployee"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> is Social Security Scotland employee</span></button>
                                </dd>
                            </div>

                            {/*Social Security Scotland Employee: Reported Person Job Role (if is an SSS Employee)*/}
                            {(this.props.reportedPersonIsScottishGovEmployee === 'Yes') ?
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What is their job role or title?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="is-social-security-scotland-employee-job-role">
                                            <p hidden={this.props.reportedPersonIsScottishGovEmployee !== 'Yes'
                                                        || this.props.reportedPersonScottishGovEmployee.jobRoleOrTitle === ''}>
                                                {(this.props.reportedPersonScottishGovEmployee.jobRoleOrTitle !== '') ? this.props.reportedPersonScottishGovEmployee.jobRoleOrTitle : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="isSocialSecurityScotlandEmployeeJobRole"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> is Social Security Scotland employee Job Role</span></button>
                                    </dd>
                                </div>
                            : ''}

                            {/*Social Security Scotland Employee: another SSS Employee involved?*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Is there another employee of Social Security Scotland involved with the fraud?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="is-another-social-security-scotland-employee-involved">
                                        <p hidden={this.props.scottishGovEmployeeInvolved === ''}>{(this.props.scottishGovEmployeeInvolved !== '') ? this.props.scottishGovEmployeeInvolved : ''}</p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="isAnotherSocialSecurityScotlandEmployeeInvolved"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> is another Social Security Scotland employee involved</span></button>
                                </dd>
                            </div>

                            {/*Social Security Scotland Employee: SSS Employee First name (if another SSS Employee is involved)*/}
                            {(this.props.scottishGovEmployeeInvolved === 'Yes') ?
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">First name</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="is-another-social-security-scotland-employee-involved-first-name">
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.firstName === ''}>
                                                {(this.props.scottishGovEmployee.firstName !== '') ? this.props.scottishGovEmployee.firstName : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="isAnotherSocialSecurityScotlandEmployeeInvolvedFirstName"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> first name of the other involved Social Security Scotland employee</span></button>
                                    </dd>
                                </div>
                            : ''}

                            {/*Social Security Scotland Employee: SSS Employee Surname (if another SSS Employee is involved)*/}
                            {(this.props.scottishGovEmployeeInvolved === 'Yes') ?
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Surname</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="is-another-social-security-scotland-employee-involved-surname">
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.surname === ''}>
                                                {(this.props.scottishGovEmployee.surname !== '') ? this.props.scottishGovEmployee.surname : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="isAnotherSocialSecurityScotlandEmployeeInvolvedSurname"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> surname of the other involved Social Security Scotland employee</span></button>
                                    </dd>
                                </div>
                            : ''}

                            {/*Social Security Scotland Employee: SSS Employee Job title (if another SSS Employee is involved)*/}
                            {(this.props.scottishGovEmployeeInvolved === 'Yes') ?
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What is their job role or title?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="is-another-social-security-scotland-employee-involved-job-role">
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.jobTitle === ''}>
                                                {(this.props.scottishGovEmployee.jobTitle !== '') ? this.props.scottishGovEmployee.jobTitle : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="isAnotherSocialSecurityScotlandEmployeeInvolvedJobTitle"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> job title of the other involved Social Security Scotland employee</span></button>
                                    </dd>
                                </div>
                            : ''}

                            {/*Social Security Scotland Employee: SSS Employee physical description (if another SSS Employee is involved)*/}
                            {(this.props.scottishGovEmployeeInvolved === 'Yes') ?
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Physical description</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="is-another-social-security-scotland-employee-involved-physical-description">
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.physicalDescription === ''}>
                                                {(this.props.scottishGovEmployee.physicalDescription !== '') ? this.props.scottishGovEmployee.physicalDescription : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="isAnotherSocialSecurityScotlandEmployeeInvolvedPhysicalDescription"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> physical description of the other involved Social Security Scotland employee</span></button>
                                    </dd>
                                </div>
                            : ''}

                            {/*Social Security Scotland Employee: SSS Employee address (if another SSS Employee is involved)*/}
                            {(this.props.scottishGovEmployeeInvolved === 'Yes') ?
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Address</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="is-another-social-security-scotland-employee-involved-address">
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.addressHouseNumber === ''}>
                                                {(this.props.scottishGovEmployee.addressHouseNumber !== '') ? this.props.scottishGovEmployee.addressHouseNumber : ''}
                                            </p>
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.addressStreetName === ''}>
                                                {(this.props.scottishGovEmployee.addressStreetName !== '') ? this.props.scottishGovEmployee.addressStreetName : ''}
                                            </p>
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.addressCity === ''}>
                                                {(this.props.scottishGovEmployee.addressCity !== '') ? this.props.scottishGovEmployee.addressCity : ''}
                                            </p>
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.addressPostcode === ''}>
                                                {(this.props.scottishGovEmployee.addressPostcode !== '') ? this.props.scottishGovEmployee.addressPostcode : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="isAnotherSocialSecurityScotlandEmployeeInvolvedAddress"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> address of the other involved Social Security Scotland employee</span></button>
                                    </dd>
                                </div>
                            : ''}

                            {/*Social Security Scotland Employee: SSS Employee what makes you think is involved (if another SSS Employee is involved)*/}
                            {(this.props.scottishGovEmployeeInvolved === 'Yes') ?
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What makes you think this person is involved in the fraud you are reporting?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="is-another-social-security-scotland-employee-involved-what-makes-you-think-is-involved">
                                            <p hidden={this.props.scottishGovEmployeeInvolved !== 'Yes'
                                                        || this.props.scottishGovEmployee.whatMakesYouThinkPersonIsInvolved === ''}>
                                                {(this.props.scottishGovEmployee.whatMakesYouThinkPersonIsInvolved !== '') ? this.props.scottishGovEmployee.whatMakesYouThinkPersonIsInvolved : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="isAnotherSocialSecurityScotlandEmployeeInvolvedWhatMakesYouThinkIsInvolved"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> what makes you think another Social Security Scotland employee is involved in the fraud</span></button>
                                    </dd>
                                </div>
                            : ''}

                        </dl>

                        {/*Report a false or doubtful disability claim for an adult or a child*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Report a false or doubtful disability claim for an adult or a child")) ?
                            <h2>Report a false or doubtful disability claim for an adult or a child</h2>
                            : ''
                        }

                        {/*Report a false or doubtful disability claim for an adult*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Report a false or doubtful disability claim for an adult or a child")
                                && (this.props.childDisabilityUnder18 === 'No')) ?
                            <dl className="govuk-summary-list">

                                {/*Report a false or doubtful disability claim for an adult: what disability do you think the person is receiving benefits for?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What disability do you think the person is receiving benefits for?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-an-adult-what-disability-do-you-think-the-person-is-receiving-benefits-for">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultWhatDisabilityDoYouThinkThePersonIsReceivingBenefitsFor"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> what disability do you think the person is receiving benefits for</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for an adult: do they receive any help at home?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do they receive any help at home?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-an-adult-receiving-any-help-at-home">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultReceivingAnyHelpAtHome"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they receive any help at home</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for an adult: (if they receive any help at home) more details*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Details of the help they receive at home</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-receiving-any-help-at-home-more-details">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultReceivingAnyHelpAtHomeMoreDetails"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> details of the help they receive at home</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: (no matter if they receive any help at home or not) can they look after themselves?*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome !== '') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Can they look after themselves?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-can-they-look-after-themselves">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultCanTheyLookAfterThemselves"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they can look after themselves</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: do they use any mobility equipment?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do they use any mobility equipment?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-an-adult-using-any-mobility-equipment">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultUsingAnyMobilityEquipment"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they use any mobility equipment</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for an adult: (if they use any mobility equipment) more details of mobility equipment*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Details of mobility equipment</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-using-any-mobility-equipment-more-details">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultUsingAnyMobilityEquipmentMoreDetails"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the details of mobility equipment</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: is the person employed?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Is the person employed?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-an-adult-is-the-person-employed">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultIsThePersonEmployed"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if the person is employed</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for an adult: (if the person is employed) what type of work they do?*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">What type of work do they do?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-person-employed-type-of-work-they-do">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultPersonEmployedTypeOfWorkTheyDo"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the type of work they do</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: (if the person is employed) how long have they have been employed for?*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">How long have they been employed for?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-person-employed-how-long-employed-for">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultPersonEmployedHowLongEmployedFor"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> how long they have been employed for</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: (if the person is employed) who is their employer?*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Who is their employer?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-person-employed-who-is-their-employer">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultPersonEmployedWhoIsTheirEmployer"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> who is their employer</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: (if the person is employed) does their employer know about their disability?*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Does their employer know about their disability?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-person-employed-does-their-employer-know-about-their-disability">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultPersonEmployedDoesTheEmployerKnowAboutTheirDisability"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if their employer knows about their disability</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: (if the person is employed & does their employer knows about their disability) did employer make any adjustments for disability?*/}
                                {((this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'Yes')
                                        && (this.props.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability === 'Yes')) ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Did the employer make any adjustments to support them with their health condition or disability?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-person-employed-did-the-employer-make-any-adjustments-to-support-them-with-their-disability">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultPersonEmployedDidTheEmployerMakeAnyAdjustmentsToSupportWithTheirDisability"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if their employer made any adjustments to support with their disability</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: (if the person is employed) do they work from home?*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Do they work from home?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-person-employed-do-they-work-from-home">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultPersonEmployedDoTheyWorkFromHome"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they work from home</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: (if the person is employed & does not work from home) how do they travel to work?*/}
                                {((this.props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'Yes')
                                        && (this.props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome === 'No')) ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">How do they travel to work?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-person-employed-does-not-work-from-home-how-do-they-travel-to-work">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultPersonEmployedDoesNotWorkFromHomeHowDoTheyTravelToWork"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> how they travel to work</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: daily routine*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What do they do on a normal day?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-an-adult-daily-routine-normal-day">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultDailyRoutineNormalDay"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> what they do on a normal day</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for an adult: daily routine (not eligible for benefit)*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Thinking about the person's daily activities, what do they do to make you think they're not eligible for the benefit?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-an-adult-daily-routine-normal-day-not-eligible-for-benefit">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultDailyRoutineNormalDayNotEligibleForBenefit"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> what makes you think they're not eligible for the benefit, based on what they do on a normal day</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for an adult: do they belong to any team or social group?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do they belong to any clubs, teams or social groups?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-an-adult-do-they-belong-to-clubs-teams-social-groups">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup : ''}
                                            </p>
                                            {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup === 'Yes') ?
                                                this.props.reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups.map((child, index) => {
                                                    return (
                                                        <div className="" id={generateElementIdForClubTeamSocialGroup(child.id)}>
                                                            <br/>
                                                            <p hidden={child.name === ''}>
                                                                {(child.name !== '') ? child.name : ''}
                                                            </p>
                                                            <p hidden={child.location === ''}>
                                                                {(child.location !== '') ? child.location : ''}
                                                            </p>
                                                            <p hidden={child.howOftenTheyAttend === ''}>
                                                                {(child.howOftenTheyAttend !== '') ? child.howOftenTheyAttend : ''}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                                : ''
                                            }
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultDoTheyBelongToClubsTeamsSocialGroups"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they belong to any clubs, teams or social groups</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for an adult: what's their main form of transportation?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What's their main form of transportation?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        {
                                            this.props.reportedPersonBenefitPaymentForDisabilityAdult.transportationForms.map((child, index) => {
                                                return (
                                                    <div className="" id={generateElementIdForTransportationForm(child.id)} hidden={!child.isChecked}>
                                                        <p>{child.value}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultMainFormsOfTransportation"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> their main forms of transportation</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for an adult: details of transportation?*/}
                                {(multiSelectListAnyValueChecked(this.props.reportedPersonBenefitPaymentForDisabilityAdult.transportationForms)) ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Details of their transportation</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-an-adult-details-of-their-transportation">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForAnAdultDetailsOfTheirTransportation"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the details of their transportation</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for an adult: further information*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Is there anything else you think we should know about this?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-an-adult-further-information">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForAnAdultFurtherInformation"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if there is anything else you think we should know about this</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Report a false or doubtful disability claim for a child*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Report a false or doubtful disability claim for an adult or a child")
                                && (this.props.childDisabilityUnder18 === 'Yes')) ?
                            <dl className="govuk-summary-list">

                                {/*List of children*/}
                                {
                                    this.props.reportedPersonBenefitPaymentForDisabilityChildren.map((child, index) => {

                                        return (
                                            <div className="govuk-summary-list__row">
                                                <dt className="govuk-summary-list__key">
                                                    <span className="font-large">Child {child.id}</span>
                                                </dt>
                                                <dd className="govuk-summary-list__value">
                                                    <div className="" id={generateElementIdForDisabilityChild(child.id)}>
                                                        <p>{(child.firstName !== '') ? "First name: ".concat(child.firstName) : 'First name: '}</p>
                                                        <p>{(child.surname !== '') ? "Surname: ".concat(child.surname) : 'Surname: '}</p>
                                                        <p>{(child.hasAlternativeName !== '') ? "Does the child go by any other name(s)?: ".concat(child.hasAlternativeName) : 'Does the child go by any other name(s)?: '}</p>
                                                        <p hidden={child.hasAlternativeName !== 'Yes'}>
                                                            {(child.alternativeName !== '') ? "Alternative name: ".concat(child.alternativeName) : 'Alternative name: '}
                                                        </p>
                                                        <p>{(child.sameAddressAsReportedPerson !== '') ? "Is the child's address the same as the person reported?: ".concat(child.sameAddressAsReportedPerson) : 'Is the child\'s address the same as the person reported?: '}</p>
                                                        <p hidden={child.sameAddressAsReportedPerson !== 'No'}>
                                                            {(child.addressHouseNumber !== '') ? "House number or Building name: ".concat(child.addressHouseNumber) : 'House number or Building name: '}
                                                        </p>
                                                        <p hidden={child.sameAddressAsReportedPerson !== 'No'}>
                                                            {(child.addressStreetName !== '') ? "Street name: ".concat(child.addressStreetName) : 'Street name: '}
                                                        </p>
                                                        <p hidden={child.sameAddressAsReportedPerson !== 'No'}>
                                                            {(child.addressCity !== '') ? "City or Town: ".concat(child.addressCity) : 'City or Town: '}
                                                        </p>
                                                        <p hidden={child.sameAddressAsReportedPerson !== 'No'}>
                                                            {(child.addressPostcode !== '') ? "Postcode: ".concat(child.addressPostcode) : 'Postcode: '}
                                                        </p>
                                                    </div>
                                                </dd>
                                                <dd className="govuk-summary-list__actions">
                                                    <button type="button"
                                                            className="link--button"
                                                            name="modalName" value={generateChangeButtonValueForDisabilityChild(child.id)}
                                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any details for child {child.id}</span></button>
                                                </dd>
                                            </div>
                                        )
                                    })
                                }

                                {/*Report a false or doubtful disability claim for a child: what disability do you think the child/children is receiving benefit(s) for?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What disability do you think the child/children is receiving benefit(s) for?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-children-disability-receiving-benefits-for">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForChildrenDisabilityReceivingBenefitsFor"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the disability you think the child/children is receiving benefit for</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for a child: what makes you doubt their eligibility for the disability benefit?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What makes you doubt their eligibility for the disability benefit?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-children-disability-eligibility-doubting-reason">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForChildrenDisabilityEligibilityDoubtingReason"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the eligibility doubting reason for the disability benefit</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for a child: how long do you suspect this benefit fraud has been going for?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">How long do you suspect this benefit fraud has been going for?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-children-disability-benefit-fraud-duration">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForChildrenDisabilityBenefitFraudDuration"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> how long you suspect this benefit fraud has been going for</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for a child: do you know if they use any mobility vehicles?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know if they use any mobility vehicles?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-children-use-any-mobility-vehicles">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForChildrenUseAnyMobilityVehicles"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they use any mobility vehicles</span></button>
                                    </dd>
                                </div>

                                {/*Report a false or doubtful disability claim for a child: mobility vehicles details*/}
                                {(this.props.reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Vehicle details</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="doubtful-disability-claim-for-children-mobility-vehicle-details">
                                                <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails === ''}>
                                                    {(this.props.reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="disabilityClaimForChildrenMobilityVehicleDetails"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the mobility vehicle details</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Report a false or doubtful disability claim for a child: other information*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Other information</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="doubtful-disability-claim-for-children-other-information">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForDisabilityInformation.otherInformation === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForDisabilityInformation.otherInformation !== '') ? this.props.reportedPersonBenefitPaymentForDisabilityInformation.otherInformation : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="disabilityClaimForChildrenOtherInformation"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> other information</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Payments for pregnancy, or child responsibility*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for pregnancy, or child responsibility")) ?
                            <h2>Benefit payments for a child or pregnancy</h2>
                            : ''
                        }

                        {/*Payments for pregnancy, or child responsibility, Type of child or pregnancy*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for pregnancy, or child responsibility")
                                && (this.props.reportedPersonBenefitForChildOrPregnancy !== '')) ?
                            <h3>Type of child or pregnancy: {this.props.reportedPersonBenefitForChildOrPregnancy}</h3>
                            : ''
                        }

                        {/*Payments for pregnancy, or child responsibility, Type of child or pregnancy: A person currently caring for a child or children*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for pregnancy, or child responsibility")
                                && (this.props.reportedPersonBenefitForChildOrPregnancy === 'A person currently caring for a child or children')) ?
                            <dl className="govuk-summary-list">

                                {/*List of children*/}
                                {
                                    this.props.reportedPersonBenefitPaymentForCaringChildren.map((child, index) => {

                                        return (
                                            <div className="govuk-summary-list__row">
                                                <dt className="govuk-summary-list__key">
                                                    <span className="font-large">Child {child.id}</span>
                                                </dt>
                                                <dd className="govuk-summary-list__value">
                                                    <div className="" id={generateElementIdForCaringChild(child.id)}>
                                                        <p hidden={child.knowDetails === ''}>
                                                            {(child.knowDetails !== '') ? "Do you know the name of the child: ".concat(child.knowDetails) : 'Do you know the name of the child: '}
                                                        </p>
                                                        <p hidden={child.knowDetails !== 'Yes'}>
                                                            {(child.firstName !== '') ? "First name: ".concat(child.firstName) : 'First name: '}
                                                        </p>
                                                        <p hidden={child.knowDetails !== 'Yes'}>
                                                            {(child.surname !== '') ? "Surname: ".concat(child.surname) : 'Surname: '}
                                                        </p>
                                                    </div>
                                                </dd>
                                                <dd className="govuk-summary-list__actions">
                                                    <button type="button"
                                                            className="link--button"
                                                            name="modalName" value={generateChangeButtonValueForCaringChild(child.id)}
                                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any details for child {child.id}</span></button>
                                                </dd>
                                            </div>
                                        )
                                    })
                                }

                            </dl>
                            : ''
                        }

                        {/*Payments for pregnancy, or child responsibility, Type of child or pregnancy: A person currently caring for a child or children*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for pregnancy, or child responsibility")
                                && (this.props.reportedPersonBenefitForChildOrPregnancy === 'A person currently caring for a child or children')) ?
                            <dl className="govuk-summary-list">

                                {/*More details*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Tell us what you think this person has done that means they have committed fraud</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="payments-for-pregnancy-or-children-under-6-type-of-child-or-pregnancy-person-currently-caring-for-child-or-children-more-details">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForChildrenCommentBox === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForChildrenCommentBox !== '') ? this.props.reportedPersonBenefitPaymentForChildrenCommentBox : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonCurrentlyCaringForChildOrChildrenMoreDetails"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any other details on what you think this person has done that means they have committed fraud</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Payments for pregnancy, or child responsibility, Type of child or pregnancy: A person who no longer cares for a child or children*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for pregnancy, or child responsibility")
                                && (this.props.reportedPersonBenefitForChildOrPregnancy === 'A person who no longer cares for a child or children')) ?
                            <dl className="govuk-summary-list">

                                    {/*List of children*/}
                                    {
                                        this.props.reportedPersonBenefitPaymentForNotCaringChildren.map((child, index) => {

                                            return (
                                                <div className="govuk-summary-list__row">
                                                    <dt className="govuk-summary-list__key">
                                                        <span className="font-large">Child {child.id}</span>
                                                    </dt>
                                                    <dd className="govuk-summary-list__value">
                                                        <div className="" id={generateElementIdForNotCaringChild(child.id)}>
                                                            <p hidden={child.knowDetails === ''}>
                                                                {(child.knowDetails !== '') ? "Do you know the name of the child: ".concat(child.knowDetails) : 'Do you know the name of the child: '}
                                                            </p>
                                                            <p hidden={child.knowDetails !== 'Yes'}>
                                                                {(child.firstName !== '') ? "First name: ".concat(child.firstName) : 'First name: '}
                                                            </p>
                                                            <p hidden={child.knowDetails !== 'Yes'}>
                                                                {(child.surname !== '') ? "Surname: ".concat(child.surname) : 'Surname: '}
                                                            </p>
                                                            <p hidden={child.whenLeftPersonCare === ''}>
                                                                {(child.whenLeftPersonCare !== '') ? "When did the child/children leave the person's care: ".concat(child.whenLeftPersonCare) : 'When did the child/children leave the person\'s care: '}
                                                            </p>
                                                            <p hidden={child.removedByLocalCouncil === ''}>
                                                                {(child.removedByLocalCouncil !== '') ? "Were they removed from the person's care by the local council: ".concat(child.removedByLocalCouncil) : 'Were they removed from the person\'s care by the local council: '}
                                                            </p>
                                                            <p hidden={child.removedByLocalCouncil !== 'Yes'}>
                                                                {(child.removedByLocalCouncilArea !== '') ? "Which council area did this take place in: ".concat(child.removedByLocalCouncilArea) : 'Which council area did this take place in: '}
                                                            </p>
                                                        </div>
                                                    </dd>
                                                    <dd className="govuk-summary-list__actions">
                                                        <button type="button"
                                                                className="link--button"
                                                                name="modalName" value={generateChangeButtonValueForNotCaringChild(child.id)}
                                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any details for child {child.id}</span></button>
                                                    </dd>
                                                </div>
                                            )
                                        })
                                    }

                            </dl>
                            : ''
                        }

                        {/*Payments for pregnancy, or child responsibility, Type of child or pregnancy: A person who no longer cares for a child or children*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for pregnancy, or child responsibility")
                                && (this.props.reportedPersonBenefitForChildOrPregnancy === 'A person who no longer cares for a child or children')) ?
                            <dl className="govuk-summary-list">

                                {/*More details*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Tell us what you think this person has done that means they have committed fraud</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="payments-for-pregnancy-or-children-under-6-type-of-child-or-pregnancy-person-no-longer-cares-for-child-or-children-more-details">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForChildrenCommentBox === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForChildrenCommentBox !== '') ? this.props.reportedPersonBenefitPaymentForChildrenCommentBox : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonNoLongerCaresForChildOrChildrenMoreDetails"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any other details on what you think this person has done that means they have committed fraud</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Payments for pregnancy, or child responsibility, Type of child or pregnancy: A person who says they are pregnant but they’re not, in order to apply for benefit payments*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for pregnancy, or child responsibility")
                                && (this.props.reportedPersonBenefitForChildOrPregnancy === 'A person who says they are pregnant but they’re not, in order to apply for benefit payments')) ?
                            <dl className="govuk-summary-list">

                                {/*More details*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Tell us why you think the application for this has been made falsely</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="payments-for-pregnancy-or-children-under-6-type-of-child-or-pregnancy-person-says-they-are-pregnant-but-they-not-more-details">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForChildrenCommentBox === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForChildrenCommentBox !== '') ? this.props.reportedPersonBenefitPaymentForChildrenCommentBox : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonSaysTheyArePregnantButTheyAreNotMoreDetails"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any other details on why you think the application for this has been made falsely</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Payments for pregnancy, or child responsibility, Type of child or pregnancy: Other*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for pregnancy, or child responsibility")
                                && (this.props.reportedPersonBenefitForChildOrPregnancy === 'Other')) ?
                            <dl className="govuk-summary-list">

                                {/*More details*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Tell us why you think the application for this has been made falsely</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="payments-for-pregnancy-or-children-under-6-type-of-child-or-pregnancy-other-more-details">
                                            <p hidden={this.props.reportedPersonBenefitPaymentForChildrenCommentBox === ''}>
                                                {(this.props.reportedPersonBenefitPaymentForChildrenCommentBox !== '') ? this.props.reportedPersonBenefitPaymentForChildrenCommentBox : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyOtherMoreDetails"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any other details on why you think the application for this has been made falsely</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Payments or benefits received for providing care*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments or benefits received for providing care")) ?
                            <h2>Not providing care for a person</h2>
                            : ''
                        }

                        {/*Payments or benefits received for providing care*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments or benefits received for providing care")) ?
                            <dl className="govuk-summary-list">

                                {/*Payments or benefits received for providing care: do you know the name of the person being cared of?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know the name of the person being cared of?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="benefits-received-for-providing-care-know-name-of-the-person-being-cared-of">
                                            <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails === ''}>
                                                {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails !== '') ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOf"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know the name of the person being cared of</span></button>
                                    </dd>
                                </div>

                                {/*Payments or benefits received for providing care: (if you know the details of the person being cared of) first name*/}
                                {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">First name</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="benefits-received-for-providing-care-know-name-of-the-person-being-cared-of-first-name">
                                                <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.firstName === ''}>
                                                    {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.firstName !== '') ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.firstName : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOfFirstName"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the first name of the person being cared of</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Payments or benefits received for providing care: (if you know the details of the person being cared of) surname*/}
                                {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Surname</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="benefits-received-for-providing-care-know-name-of-the-person-being-cared-of-surname">
                                                <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.surname === ''}>
                                                    {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.surname !== '') ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.surname : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOfSurname"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the surname of the person being cared of</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Payments or benefits received for providing care: is the person's address the same as one of the person you are reporting?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Is the person's address the same as one of the person you are reporting?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="benefits-received-for-providing-care-is-address-same-as-one-of-the-person-you-are-reporting">
                                            <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson === ''}>
                                                {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson !== '') ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="benefitsReceivedForProvidingCareAddressSameAsOneOfThePersonYouAreReporting"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if the person's address is the same as one of the person you are reporting</span></button>
                                    </dd>
                                </div>

                                {/*Payments or benefits received for providing care: (if the address of the person is not the same as of the person you are reporting) address*/}
                                {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson === 'No') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Address (of the person being cared of)</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="benefits-received-for-providing-care-address-of-the-person-being-cared-of">
                                                <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber === ''}>
                                                    {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber !== '') ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber : ''}</p>
                                                <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressStreetName === ''}>
                                                    {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressStreetName !== '') ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressStreetName : ''}</p>
                                                <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressCity === ''}>
                                                    {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressCity !== '') ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressCity : ''}</p>
                                                <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressPostcode === ''}>
                                                    {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressPostcode) ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.addressPostcode : ''}</p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="benefitsReceivedForProvidingCareAddressOfThePersonBeingCaredOf"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the address of the person being cared of</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Payments or benefits received for providing care: extra info*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Why do you think this person is committing fraud? (more details)</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="benefits-received-for-providing-care-any-other-details">
                                            <p hidden={this.props.reportedPersonDetailsOfPersonBeingCaredFor.commentBox === ''}>
                                                {(this.props.reportedPersonDetailsOfPersonBeingCaredFor.commentBox !== '') ? this.props.reportedPersonDetailsOfPersonBeingCaredFor.commentBox : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="benefitsReceivedForProvidingCareAnyOtherDetails"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any other details on payments or benefits received for providing care</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Payments for getting a job offer*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for getting a job offer")) ?
                            <h2>Job Start Payment</h2>
                            : ''
                        }

                        {/*Payments for getting a job offer*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for getting a job offer")) ?
                            <dl className="govuk-summary-list">

                                {/*Job Start Payment: are they currently employed?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Are they currently employed?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="job-start-payment-are-they-currently-employed">
                                            <p hidden={this.props.reportedPersonJobStartPayment.currentlyEmployed === ''}>
                                                {(this.props.reportedPersonJobStartPayment.currentlyEmployed !== '') ? this.props.reportedPersonJobStartPayment.currentlyEmployed : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="jobStartPaymentAreTheyCurrentlyEmployed"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they are currently employed</span></button>
                                    </dd>
                                </div>

                                {/*Job Start Payment: (if currently employed) where do they work?*/}
                                {(this.props.reportedPersonJobStartPayment.currentlyEmployed === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Where do they work?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="job-start-payment-where-do-they-work">
                                                <p hidden={this.props.reportedPersonJobStartPayment.whereDoTheyWork === ''}>
                                                    {(this.props.reportedPersonJobStartPayment.whereDoTheyWork !== '') ? this.props.reportedPersonJobStartPayment.whereDoTheyWork : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="jobStartPaymentWhereDoTheyWork"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> where do they work</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Job Start Payment: (if not currently employed) have they been employed the last 6 months?*/}
                                {(this.props.reportedPersonJobStartPayment.currentlyEmployed === 'No' || this.props.reportedPersonJobStartPayment.currentlyEmployed === 'I don\'t know') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Have they been employed in the last 6 months?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="job-start-payment-been-employed-for-the-last-6-months">
                                                <p hidden={this.props.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months === ''}>
                                                    {(this.props.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months !== '') ? this.props.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="jobStartPaymentBeenEmployedForTheLast6Months"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they have been employed for the last 6 months</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Job Start Payment: (if been employed for the last 6 months) where did they work?*/}
                                {((this.props.reportedPersonJobStartPayment.currentlyEmployed === 'No' || this.props.reportedPersonJobStartPayment.currentlyEmployed === 'I don\'t know')
                                    && this.props.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Where did they work?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="job-start-payment-where-did-they-work">
                                                <p hidden={this.props.reportedPersonJobStartPayment.whereDidTheyWork === ''}>
                                                    {(this.props.reportedPersonJobStartPayment.whereDidTheyWork !== '') ? this.props.reportedPersonJobStartPayment.whereDidTheyWork : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="jobStartPaymentWhereDidTheyWork"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> where did they work</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {(this.props.reportedPersonJobStartPayment.currentlyEmployed === 'Yes'
                                    || this.props.reportedPersonJobStartPayment.currentlyEmployed === 'No'
                                    || this.props.reportedPersonJobStartPayment.currentlyEmployed === 'I don\'t know') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Is there anything else you think we should know?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="job-start-payment-anything-else-we-should-know">
                                                <p hidden={this.props.reportedPersonJobStartPayment.extraInfo === ''}>
                                                    {(this.props.reportedPersonJobStartPayment.extraInfo !== '') ? this.props.reportedPersonJobStartPayment.extraInfo : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="jobStartPaymentExtraInfo"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if there is anything else we should know</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                            </dl>
                            : ''
                        }

                        {/*Payments for a funeral*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for a funeral")) ?
                            <h2>Funeral Support Payment</h2>
                            : ''
                        }

                        {/*Payments for a funeral*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Payments for a funeral")) ?
                            <dl className="govuk-summary-list">

                                {/*Funeral Support Payment: do you know the name of the deceased person?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know the name of the deceased person?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="funeral-support-payment-do-you-know-the-name-of-the-deceased-person">
                                            <p hidden={this.props.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson === ''}>
                                                {(this.props.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson !== '') ? this.props.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="funeralSupportPaymentKnowTheNameOfTheDeceasedPerson"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know the name of the deceased person</span></button>
                                    </dd>
                                </div>

                                {/*Funeral Support Payment: (if you know the name of the deceased person) first name*/}
                                {(this.props.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">First name</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="funeral-support-payment-first-name-of-the-deceased-person">
                                                <p hidden={this.props.reportedPersonFuneralSupportPayment.deceasedPersonFirstName === ''}>
                                                    {(this.props.reportedPersonFuneralSupportPayment.deceasedPersonFirstName !== '') ? this.props.reportedPersonFuneralSupportPayment.deceasedPersonFirstName : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="funeralSupportPaymentDeceasedPersonFirstName"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> first name of the deceased person</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Funeral Support Payment: (if you know the name of the deceased person) surname*/}
                                {(this.props.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Surname</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="funeral-support-payment-surname-of-the-deceased-person">
                                                <p hidden={this.props.reportedPersonFuneralSupportPayment.deceasedPersonSurname === ''}>
                                                    {(this.props.reportedPersonFuneralSupportPayment.deceasedPersonSurname !== '') ? this.props.reportedPersonFuneralSupportPayment.deceasedPersonSurname : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="funeralSupportPaymentDeceasedPersonSurname"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> surname of the deceased person</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Funeral Support Payment: do you know the date the person died?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know the date the person died?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="funeral-support-payment-do-you-know-the-date-the-person-died">
                                            <p hidden={this.props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === ''}>
                                                {(this.props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied !== '') ? this.props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="funeralSupportPaymentKnowTheDateThePersonDied"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know the date the person died</span></button>
                                    </dd>
                                </div>

                                {/*Funeral Support Payment: (if you know the date the person died) date of death*/}
                                {(this.props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">When did they pass away?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="funeral-support-payment-date-of-death-of-person-died">
                                                <p hidden={this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay === ''
                                                && this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth === ''
                                                && this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear === ''}>
                                                    {(this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay !== ''
                                                        || this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth !== ''
                                                        || this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear !== '')
                                                        ? beautifyDateOnlyDates(
                                                            this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay,
                                                            this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth,
                                                            this.props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear
                                                        ) : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="funeralSupportPaymentDateOfDeathOfPersonDied"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> date of death of the deceased person</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Funeral Support Payment: (if you do not know the date the person died) approximate date*/}
                                {(this.props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'No') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Do you know approximately when the person died?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="funeral-support-payment-approximate-date-of-person-died">
                                                <p hidden={this.props.reportedPersonFuneralSupportPayment.approximateDateOfPersonDied === ''}>
                                                    {(this.props.reportedPersonFuneralSupportPayment.approximateDateOfPersonDied !== '') ? this.props.reportedPersonFuneralSupportPayment.approximateDateOfPersonDied : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="funeralSupportPaymentApproximateDateOfPersonDied"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> approximate date of the deceased person</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Funeral Support Payment: do you know the date the funeral took place?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know the date the funeral took place?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="funeral-support-payment-do-you-know-the-date-the-funeral-took-place">
                                            <p hidden={this.props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === ''}>
                                                {(this.props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral !== '') ? this.props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="funeralSupportPaymentKnowTheDateOfTheFuneral"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know the date the funeral took place</span></button>
                                    </dd>
                                </div>

                                {/*Funeral Support Payment: (if you know the date the funeral took place) date of funeral*/}
                                {(this.props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">When did the funeral take place?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="funeral-support-payment-date-of-funeral">
                                                <p hidden={this.props.reportedPersonFuneralSupportPayment.dateOfFuneralDay === ''
                                                && this.props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth === ''
                                                && this.props.reportedPersonFuneralSupportPayment.dateOfFuneralYear === ''}>
                                                    {(this.props.reportedPersonFuneralSupportPayment.dateOfFuneralDay !== ''
                                                        || this.props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth !== ''
                                                        || this.props.reportedPersonFuneralSupportPayment.dateOfFuneralYear !== '')
                                                        ? beautifyDateOnlyDates(
                                                            this.props.reportedPersonFuneralSupportPayment.dateOfFuneralDay,
                                                            this.props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth,
                                                            this.props.reportedPersonFuneralSupportPayment.dateOfFuneralYear
                                                        ) : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="funeralSupportPaymentDateOfFuneral"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> date of funeral of the deceased person</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Funeral Support Payment: (if you do not know the date the funeral took place) approximate date*/}
                                {(this.props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'No') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Do you know approximately when funeral took place?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="funeral-support-payment-approximate-date-of-funeral">
                                                <p hidden={this.props.reportedPersonFuneralSupportPayment.approximateDateOfFuneral === ''}>
                                                    {(this.props.reportedPersonFuneralSupportPayment.approximateDateOfFuneral !== '') ? this.props.reportedPersonFuneralSupportPayment.approximateDateOfFuneral : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="funeralSupportPaymentApproximateDateOfFuneral"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> approximate date of funeral of the deceased person</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Funeral Support Payment: why the application was made falsely?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Why you think this application has been made falsely?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="funeral-support-payment-why-application-was-made-falsely">
                                            <p hidden={this.props.reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely === ''}>
                                                {(this.props.reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely !== '') ? this.props.reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="funeralSupportPaymentWhyApplicationMadeFalsely"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> why application was made falsely</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Using a fraudulent identity when applying for benefits or payments*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Using a fraudulent identity when applying for benefits or payments")) ?
                            <h2>Using a different identity or documents to get benefits</h2>
                            : ''
                        }

                        {/*Using a fraudulent identity when applying for benefits or payments*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Using a fraudulent identity when applying for benefits or payments")) ?
                            <dl className="govuk-summary-list">

                                {/*Using a fraudulent identity when applying for benefits or payments: do you know the name the person is using with this identity?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know the name the person is using with this identity?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="using-fraudulent-identity-know-name-the-person-is-using-with-this-identity">
                                            <p hidden={this.props.reportedPersonFraudulentIdentity.knowDetails === ''}>
                                                {(this.props.reportedPersonFraudulentIdentity.knowDetails !== '') ? this.props.reportedPersonFraudulentIdentity.knowDetails : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentity"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know the name the person is using with this identity</span></button>
                                    </dd>
                                </div>

                                {/*Using a fraudulent identity when applying for benefits or payments: (if you know the name the person is using with this identity) first name*/}
                                {(this.props.reportedPersonFraudulentIdentity.knowDetails === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">First name</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="using-fraudulent-identity-know-name-the-person-is-using-with-this-identity-first-name">
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.firstName === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.firstName !== '') ? this.props.reportedPersonFraudulentIdentity.firstName : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentityFirstName"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the first name the person is using with this identity</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Using a fraudulent identity when applying for benefits or payments: (if you know the name the person is using with this identity) surname*/}
                                {(this.props.reportedPersonFraudulentIdentity.knowDetails === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Surname</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="using-fraudulent-identity-know-name-the-person-is-using-with-this-identity-surname">
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.surname === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.surname !== '') ? this.props.reportedPersonFraudulentIdentity.surname : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentitySurname"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the surname the person is using with this identity</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Using a fraudulent identity when applying for benefits or payments: do you know the date of birth the person is using with this identity?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know the date of birth the person is using with this identity?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="using-fraudulent-identity-know-date-of-birth-the-person-is-using-with-this-identity">
                                            <p hidden={this.props.reportedPersonFraudulentIdentity.knowDateOfBirth === ''}>
                                                {(this.props.reportedPersonFraudulentIdentity.knowDateOfBirth !== '') ? this.props.reportedPersonFraudulentIdentity.knowDateOfBirth : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="usingFraudulentIdentityKnowDateOfBirthThePersonIsUsingWithThisIdentity"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know the date of birth the person is using with this identity</span></button>
                                    </dd>
                                </div>

                                {/*Using a fraudulent identity when applying for benefits or payments: (if you know the date of birth the person is using with this identity) date of birth*/}
                                {(this.props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Date of birth</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="using-fraudulent-identity-date-of-birth-the-person-is-using-with-this-identity">
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.dateOfBirthDay === ''
                                                            && this.props.reportedPersonFraudulentIdentity.dateOfBirthMonth === ''
                                                            && this.props.reportedPersonFraudulentIdentity.dateOfBirthYear === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.dateOfBirthDay !== ''
                                                        || this.props.reportedPersonFraudulentIdentity.dateOfBirthMonth !== ''
                                                        || this.props.reportedPersonFraudulentIdentity.dateOfBirthYear !== '')
                                                        ? beautifyDate('Yes',
                                                            this.props.reportedPersonFraudulentIdentity.dateOfBirthDay,
                                                            this.props.reportedPersonFraudulentIdentity.dateOfBirthMonth,
                                                            this.props.reportedPersonFraudulentIdentity.dateOfBirthYear,
                                                            '')
                                                        : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="usingFraudulentIdentityDateOfBirthThePersonIsUsingWithThisIdentity"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the date of birth the person is using with this identity</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Using a fraudulent identity when applying for benefits or payments: (if you do not know the date of birth the person is using with this identity) approximate age range*/}
                                {(this.props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'No') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Approximate age range</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="using-fraudulent-identity-approximate-age-range-the-person-is-using-with-this-identity">
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.approximateAge === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.approximateAge !== '')
                                                        ? beautifyDate('No',
                                                            this.props.reportedPersonFraudulentIdentity.dateOfBirthDay,
                                                            this.props.reportedPersonFraudulentIdentity.dateOfBirthMonth,
                                                            this.props.reportedPersonFraudulentIdentity.dateOfBirthYear,
                                                            this.props.reportedPersonFraudulentIdentity.approximateAge)
                                                        : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="usingFraudulentIdentityApproximateAgeThePersonIsUsingWithThisIdentity"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the approximate age range the person is using with this identity</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Using a fraudulent identity when applying for benefits or payments: do you know the address or previous addresses the person is using with this identity?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know the address or previous addresses the person is using with this identity?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="using-fraudulent-identity-know-address-or-addresses-the-person-is-using-with-this-identity">
                                            <p hidden={this.props.reportedPersonFraudulentIdentity.knowAddress === ''}>
                                                {(this.props.reportedPersonFraudulentIdentity.knowAddress !== '') ? this.props.reportedPersonFraudulentIdentity.knowAddress : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="usingFraudulentIdentityKnowAddressOrAddressesThePersonIsUsingWithThisIdentity"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know the address or previous addresses the person is using with this identity</span></button>
                                    </dd>
                                </div>

                                {/*Using a fraudulent identity when applying for benefits or payments: (if you know the address or previous addresses the person is using with this identity) address*/}
                                {(this.props.reportedPersonFraudulentIdentity.knowAddress === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Address used with this identity</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="using-fraudulent-identity-address-used-with-this-identity">
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.addressHouseNumber === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.addressHouseNumber !== '') ? this.props.reportedPersonFraudulentIdentity.addressHouseNumber : ''}</p>
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.addressStreetName === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.addressStreetName !== '') ? this.props.reportedPersonFraudulentIdentity.addressStreetName : ''}</p>
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.addressCity === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.addressCity !== '') ? this.props.reportedPersonFraudulentIdentity.addressCity : ''}</p>
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.addressPostcode === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.addressPostcode) ? this.props.reportedPersonFraudulentIdentity.addressPostcode : ''}</p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="usingFraudulentIdentityAddressUsedWithThisIdentity"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> address used with this identity</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Using a fraudulent identity when applying for benefits or payments: has the person used fake or false documents with this identity?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Has the person used fake or false documents with this identity?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="using-fraudulent-identity-has-the-person-used-fake-or-false-documents-with-this-identity">
                                            <p hidden={this.props.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments === ''}>
                                                {(this.props.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments !== '') ? this.props.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="usingFraudulentIdentityHasUsedFakeOrFalseDocumentsWithThisIdentity"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if the person has used fake or false documents with this identity</span></button>
                                    </dd>
                                </div>

                                {/*Using a fraudulent identity when applying for benefits or payments: (if the person used fake or false documents with this identity) more details*/}
                                {(this.props.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">More details on the fake or false documents used with this identity</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="using-fraudulent-identity-more-details-on-fake-or-false-documents-used-with-this-identity">
                                                <p hidden={this.props.reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox === ''}>
                                                    {(this.props.reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox !== '') ? this.props.reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox : ''}</p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="usingFraudulentIdentityMoreDetailsOnFakeOrFalseDocumentsUsedWithThisIdentity"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> the details on fake or false documents used with this identity</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Using a fraudulent identity when applying for benefits or payments: extra info*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Any other details</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="using-fraudulent-identity-any-other-details">
                                            <p hidden={this.props.reportedPersonFraudulentIdentity.commentBox === ''}>
                                                {(this.props.reportedPersonFraudulentIdentity.commentBox !== '') ? this.props.reportedPersonFraudulentIdentity.commentBox : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="usingFraudulentIdentityAnyOtherDetails"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> any other details on using a fraudulent identity when applying for benefits or payments</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Receiving benefits in Scotland but not living in Scotland*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Receiving benefits in Scotland but not living in Scotland")) ?
                            <h2>Receiving benefits in Scotland but not living in Scotland</h2>
                            : ''
                        }

                        {/*Receiving benefits in Scotland but not living in Scotland*/}
                        {(multiSelectListValueIsChecked(this.props.suspectedBenefitFraudTypes, "Receiving benefits in Scotland but not living in Scotland")) ?
                            <dl className="govuk-summary-list">

                                {/*Receiving benefits in Scotland but not living in Scotland: what country is the person living in?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">What country is the person living in?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="not-living-in-scotland-country-person-is-living">
                                            <p hidden={this.props.reportedPersonLivingOutsideScotland.country === ''}>
                                                {(this.props.reportedPersonLivingOutsideScotland.country !== '') ? this.props.reportedPersonLivingOutsideScotland.country : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="notLivingInScotlandCountryPersonIsLiving"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> what country is the person living in</span></button>
                                    </dd>
                                </div>

                                {/*Receiving benefits in Scotland but not living in Scotland: do they live in this country permanently or temporarily?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do they live in this country permanently or temporarily?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="not-living-in-scotland-country-person-is-living-permanently-or-temporarily">
                                            <p hidden={this.props.reportedPersonLivingOutsideScotland.movePermanentOrTemporary === ''}>
                                                {(this.props.reportedPersonLivingOutsideScotland.movePermanentOrTemporary !== '') ? this.props.reportedPersonLivingOutsideScotland.movePermanentOrTemporary : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="notLivingInScotlandCountryPersonIsLivingPermanentlyOrTemporarily"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if they live in this country permanently or temporarily</span></button>
                                    </dd>
                                </div>

                                {/*Receiving benefits in Scotland but not living in Scotland: do you know the person's current address?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know the person’s current address?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="not-living-in-scotland-do-you-know-person-current-address">
                                            <p hidden={this.props.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad === ''}>
                                                {(this.props.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad !== '') ? this.props.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="notLivingInScotlandKnowPersonCurrentAddress"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know the person's current address</span></button>
                                    </dd>
                                </div>

                                {/*Receiving benefits in Scotland but not living in Scotland: (if you know the person's current address) have you already given their address at the start of the form?*/}
                                {(this.props.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Have you already given their address abroad at the start of the form?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="not-living-in-scotland-have-you-already-given-their-address-abroad">
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad !== '') ? this.props.reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="notLivingInScotlandHaveYouAlreadyGivenTheirAddressAbroad"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you have already given their address abroad</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Receiving benefits in Scotland but not living in Scotland: (if you have not already given the person's address abroad) current address abroad*/}
                                {(this.props.reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad === 'No') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Current address abroad</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="not-living-in-scotland-current-address-abroad">
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressAbroadHouseNumber === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressAbroadHouseNumber !== '') ? this.props.reportedPersonLivingOutsideScotland.addressAbroadHouseNumber : ''}</p>
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressAbroadStreetName === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressAbroadStreetName !== '') ? this.props.reportedPersonLivingOutsideScotland.addressAbroadStreetName : ''}</p>
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressAbroadCity === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressAbroadCity !== '') ? this.props.reportedPersonLivingOutsideScotland.addressAbroadCity : ''}</p>
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressAbroadPostcode === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressAbroadPostcode) ? this.props.reportedPersonLivingOutsideScotland.addressAbroadPostcode : ''}</p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="notLivingInScotlandCurrentAddressAbroad"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> current address abroad</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Receiving benefits in Scotland but not living in Scotland: do you know when they moved to this address?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know when they moved to this address?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="not-living-in-scotland-do-you-know-when-they-moved-to-this-address">
                                            <p hidden={this.props.reportedPersonLivingOutsideScotland.knowWhenTheyMoved === ''}>
                                                {(this.props.reportedPersonLivingOutsideScotland.knowWhenTheyMoved !== '') ? this.props.reportedPersonLivingOutsideScotland.knowWhenTheyMoved : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="notLivingInScotlandKnowWhenTheyMovedToThisAddress"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know when they moved to this address</span></button>
                                    </dd>
                                </div>

                                {/*Receiving benefits in Scotland but not living in Scotland: do you know their address in Scotland?*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Do you know their address in Scotland?</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="not-living-in-scotland-do-you-know-their-address-in-scotland">
                                            <p hidden={this.props.reportedPersonLivingOutsideScotland.knowAddressInScotland === ''}>
                                                {(this.props.reportedPersonLivingOutsideScotland.knowAddressInScotland !== '') ? this.props.reportedPersonLivingOutsideScotland.knowAddressInScotland : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="notLivingInScotlandKnowAddressInScotland"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know their address in Scotland</span></button>
                                    </dd>
                                </div>

                                {/*Receiving benefits in Scotland but not living in Scotland: (if you know their address in Scotland) have you already given their address in Scotland at the start of the form?*/}
                                {(this.props.reportedPersonLivingOutsideScotland.knowAddressInScotland === 'Yes') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Have you already given their address in Scotland at the start of the form?</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="not-living-in-scotland-have-you-already-given-their-address-in-scotland">
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven !== '') ? this.props.reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven : ''}
                                                </p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="notLivingInScotlandHaveYouAlreadyGivenTheirAddressInScotland"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you have already given their address in Scotland</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Receiving benefits in Scotland but not living in Scotland: (if you have not already given the person's address in Scotland) current address in Scotland*/}
                                {(this.props.reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven === 'No') ?
                                    <div className="govuk-summary-list__row">
                                        <dt className="govuk-summary-list__key">
                                            <span className="font-large">Current address in Scotland</span>
                                        </dt>
                                        <dd className="govuk-summary-list__value">
                                            <div className="" id="not-living-in-scotland-current-address-in-scotland">
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber !== '') ? this.props.reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber : ''}</p>
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressInScotlandStreetName === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressInScotlandStreetName !== '') ? this.props.reportedPersonLivingOutsideScotland.addressInScotlandStreetName : ''}</p>
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressInScotlandCity === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressInScotlandCity !== '') ? this.props.reportedPersonLivingOutsideScotland.addressInScotlandCity : ''}</p>
                                                <p hidden={this.props.reportedPersonLivingOutsideScotland.addressInScotlandPostcode === ''}>
                                                    {(this.props.reportedPersonLivingOutsideScotland.addressInScotlandPostcode) ? this.props.reportedPersonLivingOutsideScotland.addressInScotlandPostcode : ''}</p>
                                            </div>
                                        </dd>
                                        <dd className="govuk-summary-list__actions">
                                            <button type="button"
                                                    className="link--button"
                                                    name="modalName" value="notLivingInScotlandCurrentAddressInScotland"
                                                    onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> current address in Scotland</span></button>
                                        </dd>
                                    </div>
                                    : ''
                                }

                                {/*Receiving benefits in Scotland but not living in Scotland: extra info*/}
                                <div className="govuk-summary-list__row">
                                    <dt className="govuk-summary-list__key">
                                        <span className="font-large">Any other details</span>
                                    </dt>
                                    <dd className="govuk-summary-list__value">
                                        <div className="" id="not-living-in-scotland-any-other-details">
                                            <p hidden={this.props.reportedPersonLivingOutsideScotland.extraInfo === ''}>
                                                {(this.props.reportedPersonLivingOutsideScotland.extraInfo !== '') ? this.props.reportedPersonLivingOutsideScotland.extraInfo : ''}
                                            </p>
                                        </div>
                                    </dd>
                                    <dd className="govuk-summary-list__actions">
                                        <button type="button"
                                                className="link--button"
                                                name="modalName" value="notLivingInScotlandAnyOtherDetails"
                                                onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you know any other details</span></button>
                                    </dd>
                                </div>

                            </dl>
                            : ''
                        }

                        {/*Additional information about this report*/}
                        <h2>Additional information about this report</h2>

                        <dl className="govuk-summary-list">

                            {/*Additional information about the report: how do you know the information?*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">How do you know the information in this report?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="additional-information-how-do-you-know-the-information">
                                        <p hidden={this.props.reportAdditionalInformation.howDoYouKnowTheInformation === ''}>
                                            {(this.props.reportAdditionalInformation.howDoYouKnowTheInformation !== '') ? this.props.reportAdditionalInformation.howDoYouKnowTheInformation : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="additionalInformationHowDoYouKnowTheInformation"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> how do you know the information in this report</span></button>
                                </dd>
                            </div>

                            {/*Additional information about the report: when did you first learn of this information?*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">When did you first learn of this information?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="additional-information-when-did-you-first-learn-of-this-information">
                                        <p hidden={this.props.reportAdditionalInformation.whenDidYouFirstLearnTheInformation === ''}>
                                            {(this.props.reportAdditionalInformation.whenDidYouFirstLearnTheInformation !== '') ? this.props.reportAdditionalInformation.whenDidYouFirstLearnTheInformation : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="additionalInformationWhenDidYouFirstLearnOfThisInformation"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> when did you first learn of the information in this report</span></button>
                                </dd>
                            </div>

                            {/*Additional information about the report: when did you last know it to be true?*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">If you have known this information for some time, when did you last know it to be true?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="additional-information-when-did-you-last-know-it-to-be-true">
                                        <p hidden={this.props.reportAdditionalInformation.whenDidYouLastKnowItToBeTrue === ''}>
                                            {(this.props.reportAdditionalInformation.whenDidYouLastKnowItToBeTrue !== '') ? this.props.reportAdditionalInformation.whenDidYouLastKnowItToBeTrue : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="additionalInformationWhenDidYouLastKnowItToBeTrue"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> when did you last know the information in this report to be true</span></button>
                                </dd>
                            </div>

                            {/*Additional information about the report: are you the only person who knows this information?*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Apart from the person(s) you reported, are you the only person who knows this information?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="additional-information-only-person-who-knows-the-information">
                                        <p hidden={this.props.reportAdditionalInformation.onlyPersonWhoKnowsTheInformation === ''}>
                                            {(this.props.reportAdditionalInformation.onlyPersonWhoKnowsTheInformation !== '') ? this.props.reportAdditionalInformation.onlyPersonWhoKnowsTheInformation : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="additionalInformationOnlyPersonWhoKnowsTheInformation"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you are the only person who knows the information in this report</span></button>
                                </dd>
                            </div>

                            {/*Additional information about the report: are you happy for us to act on the information provided?*/}
                            <div className="govuk-summary-list__row">
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Are you happy for us to act on the information you provided?</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    <div className="" id="additional-information-happy-to-act-on-the-information-provided">
                                        <p hidden={this.props.reportAdditionalInformation.happyToActOnInformationProvided === ''}>
                                            {(this.props.reportAdditionalInformation.happyToActOnInformationProvided !== '') ? this.props.reportAdditionalInformation.happyToActOnInformationProvided : ''}
                                        </p>
                                    </div>
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="additionalInformationHappyToActOnInformationProvided"
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> if you are happy to act on the information provided in this report</span></button>
                                </dd>
                            </div>

                        </dl>

                       {/* <dl className="govuk-summary-list">
                             Row I: Name
                            <div className={(!multiSelectListValueIsChecked(this.props.suspectedPersonRequiredInformation, 'Name')) ? "govuk-summary-list__rowHidden" : "govuk-summary-list__row"}>
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Name</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {(this.state.showNameDetails === false) ?
                                        <div className="vertical-inputs" id="editable-details">
                                            <label htmlFor="reportedPersonPersonalInfo.firstName" className="form-text">First name (<span className="red">required</span>)</label>
                                            <input type="text"
                                                   autoFocus
                                                   maxLength={this.props.maxLengthForText}
                                                   required={true}
                                                   name="reportedPersonPersonalInfo.firstName"
                                                   id="reportedPersonPersonalInfo.firstName"
                                                   value={this.props.reportedPersonPersonalInfo.firstName}
                                                   className={this.props.errorClass.reportedPersonPersonalInfofirstName}
                                                   onChange={this.props.handleObjectChange} />

                                            <label htmlFor="reportedPersonPersonalInfo.surname" className="form-text">Surname (<span className="red">required</span>)</label>
                                            <input type="text"
                                                   maxLength={this.props.maxLengthForText}
                                                   required={true}
                                                   name="reportedPersonPersonalInfo.surname"
                                                   id="reportedPersonPersonalInfo.surname"
                                                   value={this.props.reportedPersonPersonalInfo.surname}
                                                   className={this.props.errorClass.reportedPersonPersonalInfosurname}
                                                   onChange={this.props.handleObjectChange} />

                                            <fieldset aria-labelledby="reportedPersonPersonalInfoHasAlternativeName" className="vertical-inputs">
                                                <legend id="reportedPersonPersonalInfoHasAlternativeName">Has alternative name?</legend>
                                                <input type="radio"
                                                   name="reportedPersonPersonalInfo.hasAlternativeName"
                                                   id="reportedPersonPersonalInfo.hasAlternativeNameYes"
                                                   value="Yes"
                                                   checked={this.props.reportedPersonPersonalInfo.hasAlternativeName === 'Yes'}
                                                   onChange={this.props.handleObjectChange} />
                                                <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameYes" className="form-radio">Yes</label>

                                                <input type="radio"
                                                   name="reportedPersonPersonalInfo.hasAlternativeName"
                                                   id="reportedPersonPersonalInfo.hasAlternativeNameNo"
                                                   value="No"
                                                   checked={this.props.reportedPersonPersonalInfo.hasAlternativeName === 'No'}
                                                   onChange={this.props.handleObjectChange} />
                                                <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameNo" className="form-radio">No</label>

                                                <input type="radio"
                                                   name="reportedPersonPersonalInfo.hasAlternativeName"
                                                   id="reportedPersonPersonalInfo.hasAlternativeNameDoNotKnow"
                                                   value="I don't know"
                                                   checked={this.props.reportedPersonPersonalInfo.hasAlternativeName === 'I don't know'}
                                                   onChange={this.props.handleObjectChange} />
                                                <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameDoNotKnow" className="form-radio">I don't know</label>
                                            </fieldset>

                                            <div hidden={this.props.reportedPersonPersonalInfo.hasAlternativeName !== 'Yes'}>
                                                <label htmlFor="reportedPersonPersonalInfo.alternativeName" className="form-text">Alternative name</label>
                                                <input type="text"
                                                       maxLength={this.props.maxLengthForText}
                                                       name="reportedPersonPersonalInfo.alternativeName"
                                                       id="reportedPersonPersonalInfo.alternativeName"
                                                       value={this.props.reportedPersonPersonalInfo.alternativeName}
                                                       onChange={this.props.handleObjectChange} />
                                            </div>
                                        </div>
                                    :
                                        <div className="" id="details">
                                            <p hidden={this.props.reportedPersonPersonalInfo.firstName === ''}>First name: {(this.props.reportedPersonPersonalInfo.firstName !== '') ? this.props.reportedPersonPersonalInfo.firstName : '---'}</p>
                                            <p hidden={this.props.reportedPersonPersonalInfo.surname === ''}>Surname: {(this.props.reportedPersonPersonalInfo.surname !== '') ? this.props.reportedPersonPersonalInfo.surname : '---'}</p>
                                            <p hidden={this.props.reportedPersonPersonalInfo.hasAlternativeName === ''}>Has alternative name: {(this.props.reportedPersonPersonalInfo.hasAlternativeName !== '') ? this.props.reportedPersonPersonalInfo.hasAlternativeName : '---'}</p>
                                            <p hidden={this.props.reportedPersonPersonalInfo.alternativeName === ''}>Alternative name: {(this.props.reportedPersonPersonalInfo.alternativeName !== '') ? this.props.reportedPersonPersonalInfo.alternativeName : '---'}</p>
                                        </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="nameDetails"
                                            disabled={this.props.reportedPersonPersonalInfo.firstName === ''
                                                        || this.props.reportedPersonPersonalInfo.surname === ''}
                                            onClick={this.toggleDetails}>{this.state.changeButtonName}<span className="visually-hidden"> name</span></button>
                                </dd>
                            </div>

                             Row II: Date of Birth
                            <div className={(!multiSelectListValueIsChecked(this.props.suspectedPersonOptionalInformation, 'Date of birth (or approximate age)')
                                                || (this.state.showAgeDetails === true && this.props.reportedPersonPersonalInfo.knowDateOfBirth === '')
                                                || (this.state.showAgeDetails === false && this.props.reportedPersonPersonalInfo.knowDateOfBirth === '' && this.state.modalName !== 'ageDetails'))
                                            ? "govuk-summary-list__rowHidden" : "govuk-summary-list__row"}>
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Date of Birth</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {(this.state.showAgeDetails === false) ?
                                        <div className="" id="editable-age-details">
                                          <fieldset className="vertical-inputs">
                                            <legend>Do you know their date of birth?</legend>
                                            <input type="radio"
                                                   autoFocus
                                                   name="reportedPersonPersonalInfo.knowDateOfBirth"
                                                   id="reportedPersonPersonalInfo.knowDateOfBirthYes"
                                                   value="Yes"
                                                   checked={this.props.reportedPersonPersonalInfo.knowDateOfBirth === 'Yes'}
                                                   onChange={this.props.handleObjectChange} />
                                            <label htmlFor="reportedPersonPersonalInfo.knowDateOfBirthYes" className="form-radio">Yes</label>

                                            <input type="radio"
                                                   name="reportedPersonPersonalInfo.knowDateOfBirth"
                                                   id="reportedPersonPersonalInfo.knowDateOfBirthNo"
                                                   value="No"
                                                   checked={this.props.reportedPersonPersonalInfo.knowDateOfBirth === 'No'}
                                                   onChange={this.props.handleObjectChange} />
                                            <label htmlFor="reportedPersonPersonalInfo.knowDateOfBirthNo" className="form-radio">No</label>
                                          </fieldset>

                                            <div hidden={this.props.reportedPersonPersonalInfo.knowDateOfBirth !== 'Yes'}>
                                                <br/>
                                                <p>What is their date of birth?</p>
                                                <p className="hint">For example, 31 03 1980</p>

                                                <div className="grid date">
                                                  <div className={(isValidDate(this.props.reportedPersonPersonalInfo.dateOfBirthDay, this.props.reportedPersonPersonalInfo.dateOfBirthMonth, this.props.reportedPersonPersonalInfo.dateOfBirthYear)
                                                                    && isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthDay, 'dob')) ? '' : 'error'}>
                                                    <label htmlFor="reportedPersonPersonalInfo.dateOfBirthDay" className="form-date">Day</label>
                                                    <input type="number"
                                                       className="twoDigit"
                                                       min={1} max={31} step={1}
                                                       name="reportedPersonPersonalInfo.dateOfBirthDay"
                                                       id="reportedPersonPersonalInfo.dateOfBirthDay"
                                                       value={this.props.reportedPersonPersonalInfo.dateOfBirthDay}
                                                       onChange={this.props.handleObjectChange} />
                                                  </div>

                                                  <div className={(isValidDate(this.props.reportedPersonPersonalInfo.dateOfBirthDay, this.props.reportedPersonPersonalInfo.dateOfBirthMonth, this.props.reportedPersonPersonalInfo.dateOfBirthYear)
                                                                    && isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthMonth, 'dob')) ? '' : 'error'}>
                                                    <label htmlFor="reportedPersonPersonalInfo.dateOfBirthMonth" className="form-date">Month</label>
                                                    <input type="number"
                                                       className="twoDigit"
                                                       min={1} max={12} step={1}
                                                       name="reportedPersonPersonalInfo.dateOfBirthMonth"
                                                       id="reportedPersonPersonalInfo.dateOfBirthMonth"
                                                       value={this.props.reportedPersonPersonalInfo.dateOfBirthMonth}
                                                       onChange={this.props.handleObjectChange} />
                                                  </div>

                                                  <div className={(isValidDate(this.props.reportedPersonPersonalInfo.dateOfBirthDay, this.props.reportedPersonPersonalInfo.dateOfBirthMonth, this.props.reportedPersonPersonalInfo.dateOfBirthYear)
                                                                    && isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthYear, 'dob')) ? '' : 'error'}>
                                                    <label htmlFor="reportedPersonPersonalInfo.dateOfBirthYear" className="form-date">Year</label>
                                                    <input type="number"
                                                       className="fourDigit"
                                                       min={1900} max={9999} step={1}
                                                       name="reportedPersonPersonalInfo.dateOfBirthYear"
                                                       id="reportedPersonPersonalInfo.dateOfBirthYear"
                                                       value={this.props.reportedPersonPersonalInfo.dateOfBirthYear}
                                                       onChange={this.props.handleObjectChange} />
                                                  </div>
                                                </div>
                                                <div className="errorMessage">
                                                    <div hidden={isValidDate(this.props.reportedPersonPersonalInfo.dateOfBirthDay, this.props.reportedPersonPersonalInfo.dateOfBirthMonth, this.props.reportedPersonPersonalInfo.dateOfBirthYear)
                                                            && isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthDay, 'dob')
                                                            && isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthMonth, 'dob')
                                                            && isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthYear, 'dob')}>
                                                        <p>The date selected is not valid!</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div hidden={this.props.reportedPersonPersonalInfo.knowDateOfBirth !== 'No'}>
                                                <br/>
                                                <p>Please tell us the approximate age of the person.</p>
                                                <select name="reportedPersonPersonalInfo.ageRange"
                                                        value={this.props.reportedPersonPersonalInfo.ageRange}
                                                        onChange={this.props.handleObjectChange}>
                                                    <option value="16-24">16-24</option>
                                                    <option value="25-34">25-34</option>
                                                    <option value="35-44">35-44</option>
                                                    <option value="45-54">45-54</option>
                                                    <option value="55-64">55-64</option>
                                                    <option value="65+">65+</option>
                                                </select>
                                            </div>
                                        </div>
                                    :
                                        <div className="" id="ageDetails">
                                            {(this.props.reportedPersonPersonalInfo.knowDateOfBirth !== '')
                                                ? beautifyDate(this.props.reportedPersonPersonalInfo.knowDateOfBirth, this.props.reportedPersonPersonalInfo.dateOfBirthDay, this.props.reportedPersonPersonalInfo.dateOfBirthMonth, this.props.reportedPersonPersonalInfo.dateOfBirthYear, this.props.reportedPersonPersonalInfo.ageRange)
                                                : '---'}
                                        </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="ageDetails"
                                            disabled={this.props.reportedPersonPersonalInfo.knowDateOfBirth === 'Yes'
                                                        &&
                                                        (!isValidDate(this.props.reportedPersonPersonalInfo.dateOfBirthDay, this.props.reportedPersonPersonalInfo.dateOfBirthMonth, this.props.reportedPersonPersonalInfo.dateOfBirthYear)
                                                            || !isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthDay, 'dob')
                                                            || !isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthMonth, 'dob')
                                                            || !isNumber(this.props.reportedPersonPersonalInfo.dateOfBirthYear, 'dob'))}
                                            onClick={this.toggleDetails}>{this.state.changeButtonDoB}<span className="visually-hidden">  date of birth</span></button>
                                </dd>
                            </div>

                             Row III: Address
                            <div className={(!multiSelectListValueIsChecked(this.props.suspectedPersonRequiredInformation, 'City or Town')) ? "govuk-summary-list__rowHidden" : "govuk-summary-list__row"}>
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Address</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {(this.state.showAddressDetails === false) ?
                                        <div className="" id="editable-address-details">
                                            <label htmlFor="reportedPersonPersonalInfo.addressHouseNumber" className="form-text">House number/Building name</label>
                                            <input type="text"
                                                   autoFocus
                                                   maxLength={this.props.maxLengthForText}
                                                   name="reportedPersonPersonalInfo.addressHouseNumber"
                                                   id="reportedPersonPersonalInfo.addressHouseNumber"
                                                   value={this.props.reportedPersonPersonalInfo.addressHouseNumber}
                                                   onChange={this.props.handleObjectChange} />
                                            <br/>

                                            <br/>
                                            <label htmlFor="reportedPersonPersonalInfo.addressStreetName" className="form-text">Street name</label>
                                            <input type="text"
                                                   maxLength={this.props.maxLengthForText}
                                                   name="reportedPersonPersonalInfo.addressStreetName"
                                                   id="reportedPersonPersonalInfo.addressStreetName"
                                                   value={this.props.reportedPersonPersonalInfo.addressStreetName}
                                                   onChange={this.props.handleObjectChange} />
                                            <br/>

                                            <br/>
                                            <label htmlFor="reportedPersonPersonalInfo.addressCity" className="form-text">City/Town (<span className="red">required</span>)</label>
                                            <input type="text"
                                                   maxLength={this.props.maxLengthForText}
                                                   required={true}
                                                   name="reportedPersonPersonalInfo.addressCity"
                                                   id="reportedPersonPersonalInfo.addressCity"
                                                   value={this.props.reportedPersonPersonalInfo.addressCity}
                                                   className={this.props.errorClass.reportedPersonPersonalInfoaddressCity}
                                                   onChange={this.props.handleObjectChange} />
                                            <br/>

                                            <br/>
                                            <label htmlFor="reportedPersonPersonalInfo.addressPostcode" className="form-text">Postcode</label>
                                            <input type="text"
                                                   className="postcode"
                                                   maxLength={this.props.maxLengthForText}
                                                   name="reportedPersonPersonalInfo.addressPostcode"
                                                   id="reportedPersonPersonalInfo.addressPostcode"
                                                   value={this.props.reportedPersonPersonalInfo.addressPostcode}
                                                   onChange={this.props.handleObjectChange} />
                                            <br/>
                                        </div>
                                    :
                                        <div className="" id="addressDetails">
                                            <p hidden={(this.props.reportedPersonPersonalInfo.addressHouseNumber === '')}>House number: {(this.props.reportedPersonPersonalInfo.addressHouseNumber !== '') ? this.props.reportedPersonPersonalInfo.addressHouseNumber : '---'}</p>
                                            <p hidden={(this.props.reportedPersonPersonalInfo.addressStreetName === '')}>Street name: {(this.props.reportedPersonPersonalInfo.addressStreetName !== '') ? this.props.reportedPersonPersonalInfo.addressStreetName : '---'}</p>
                                            <p hidden={(this.props.reportedPersonPersonalInfo.addressCity === '')}>City: {(this.props.reportedPersonPersonalInfo.addressCity !== '') ? this.props.reportedPersonPersonalInfo.addressCity : '---'}</p>
                                            <p hidden={(this.props.reportedPersonPersonalInfo.addressPostcode === '')}>Post code: {(this.props.reportedPersonPersonalInfo.addressPostcode !== '') ? this.props.reportedPersonPersonalInfo.addressPostcode : '---'}</p>
                                        </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="addressDetails"
                                            disabled={this.props.reportedPersonPersonalInfo.addressCity === ''}
                                            onClick={this.toggleDetails}>{this.state.changeButtonAddress}<span className="visually-hidden">  address</span></button>
                                </dd>
                            </div>

                             Row IV: National Insurance Number
                            <div className={(!multiSelectListValueIsChecked(this.props.suspectedPersonOptionalInformation, 'National Insurance number')
                                                || (this.state.showNationalInsuranceDetails === true && this.props.reportedPersonPersonalInfo.nationalInsuranceNumber === '')
                                                || (this.state.showNationalInsuranceDetails === false && this.props.reportedPersonPersonalInfo.nationalInsuranceNumber === '' && this.state.modalName !== 'nationalInsuranceDetails'))
                                            ? "govuk-summary-list__rowHidden" : "govuk-summary-list__row"}>
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">National Insurance Number</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {(this.state.showNationalInsuranceDetails === false) ?
                                        <div className="" id="editable-nationalInsurance-details">
                                            <label htmlFor="reportedPersonPersonalInfo.nationalInsuranceNumber" className="form-text">National Insurance Number</label>
                                            <input type="text"
                                                   autoFocus
                                                   maxLength={this.props.maxLengthForText}
                                                   name="reportedPersonPersonalInfo.nationalInsuranceNumber"
                                                   value={this.props.reportedPersonPersonalInfo.nationalInsuranceNumber}
                                                   onChange={this.props.handleObjectChange} />
                                        </div>
                                    :
                                        <div className="" id="nationalInsuranceDetails">
                                            {(this.props.reportedPersonPersonalInfo.nationalInsuranceNumber !== '') ? this.props.reportedPersonPersonalInfo.nationalInsuranceNumber : '---'}
                                        </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="nationalInsuranceDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonNationalInsuranceNumber}<span className="visually-hidden">  national insurance number</span></button>
                                </dd>
                            </div>

                             Row V: Physical Characteristics
                            <div className={(!multiSelectListValueIsChecked(this.props.suspectedPersonOptionalInformation, 'Physical description')
                                                || (this.state.showPhysicalCharacteristicsDetails === true
                                                    && this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet === '' && this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches === ''
                                                    && this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild === '' && this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures === ''
                                                    && this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor === '')
                                                || (this.state.showPhysicalCharacteristicsDetails === false
                                                    && this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet === '' && this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches === ''
                                                    && this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild === '' && this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures === ''
                                                    && this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor === '' && this.state.modalName !== 'physicalCharacteristicsDetails'))
                                            ? "govuk-summary-list__rowHidden" : "govuk-summary-list__row"}>
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large">Physical Characteristics</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {(this.state.showPhysicalCharacteristicsDetails === false) ?
                                        <div id="editable-physicalCharacteristics-details" className="">

                                            <div className={(isValidHeight(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches)
                                                                && isNumber(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, 'feet')) ? '' : 'error'}>
                                                <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsFeet" className="form-text">Feet (approximately)</label>
                                                <input type="text"
                                                       autoFocus
                                                       name="reportedPersonPersonalInfo.physicalCharacteristicsFeet"
                                                       id="reportedPersonPersonalInfo.physicalCharacteristicsFeet"
                                                       value={this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet}
                                                       onChange={this.props.handleObjectChange} />
                                            </div>

                                            <div className={isValidHeight(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches)
                                                                && isNumber(this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches, 'inches')? '' : 'error'}>
                                                <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsInches" className="form-text">Inches (approximately)</label>
                                                <input type="text"
                                                       name="reportedPersonPersonalInfo.physicalCharacteristicsInches"
                                                       id="reportedPersonPersonalInfo.physicalCharacteristicsInches"
                                                       value={this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches}
                                                       onChange={this.props.handleObjectChange} />
                                            </div>

                                            <div className="errorMessage">
                                                <div hidden={isValidHeight(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches)
                                                                && isNumber(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, 'feet')
                                                                && isNumber(this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches, 'inches')}>
                                                    <p>The height selected is not valid!</p>
                                                </div>
                                            </div>

                                            <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsBuild" className="form-text">Build (slim, stocky, muscular, etc)</label>
                                            <input type="text"
                                                   maxLength={this.props.maxLengthForText}
                                                   name="reportedPersonPersonalInfo.physicalCharacteristicsBuild"
                                                   id="reportedPersonPersonalInfo.physicalCharacteristicsBuild"
                                                   value={this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild}
                                                   onChange={this.props.handleObjectChange} />

                                            <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsHairColor" className="form-text">Hair colour (brown, blonde, grey, etc)</label>
                                            <input type="text"
                                                   maxLength={this.props.maxLengthForText}
                                                   name="reportedPersonPersonalInfo.physicalCharacteristicsHairColor"
                                                   id="reportedPersonPersonalInfo.physicalCharacteristicsHairColor"
                                                   value={this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor}
                                                   onChange={this.props.handleObjectChange} />

                                            <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsFeatures" className="form-text">Distinctive features (tattoos, visible scars, glasses, etc)</label>
                                            <input type="text"
                                                   maxLength={this.props.maxLengthForText}
                                                   name="reportedPersonPersonalInfo.physicalCharacteristicsFeatures"
                                                   id="reportedPersonPersonalInfo.physicalCharacteristicsFeatures"
                                                   value={this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures}
                                                   onChange={this.props.handleObjectChange} />
                                        </div>
                                    :
                                        <div className="" id="physicalCharacteristicsDetails">
                                            {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet !== '' || this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches !== ''
                                                || this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild !== '' || this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor !== ''
                                                || this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures !== '')
                                                ?
                                                    <div className="">
                                                        <p hidden={(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet === '')}>Feet (approximately): {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet !== '') ? this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet : '---'}</p>
                                                        <p hidden={(this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches === '')}>Inches (approximately): {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches !== '') ? this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches : '---'}</p>
                                                        <p hidden={(this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild === '')}>Build (slim, stocky, etc): {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild !== '') ? this.props.reportedPersonPersonalInfo.physicalCharacteristicsBuild : '---'}</p>
                                                        <p hidden={(this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor === '')}>Hair color: {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor !== '') ? this.props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor : '---'}</p>
                                                        <p hidden={(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures === '')}>Distinctive features: {(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures !== '') ? this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures : '---'}</p>
                                                    </div>
                                                :
                                                    '---'
                                            }
                                        </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="physicalCharacteristicsDetails"
                                            disabled={!isValidHeight(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches)
                                                        || !isNumber(this.props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, 'feet')
                                                        || !isNumber(this.props.reportedPersonPersonalInfo.physicalCharacteristicsInches, 'inches')}
                                            onClick={this.toggleDetails}>{this.state.changeButtonPhysicalCharacteristics}<span className="visually-hidden"> physical characteristics</span></button>
                                </dd>
                            </div>

                             Row VI: Social Media
                            <div className={(!multiSelectListValueIsChecked(this.props.suspectedPersonOptionalInformation, 'Social media accounts')
                                                || (this.state.showSocialMediaDetails === true && !multiSelectListAnyValueChecked(this.props.reportedPersonSocialMediaChannels))
                                                || (this.state.showSocialMediaDetails === false && !multiSelectListAnyValueChecked(this.props.reportedPersonSocialMediaChannels) && this.state.modalName !== 'socialMediaDetails'))
                                            ? "govuk-summary-list__rowHidden" : "govuk-summary-list__row"}>
                                <dt className="govuk-summary-list__key">
                                    <span className="font-large" id="reportedPersonSocialMediaChannels">Social Media</span>
                                </dt>
                                <dd className="govuk-summary-list__value">
                                    {(this.state.showSocialMediaDetails === false) ?
                                        <div className="" id="editable-socialMedia-details">
                                            <p>Tick all that apply</p>
                                            <ul className="checkBoxListNoBullets" role="group" aria-labelledby="reportedPersonSocialMediaChannels">
                                                {
                                                    this.props.reportedPersonSocialMediaChannels.map((info, index) => {
                                                        return (
                                                            <CheckBox key={index}
                                                                      currentStep = {18}
                                                                      handleChangeInMultiSelectCheckBox={this.props.handleChangeInMultiSelectCheckBox} {...info} />
                                                        )
                                                    })
                                                }
                                            </ul>

                                            <div hidden={!multiSelectListAnyValueChecked(this.props.reportedPersonSocialMediaChannels)}>
                                                <p>If you know, please list their account/handle name(s) in the box that appears below.</p>
                                            </div>

                                            <div hidden={!multiSelectListValueIsChecked(this.props.reportedPersonSocialMediaChannels, "Facebook")}>
                                                <br/>
                                                <label htmlFor="reportedPersonPersonalInfo.socialMediaFacebookName" className="form-text">Tell us how the person's name appears on Facebook. For example, Joe Bloggs.</label>
                                                <input type="text"
                                                       maxLength={this.props.maxLengthForText}
                                                       name="reportedPersonPersonalInfo.socialMediaFacebookName"
                                                       id="reportedPersonPersonalInfo.socialMediaFacebookName"
                                                       value={this.props.reportedPersonPersonalInfo.socialMediaFacebookName}
                                                       onChange={this.props.handleObjectChange} />
                                                <br/>
                                            </div>

                                            <div hidden={!multiSelectListValueIsChecked(this.props.reportedPersonSocialMediaChannels, "Instagram")}>
                                                <br/>
                                                <label htmlFor="reportedPersonPersonalInfo.socialMediaInstagramName" className="form-text">Tell us the person's Instagram username. For example, @Joebloggs1.</label>
                                                <input type="text"
                                                       maxLength={this.props.maxLengthForText}
                                                       name="reportedPersonPersonalInfo.socialMediaInstagramName"
                                                       id="reportedPersonPersonalInfo.socialMediaInstagramName"
                                                       value={this.props.reportedPersonPersonalInfo.socialMediaInstagramName}
                                                       onChange={this.props.handleObjectChange} />
                                                <br/>
                                            </div>

                                            <div hidden={!multiSelectListValueIsChecked(this.props.reportedPersonSocialMediaChannels, "Twitter")}>
                                                <br/>
                                                <label htmlFor="reportedPersonPersonalInfo.socialMediaTwitterName" className="form-text">Tell us how the person's Twitter username. For example, @Joebloggs1.</label>
                                                <input type="text"
                                                       maxLength={this.props.maxLengthForText}
                                                       name="reportedPersonPersonalInfo.socialMediaTwitterName"
                                                       id="reportedPersonPersonalInfo.socialMediaTwitterName"
                                                       value={this.props.reportedPersonPersonalInfo.socialMediaTwitterName}
                                                       onChange={this.props.handleObjectChange} />
                                                <br/>
                                            </div>

                                            <div hidden={!multiSelectListValueIsChecked(this.props.reportedPersonSocialMediaChannels, "Snapchat")}>
                                                <br/>
                                                <label htmlFor="reportedPersonPersonalInfo.socialMediaSnapchatName" className="form-text">Tell us how the person's Snapchat username. For example, @Joebloggs1.</label>
                                                <input type="text"
                                                       maxLength={this.props.maxLengthForText}
                                                       name="reportedPersonPersonalInfo.socialMediaSnapchatName"
                                                       id="reportedPersonPersonalInfo.socialMediaSnapchatName"
                                                       value={this.props.reportedPersonPersonalInfo.socialMediaSnapchatName}
                                                       onChange={this.props.handleObjectChange} />
                                                <br/>
                                            </div>

                                            <div hidden={!multiSelectListValueIsChecked(this.props.reportedPersonSocialMediaChannels, "Other")}>
                                                <br/>
                                                <label htmlFor="reportedPersonPersonalInfo.socialMediaOtherName" className="form-text">Tell us what social media channel they use and their username.</label>
                                                <input type="text"
                                                       maxLength={this.props.maxLengthForText}
                                                       name="reportedPersonPersonalInfo.socialMediaOtherName"
                                                       id="reportedPersonPersonalInfo.socialMediaOtherName"
                                                       value={this.props.reportedPersonPersonalInfo.socialMediaOtherName}
                                                       onChange={this.props.handleObjectChange} />
                                                <br/>
                                            </div>

                                        </div>
                                    :
                                        <div className="" id="socialMediaDetails">
                                            {(
                                                (multiSelectListAnyValueChecked(this.props.reportedPersonSocialMediaChannels))
                                                    || (this.props.reportedPersonPersonalInfo.socialMediaFacebookName !== ''
                                                            || this.props.reportedPersonPersonalInfo.socialMediaInstagramName !== '' || this.props.reportedPersonPersonalInfo.socialMediaTwitterName !== ''
                                                            || this.props.reportedPersonPersonalInfo.socialMediaSnapchatName !== '' || this.props.reportedPersonPersonalInfo.socialMediaOtherName !== '')
                                                        )
                                                ?
                                                    beautifySocialMedia(this.props.reportedPersonSocialMediaChannels, this.props.reportedPersonPersonalInfo.socialMediaFacebookName,
                                                        this.props.reportedPersonPersonalInfo.socialMediaInstagramName, this.props.reportedPersonPersonalInfo.socialMediaTwitterName,
                                                        this.props.reportedPersonPersonalInfo.socialMediaSnapchatName, this.props.reportedPersonPersonalInfo.socialMediaOtherName)
                                                :
                                                    '---'
                                            }
                                        </div>
                                    }
                                </dd>
                                <dd className="govuk-summary-list__actions">
                                    <button type="button"
                                            className="link--button"
                                            name="modalName" value="socialMediaDetails"
                                            onClick={this.toggleDetails}>{this.state.changeButtonSocialMedia}<span className="visually-hidden"> social media</span></button>
                                </dd>
                            </div>
                        </dl>*/}
                    </div>
                    <br/>
                    <br/>
                    <p>If the information is correct, send this report to Social Security Scotland.</p>
                    <p>The report will be sent to Social Security Scotland’s fraud team to review. We cannot tell you the result of this report. This is because all benefit fraud reports are anonymous.</p>
                    <p>If you have used this form to report a case of fraud that has happened to you, we will retain your information. Our privacy notice can be found online at <a target="_blank" rel="noopener noreferrer" href="https://mygov.scot/social-security-data">mygov.scot/social-security-data</a>.</p>
                    <Recaptcha sitekey={this.props.captchaSiteKey}
                               render="explicit"
                               onloadCallback={this.props.recaptchaOnLoadCallback}
                               verifyCallback={this.props.recaptchaVerifyCallback} />

                </React.Fragment>
            )
        }
    }
}

/**
 *
 * @param knowDateOfBirth
 * @param day
 * @param month
 * @param year
 * @param ageRange
 * @returns {any}
 */
function beautifyDate(knowDateOfBirth, day, month, year, ageRange) {
    let beautifiedAge = ''
    if (knowDateOfBirth === 'Yes') {
        beautifiedAge = `${day} ${month} ${year}`
    } else if (knowDateOfBirth === 'No') {
        beautifiedAge = ageRange.concat(" (approximate age range)")
    }
    return beautifiedAge
}

/**
 *
 * @param day
 * @param month
 * @param year
 * @returns {string}
 */
function beautifyDateOnlyDates(day, month, year) {
    return `${day} ${month} ${year}`
}

/**
 *
 * @param canProvidePhysicalDescription
 * @param feet
 * @param inches
 * @returns {string}
 */
function beautifyPhysicalDescriptionFeetInches(canProvidePhysicalDescription, feet, inches) {
    let beautifiedFeetInches = '';
    if (canProvidePhysicalDescription === 'Yes') {
        if (feet !== '') {
            beautifiedFeetInches = feet.concat(" feet")
        }
        if (inches !== '') {
            beautifiedFeetInches = beautifiedFeetInches.concat(" ").concat(inches).concat(" inches")
        }
    }
    return beautifiedFeetInches
}

/**
 *
 * @param socialMediaChannels
 * @param socialMediaFacebookName
 * @param socialMediaInstagramName
 * @param socialMediaTwitterName
 * @param socialMediaSnapchatName
 * @param socialMediaOtherName
 * @returns {*}
 */
/*function beautifySocialMedia(socialMediaChannels, socialMediaFacebookName, socialMediaInstagramName, socialMediaTwitterName, socialMediaSnapchatName, socialMediaOtherName) {
    let activeSocialMedia = ""
    let activeSocialMediaAccounts = ""
    socialMediaChannels.forEach(function(channel) {
        if (channel.isChecked) {
            activeSocialMedia = activeSocialMedia.concat(channel.value).concat(", ")

            if (channel.value === 'Facebook') {
                activeSocialMediaAccounts = concatenateSocialMediaAccounts(activeSocialMediaAccounts, socialMediaFacebookName)
            } else if (channel.value === 'Instagram') {
                activeSocialMediaAccounts = concatenateSocialMediaAccounts(activeSocialMediaAccounts, socialMediaInstagramName)
            } else if (channel.value === 'Twitter') {
                activeSocialMediaAccounts = concatenateSocialMediaAccounts(activeSocialMediaAccounts, socialMediaTwitterName)
            } else if (channel.value === 'Snapchat') {
                activeSocialMediaAccounts = concatenateSocialMediaAccounts(activeSocialMediaAccounts, socialMediaSnapchatName)
            } else if (channel.value === 'Other') {
                activeSocialMediaAccounts = concatenateSocialMediaAccounts(activeSocialMediaAccounts, socialMediaOtherName)
            }
        }
    })
    if (activeSocialMedia.endsWith(", ")) {
        activeSocialMedia = activeSocialMedia.substring(0, activeSocialMedia.length - 2)
    }

    return (
        <React.Fragment>
            <p>Active Social Media: {(activeSocialMedia !== '') ? activeSocialMedia : '---'}</p>
            <p>Accounts: {(activeSocialMediaAccounts !== '') ? activeSocialMediaAccounts : '---'}</p>
        </React.Fragment>
    )
}*/

/**
 * Utility method to concat the Social Media accounts (if exist)
 *
 * @param valueBeforeConcat
 * @param valueToConcat
 * @returns {Buffer | any[] | string}
 */
/*function concatenateSocialMediaAccounts(valueBeforeConcat, valueToConcat) {
    if (valueBeforeConcat === '') {
        if (valueToConcat !== '') {
            valueBeforeConcat = valueBeforeConcat.concat(valueToConcat)
        }
    } else {
        if (valueToConcat !== '') {
            valueBeforeConcat = valueBeforeConcat.concat(", ").concat(valueToConcat)
        } else {
            valueBeforeConcat = valueBeforeConcat.concat(valueToConcat)
        }
    }

    return valueBeforeConcat
}*/

export default Step22;