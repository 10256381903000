import React from 'react';

/**
 * Component representing 'Child or Pregnancy benefit --> Other' part of the fourth page (see 'Page four: Full Form' at https://10t3ux.axshare.com/)
 *      , it shows up when 'Other' is selected in step11.js
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step15(props) {

    if (props.currentStep !== 15) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-15">

                <h1>Benefit payment(s) for a child</h1>

                <label htmlFor="reportedPersonBenefitPaymentForChildrenCommentBox" className="form-text-tell-us-what-u-think">Tell us why you think the application for this has been made falsely. Add any additional information you know.</label>
                <span>This can include:
                    <ul>
                        <li>the child's date of birth</li>
                        <li>who the child is currently living with</li>
                    </ul>
                </span>
                <textarea name="reportedPersonBenefitPaymentForChildrenCommentBox"
                          id="reportedPersonBenefitPaymentForChildrenCommentBox"
                          rows={5} cols={80}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForChildrenCommentBox}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForChildrenCommentBox'}
                          onChange={props.handleChange} />

            </div>
        </React.Fragment>
    )

}

export default Step15