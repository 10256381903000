
/**
 * Helper function to search if a specific value from the list, is checked
 *
 * @param list
 * @param searchValue
 * @returns {boolean}
 */
export function multiSelectListValueIsChecked(list, searchValue) {

    let isChecked = false
    list.forEach(value => {
        if (value.value === searchValue && value.isChecked === true) {
            isChecked = true
        }
    })
    return isChecked
}

/**
 * Helper function to search if any value from the list, is checked
 *
 * @param list
 * @returns {boolean}
 */
export function multiSelectListAnyValueChecked(list) {

    let isChecked = false
    list.forEach(value => {
        if (value.isChecked === true) {
            isChecked = true
        }
    })
    return isChecked
}

/**
 *
 * @param id
 * @returns {string}
 */
export function generateElementIdForClubTeamSocialGroup(id) {
    return 'doubtful-disability-claim-for-an-adult-clubs-teams-social-groups-'.concat(id)
}

/**
 *
 * @param id
 * @returns {string}
 */
export function generateElementIdForTransportationForm(id) {
    return 'doubtful-disability-claim-for-an-adult-transportation-forms-'.concat(id)
}

/**
 *
 * @param id
 * @returns {string}
 */
export function generateElementIdForDisabilityChild(id) {
    return 'doubtful-disability-claim-for-children-child-'.concat(id)
}

/**
 *
 * @param id
 * @returns {string}
 */
export function generateChangeButtonValueForDisabilityChild(id) {
    return 'doubtfulDisabilityClaimForChildrenChild-'.concat(id)
}

/**
 *
 * @param id
 * @returns {string}
 */
export function generateElementIdForCaringChild(id) {
    return 'payments-for-pregnancy-or-children-under-6-type-of-child-or-pregnancy-person-currently-caring-for-child-or-children-child-'.concat(id)
}

/**
 *
 * @param id
 * @returns {string}
 */
export function generateChangeButtonValueForCaringChild(id) {
    return 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonCurrentlyCaringForChildOrChildrenChild-'.concat(id)
}

/**
 *
 * @param id
 * @returns {string}
 */
export function generateElementIdForNotCaringChild(id) {
    return 'payments-for-pregnancy-or-children-under-6-type-of-child-or-pregnancy-person-no-longer-cares-for-child-or-children-child-'.concat(id)
}

/**
 *
 * @param id
 * @returns {string}
 */
export function generateChangeButtonValueForNotCaringChild(id) {
    return 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonNoLongerCaresForChildOrChildrenChild-'.concat(id)
}

/**
 * Helper function to disable placeholder values in drop-down select lists (when another value is selected by the user)
 *
 * @param currentStep
 * @param dropDownName
 * @param selectedValue
 * @returns {boolean}
 */
export function disableSelectPlaceholders(currentStep, dropDownName, selectedValue) {

    let toDisable = false
    if (currentStep === 7) {
        if (dropDownName === 'reportedPersonAgeRange'
                && (selectedValue !== 'Please select age range' && selectedValue !== '')) {
            toDisable = true
        }
    } else if (currentStep === 19) {
        if (dropDownName === 'fraudulentIdentityAgeRange'
                && (selectedValue !== 'Please select age range' && selectedValue !== '')) {
            toDisable = true
        }
    }
    return toDisable
}

/**
 * Used to generate label by key for JSON fields of the object to submit
 *
 * @param key
 * @returns {string}
 */
export function generateLabelByKey(key) {
    if (key === 'hasReceivedBenefit') {
        return 'Has received benefit'
    } else if (key === 'suspectedBenefitFraudTypes') {
        return 'Suspected benefit fraud types'
    } else if (key === 'carerBetween') {
        return 'Carer between 16 and 18 years old'
    } else if (key === 'childDisabilityUnder18') {
        return 'Disability benefit for child under the age of 18'
    } else if (key === 'reportedPersonIsSgEmployee') {
        return 'Reported person is a Scottish Gov Employee'
    } else if (key === 'reportedPersonSgEmployee') {
        return 'Reported person Scottish Gov Employee'
    } else if (key === 'sgEmployeeInvolved') {
        return 'Another Scottish Gov Employee involved'
    } else if (key === 'involvedScottishGovEmployee') {
        return 'Involved Scottish Gov Employee'
    } else if (key === 'reportedPersonPersonalInfo') {
        return 'Reported person Personal Info'
    } else if (key === 'reportedPersonFuneralSupportPayment') {
        return 'Reported person Funeral Support Payment'
    } else if (key === 'reportedPersonBenefitPaymentForDisabilityAdult') {
        return 'Benefit payment for false or doubtful disability relating to adult'
    } else if (key === 'receivingHelpAtHome') {
        return 'Receiving help at home'
    } else if (key === 'receivingHelpAtHomeDetails') {
        return 'Receiving help at home (details)'
    } else if (key === 'canTheyLookAfterThemselves') {
        return 'Can they look after themselves'
    } else if (key === 'useMobilityEquipment') {
        return 'Use of mobility equipment'
    } else if (key === 'useMobilityEquipmentDetails') {
        return 'Use of mobility equipment (details)'
    } else if (key === 'isThePersonEmployed') {
        return 'Is the person employed'
    } else if (key === 'whatTypeOfWorkTheyDo') {
        return 'What type of work they do'
    } else if (key === 'howLongTheyHaveBeenEmployed') {
        return 'How long they have been employed'
    } else if (key === 'whoIsTheirEmployer') {
        return 'Who is their employer'
    } else if (key === 'doesTheEmployerKnowAboutTheirDisability') {
        return 'Does the employer know about their disability'
    } else if (key === 'didEmployerMakeAdjustmentsForDisability') {
        return 'Did employer make adjustments for disability'
    } else if (key === 'doTheyWorkFromHome') {
        return 'Do they work from home'
    } else if (key === 'howDoTheyTravelToWork') {
        return 'How do they travel to work'
    } else if (key === 'dailyRoutineNormalDay') {
        return 'Daily routine (normal day)'
    } else if (key === 'dailyRoutineNotEligibleForBenefit') {
        return 'Daily routine (not eligible for benefit)'
    } else if (key === 'doTheyBelongToAnyClubTeamSocialGroup') {
        return 'Do they belong to any Club, Team or Social Group'
    } else if (key === 'clubsTeamsSocialGroups') {
        return 'List of Clubs, Teams or Social Groups'
    } else if (key === 'name') {
        return 'Name'
    } else if (key === 'location') {
        return 'Location'
    } else if (key === 'howOftenTheyAttend') {
        return 'How often they attend'
    } else if (key === 'transportationForms') {
        return 'Transportation Forms'
    } else if (key === 'detailsOfTransportation') {
        return 'Details of Transportation'
    } else if (key === 'furtherInformation') {
        return 'Further Information'
    } else if (key === 'reportedPersonBenefitForChildOrPregnancy') {
        return 'Reported person Benefit Payment for Child or Pregnancy'
    } else if (key === 'personsReceivingBenefits') {
        return 'Reported person (sum of people receiving benefits for)'
    } else if (key === 'benefitReceivingPersonFirst') {
        return 'First benefit receiving person'
    } else if (key === 'benefitReceivingPersonSecond') {
        return 'Second benefit receiving person'
    } else if (key === 'benefitReceivingPersonThird') {
        return 'Third benefit receiving person'
    } else if (key === 'benefitReceivingPersonExtraInfo') {
        return 'Benefit receiving person(s) (extra info)'
    } else if (key === 'benefitPaymentForChildren') {
        return 'Benefit payment for children'
    } else if (key === 'benefitPaymentForCaringTotalChildren') {
        return 'Benefit payment for \'currently caring\' children (total children)'
    } else if (key === 'benefitPaymentForCaringChildrenFirst') {
        return 'Benefit payment for \'currently caring\' children (first child)'
    } else if (key === 'benefitPaymentForCaringChildrenSecond') {
        return 'Benefit payment for \'currently caring\' children (second child)'
    } else if (key === 'benefitPaymentForCaringChildrenThird') {
        return 'Benefit payment for \'currently caring\' children (third child)'
    } else if (key === 'benefitPaymentForCaringChildrenFourth') {
        return 'Benefit payment for \'currently caring\' children (fourth child)'
    } else if (key === 'benefitPaymentForCaringChildrenFifth') {
        return 'Benefit payment for \'currently caring\' children (fifth child)'
    } else if (key === 'reportedPersonBenefitPaymentForNotCaringChildrenTotalChildren') {
        return 'Benefit payment for \'not caring\' children (total children)'
    } else if (key === 'reportedPersonBenefitPaymentForNotCaringChildrenFirstChild') {
        return 'Benefit payment for \'not caring\' children (first child)'
    } else if (key === 'reportedPersonBenefitPaymentForNotCaringChildrenSecondChild') {
        return 'Benefit payment for \'not caring\' children (second child)'
    } else if (key === 'reportedPersonBenefitPaymentForNotCaringChildrenThirdChild') {
        return 'Benefit payment for \'not caring\' children (third child)'
    } else if (key === 'reportedPersonBenefitPaymentForNotCaringChildrenFourthChild') {
        return 'Benefit payment for \'not caring\' children (fourth child)'
    } else if (key === 'reportedPersonBenefitPaymentForNotCaringChildrenFifthChild') {
        return 'Benefit payment for \'not caring\' children (fifth child)'
    } else if (key === 'reportedPersonBenefitPaymentForChildrenCommentBox') {
        return 'Benefit payment for children (Comment box)'
    } else if (key === 'reportedPersonDetailsOfPersonBeingCaredFor') {
        return 'Benefit payment for providing care'
    } else if (key === 'reportedPersonFraudulentIdentity') {
        return 'Benefit payment using fraudulent identity'
    } else if (key === 'reportedPersonUndeclaredIncomeAreas') {
        return 'Reported person undeclared income areas'
    } else if (key === 'reportedPersonUndeclaredIncome') {
        return 'Reported person undeclared income'
    } else if (key === 'reportedPersonOutsideUK') {
        return 'Reported person living outside the UK'
    } else if (key === 'reportedPersonOutsideSco') {
        return 'Reported person living outside Scotland'
    } else if (key === 'reportedPersonPrison') {
        return 'Reported person In Prison'
    } else if (key === 'reportedPersonBenefitPaymentForCaringChildren') {
        return 'Benefit payment for \'currently caring\' children - list of children'
    } else if (key === 'reportedPersonBenefitPaymentForNotCaringChildren') {
        return 'Benefit payment for \'currently not caring\' children - list of children'
    } else if (key === 'reportedPersonBenefitPaymentForDisabilityInformation') {
        return 'Benefit payment for false or doubtful disability relating to child (children) - information'
    } else if (key === 'reportedPersonBenefitPaymentForDisabilityChildren') {
        return 'Benefit payment for false or doubtful disability relating to child (children) - list of children'
    } else if (key === 'reportedPersonJobStartPayment') {
        return 'Reported person Job Start Payment'
    } else if (key === 'id') {
        return 'ID'
    } else if (key === 'firstName' || key === 'whoChildLivesWithFirstName') {
        return 'First Name'
    } else if (key === 'lastName') {
        return 'Last Name'
    } else if (key === 'surname' || key === 'whoChildLivesWithSurname') {
        return 'Surname'
    } else if (key === 'jobRoleOrTitle') {
        return 'Job Role (or Title)'
    } else if (key === 'jobTitle') {
        return 'Job Title'
    } else if (key === 'physicalDescription') {
        return 'Physical Description'
    } else if (key === 'whatMakesYouThinkPersonIsInvolved') {
        return 'What makes you think person is involved'
    } else if (key === 'hasAlternativeName') {
        return 'Has Alternative Name'
    } else if (key === 'alternativeName') {
        return 'Alternative Name'
    } else if (key === 'areaOfWork') {
        return 'Area of Work'
    } else if (key === 'areaOfWorkDetails') {
        return 'Area of Work (details)'
    } else if (key === 'associationType') {
        return 'Association Type'
    } else if (key === 'associationTypeOther') {
        return 'Other Association Type'
    } else if (key === 'addressHouseNumber' || key === 'whoChildLivesWithKnowAddressHouseNumber'
                || key === 'houseNumber') {
        return 'Address - House Number'
    } else if (key === 'addressStreetName' || key === 'whoChildLivesWithKnowAddressStreetName'
                || key === 'streetName') {
        return 'Address - Street Name'
    } else if (key === 'addressCity' || key === 'whoChildLivesWithKnowAddressCity'
                || key === 'city') {
        return 'Address - City'
    } else if (key === 'addressPostcode' || key === 'whoChildLivesWithKnowAddressPostcode'
                || key === 'postcode') {
        return 'Address - Postcode'
    } else if (key === 'commentBox') {
        return 'Comment Box (why do you think this person is committing fraud)'
    } else if (key === 'fakeOrFalseDocumentsCommentBox') {
        return 'Comment Box (related to reported person has use of fake or false documents)'
    } else if (key === 'knowAddress') {
        return 'Know Address'
    } else if (key === 'knowCurrentAddress') {
        return 'Know Current Address'
    } else if (key === 'knowDateOfBirth') {
        return 'Know Date of Birth'
    } else if (key === 'dateOfBirthDay') {
        return 'Date of Birth (Day)'
    } else if (key === 'dateOfBirthMonth') {
        return 'Date of Birth (Month)'
    } else if (key === 'dateOfBirthYear') {
        return 'Date of Birth (Year)'
    } else if (key === 'approximateDateOfFuneral') {
        return 'Approximate Date of Funeral'
    } else if (key === 'approximateDateOfPersonDied') {
        return 'Approximate Date of Person\'s Death'
    } else if (key === 'ageRange') {
        return 'Age Range'
    } else if (key === 'knowNationalInsuranceNumber') {
        return 'Know National Insurance Number'
    } else if (key === 'nationalInsuranceNumber') {
        return 'National Insurance Number'
    } else if (key === 'canProvidePhysicalDescription') {
        return 'Can Provide Physical Description'
    } else if (key === 'physicalCharacteristicsFeet') {
        return 'Approximate Height (Feet)'
    } else if (key === 'physicalCharacteristicsInches') {
        return 'Approximate Height (Inches)'
    } else if (key === 'physicalCharacteristicsBuild') {
        return 'Build'
    } else if (key === 'physicalCharacteristicsHairColor') {
        return 'Hair Color'
    } else if (key === 'physicalCharacteristicsFeatures') {
        return 'Features'
    } else if (key === 'knowSocialMediaAccounts') {
        return 'Know Social Media Accounts'
    } else if (key === 'socialMediaInstagramName') {
        return 'Social Media (Instagram name)'
    } else if (key === 'socialMediaTwitterName') {
        return 'Social Media (Twitter name)'
    } else if (key === 'socialMediaFacebookName') {
        return 'Social Media (Facebook name)'
    } else if (key === 'socialMediaSnapchatName') {
        return 'Social Media (Snapchat name)'
    } else if (key === 'socialMediaOtherName') {
        return 'Social Media (Other)'
    } else if (key === 'knowTheNameOfDeceasedPerson') {
        return 'Know the Name of Deceased Person'
    } else if (key === 'deceasedPersonFirstName') {
        return 'Deceased Person (First Name)'
    } else if (key === 'deceasedPersonSurname') {
        return 'Deceased Person (Surname)'
    } else if (key === 'knowTheDateOfPersonDied') {
        return 'Know the Date of Person Died'
    } else if (key === 'dateOfPersonDiedDay') {
        return 'Date of Person died (Day)'
    } else if (key === 'dateOfPersonDiedMonth') {
        return 'Date of Person died (Month)'
    } else if (key === 'dateOfPersonDiedYear') {
        return 'Date of Person died (Year)'
    } else if (key === 'knowTheDateOfFuneral') {
        return 'Know the Date of Funeral'
    } else if (key === 'dateOfFuneralDay') {
        return 'Date of Funeral (Day)'
    } else if (key === 'dateOfFuneralMonth') {
        return 'Date of Funeral (Month)'
    } else if (key === 'dateOfFuneralYear') {
        return 'Date of Funeral (Year)'
    } else if (key === 'disabilityReceivingBenefitFor') {
        return 'Disability the adult or child/children is receiving benefit(s) for'
    } else if (key === 'eligibilityDoubtingReason') {
        return 'Doubting reason for the eligibility of receiving the disability benefit(s)'
    } else if (key === 'benefitFraudDuration') {
        return 'Benefit fraud duration'
    } else if (key === 'usingMobilityVehicles') {
        return 'Using Mobility Vehicles'
    } else if (key === 'mobilityVehicleDetails') {
        return 'Mobility Vehicle Details'
    } else if (key === 'otherInformation') {
        return 'Other Information'
    } else if (key === 'whyApplicationMadeFalsely') {
        return 'Why application was made falsely'
    } else if (key === 'sameAddressAsReportedPerson') {
        return 'Same Address as Reported Person'
    } else if (key === 'hasUsedFakeOrFalseDocuments') {
        return 'Has used fake or false documents'
    } else if (key === 'knowDetails') {
        return 'Know Details'
    } else if (key === 'approximateAge') {
        return 'Approximate Age'
    } else if (key === 'whenLeftPersonCare') {
        return 'When left Person Care'
    } else if (key === 'removedByLocalCouncil') {
        return 'Removed by Local Council'
    } else if (key === 'removedByLocalCouncilArea') {
        return 'Local Council Area'
    } else if (key === 'whoChildLivesWith') {
        return 'Know with who the child lives with'
    } else if (key === 'whoChildLivesWithKnowAddress') {
        return 'Know address of who the child lives with'
    } else if (key === 'careArrangementTemporary') {
        return 'Care Arrangement Temporary'
    } else if (key === 'careArrangementDuration') {
        return 'Care Arrangement Duration'
    } else if (key === 'property') {
        return 'Property'
    } else if (key === 'country') {
        return 'Country'
    } else if (key === 'hasAddress') {
        return 'Has Address'
    } else if (key === 'knowCurrentAddressAbroad') {
        return 'Know current Address Abroad'
    } else if (key === 'alreadyGivenAddressAbroad') {
        return 'Already given Address Abroad'
    } else if (key === 'addressAbroadHouseNumber') {
        return 'Address (abroad) - House Number'
    } else if (key === 'addressAbroadStreetName') {
        return 'Address (abroad) - Street Name'
    } else if (key === 'addressAbroadCity') {
        return 'Address (abroad) - City'
    } else if (key === 'addressAbroadPostcode') {
        return 'Address (abroad) - Postcode'
    } else if (key === 'knowWhenTheyMoved') {
        return 'Know when they Moved'
    } else if (key === 'knowWhenTheyMovedMonth') {
        return 'Month'
    } else if (key === 'knowWhenTheyMovedYear') {
        return 'Year'
    } else if (key === 'movePermanentOrTemporary') {
        return 'Permanent or Temporary'
    } else if (key === 'knowAddressInScotland') {
        return 'Know Address in Scotland'
    } else if (key === 'addressInScotlandAlreadyGiven') {
        return 'Address (in Scotland) already given'
    } else if (key === 'addressInScotlandHouseNumber') {
        return 'Address (in Scotland) - House Number'
    } else if (key === 'addressInScotlandStreetName') {
        return 'Address (in Scotland) - Street Name'
    } else if (key === 'addressInScotlandCity') {
        return 'Address (in Scotland) - City'
    } else if (key === 'addressInScotlandPostcode') {
        return 'Address (in Scotland) - Postcode'
    } else if (key === 'extraInfo') {
        return 'Extra Info'
    } else if (key === 'prisonName') {
        return 'Prison Name'
    } else if (key === 'startDateMonth') {
        return 'Start Date (Month)'
    } else if (key === 'startDateYear') {
        return 'Start Date (Year)'
    } else if (key === 'sentencePeriod') {
        return 'Sentence Period'
    } else if (key === 'currentlyEmployed') {
        return 'Currently Employed'
    } else if (key === 'whereDoTheyWork') {
        return 'Where do they work'
    } else if (key === 'haveBeenEmployedTheLast6Months') {
        return 'Have been employed the last 6 months'
    } else if (key === 'whereDidTheyWork') {
        return 'Where did they work'
    } else if (key === 'reportAdditionalInformation') {
        return 'Report additional information'
    } else if (key === 'howDoYouKnowTheInformation') {
        return 'How do you know the information in this report'
    } else if (key === 'whenDidYouFirstLearnTheInformation') {
        return 'When did you first learn of this information'
    } else if (key === 'whenDidYouLastKnowItToBeTrue') {
        return 'When did you last know it to be true'
    } else if (key === 'onlyPersonWhoKnowsTheInformation') {
        return 'Are you the only person who knows this information'
    } else if (key === 'happyToActOnInformationProvided') {
        return 'Are you happy for us to act on the information you provided'
    } else {
        return ''
    }
}

export function findFocusElementIdByChangeButtonValue(currentStep, changeButtonValue,
                                                      hasAlternativeName, knowDateOfBirth, knowNationalInsuranceNumber, canProvidePhysicalDescription, knowSocialMediaAccounts,
                                                      reportedPersonIsScottishGovEmployee, scottishGovEmployeeInvolved,
                                                      funeralSupportPaymentKnowTheNameOfTheDeceasedPerson, funeralSupportPaymentKnowTheDateThePersonDied, funeralSupportPaymentKnowTheDateOfTheFuneral,
                                                      disabilityAdultReceivingHelpAtHome, disabilityAdultUseMobilityEquipment, disabilityAdultIsThePersonEmployed, disabilityAdultDoesTheEmployerKnowAboutTheirDisability,
                                                      disabilityAdultDoTheyWorkFromHome, disabilityAdultDoTheyBelongToAnyClubTeamSocialGroup,
                                                      disabilityClaimForChildrenUseAnyMobilityVehicles,
                                                      pregnancyOrCaringBenefitCurrentlyCaringChildKnowDetails,
                                                      pregnancyOrCaringBenefitNoLongerCaringChildKnowDetails,
                                                      benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOf, benefitsReceivedForProvidingCareAddressSameAsOneOfThePersonYouAreReporting,
                                                      usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentity, usingFraudulentIdentityKnowDateOfBirthThePersonIsUsingWithThisIdentity,
                                                      usingFraudulentIdentityKnowAddressOrAddressesThePersonIsUsingWithThisIdentity, usingFraudulentIdentityHasUsedFakeOrFalseDocumentsWithThisIdentity,
                                                      notLivingInScotlandCountryPersonIsLivingPermanentlyOrTemporarily, notLivingInScotlandKnowPersonCurrentAddress,
                                                      notLivingInScotlandHaveYouAlreadyGivenTheirAddressAbroad, notLivingInScotlandKnowAddressInScotland, notLivingInScotlandHaveYouAlreadyGivenTheirAddressInScotland,
                                                      jobStartPaymentAreTheyCurrentlyEmployed, jobStartPaymentBeenEmployedForTheLast6Months,
                                                      additionalInformationOnlyPersonWhoKnowsTheInformation, additionalInformationHappyToActOnInformationProvided) {

    /*console.log('**** currentStep: ', currentStep)
    console.log('&&&& changeButtonValue: ', changeButtonValue)*/

    if (currentStep === 7) {
        if (changeButtonValue === 'firstNameDetails') {
            return 'reportedPersonPersonalInfo.firstName'
        } else if (changeButtonValue === 'surnameDetails') {
            return 'reportedPersonPersonalInfo.surname'
        } else if (changeButtonValue === 'hasAlternativeNameDetails') {
            if (hasAlternativeName !== '') {
                if (hasAlternativeName === 'Yes') {
                    return 'reportedPersonPersonalInfo.hasAlternativeNameYes'
                } else if (hasAlternativeName === 'No') {
                    return 'reportedPersonPersonalInfo.hasAlternativeNameNo'
                } else {
                    return 'reportedPersonPersonalInfo.hasAlternativeNameDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'addressDetails') {
            return 'reportedPersonPersonalInfo.addressHouseNumber'
        } else if (changeButtonValue === 'ageDetails') {
            if (knowDateOfBirth !== '') {
                if (knowDateOfBirth === 'Yes') {
                    return 'reportedPersonPersonalInfo.knowDateOfBirthYes'
                } else if (knowDateOfBirth === 'No') {
                    return 'reportedPersonPersonalInfo.knowDateOfBirthNo'
                }
            }
        } else if (changeButtonValue === 'nationalInsuranceDetails') {
            if (knowNationalInsuranceNumber !== '') {
                if (knowNationalInsuranceNumber === 'Yes') {
                    return 'reportedPersonPersonalInfo.knowNationalInsuranceNumberYes'
                } else if (knowNationalInsuranceNumber === 'No') {
                    return 'reportedPersonPersonalInfo.knowNationalInsuranceNumberNo'
                }
            }
        } else if (changeButtonValue === 'physicalDescriptionDetails') {
            if (canProvidePhysicalDescription !== '') {
                if (canProvidePhysicalDescription === 'Yes') {
                    return 'reportedPersonPersonalInfo.canProvidePhysicalDescriptionYes'
                } else if (canProvidePhysicalDescription === 'No') {
                    return 'reportedPersonPersonalInfo.canProvidePhysicalDescriptionNo'
                }
            }
        } else if (changeButtonValue === 'socialMediaDetails') {
            if (knowSocialMediaAccounts !== '') {
                if (knowSocialMediaAccounts === 'Yes') {
                    return 'reportedPersonPersonalInfo.knowSocialMediaAccountsYes'
                } else if (knowSocialMediaAccounts === 'No') {
                    return 'reportedPersonPersonalInfo.knowSocialMediaAccountsNo'
                }
            }
        }
    } else if (currentStep === 8) {
        if (changeButtonValue === 'isSocialSecurityScotlandEmployee') {
            if (reportedPersonIsScottishGovEmployee !== '') {
                if (reportedPersonIsScottishGovEmployee === 'Yes') {
                    return 'reportedPersonIsScottishGovEmployeeYes'
                } else if (reportedPersonIsScottishGovEmployee === 'No') {
                    return 'reportedPersonIsScottishGovEmployeeNo'
                } else {
                    return 'reportedPersonIsScottishGovEmployeeDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'isSocialSecurityScotlandEmployeeJobRole') {
            return 'reportedPersonScottishGovEmployee.jobRoleOrTitle'
        } else if (changeButtonValue === 'isAnotherSocialSecurityScotlandEmployeeInvolved') {
            if (scottishGovEmployeeInvolved !== '') {
                if (scottishGovEmployeeInvolved === 'Yes') {
                    return 'scottishGovEmployeeInvolvedYesWorksFor'
                } else if (scottishGovEmployeeInvolved === 'No') {
                    return 'scottishGovEmployeeInvolvedNo'
                } else {
                    return 'scottishGovEmployeeInvolvedDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'isAnotherSocialSecurityScotlandEmployeeInvolvedFirstName') {
            return 'scottishGovEmployee.firstName'
        } else if (changeButtonValue === 'isAnotherSocialSecurityScotlandEmployeeInvolvedSurname') {
            return 'scottishGovEmployee.surname'
        } else if (changeButtonValue === 'isAnotherSocialSecurityScotlandEmployeeInvolvedJobTitle') {
            return 'scottishGovEmployee.jobTitle'
        } else if (changeButtonValue === 'isAnotherSocialSecurityScotlandEmployeeInvolvedPhysicalDescription') {
            return 'scottishGovEmployee.physicalDescription'
        } else if (changeButtonValue === 'isAnotherSocialSecurityScotlandEmployeeInvolvedAddress') {
            return 'scottishGovEmployee.addressHouseNumber'
        } else if (changeButtonValue === 'isAnotherSocialSecurityScotlandEmployeeInvolvedWhatMakesYouThinkIsInvolved') {
            return 'scottishGovEmployee.whatMakesYouThinkPersonIsInvolved'
        }
    } else if (currentStep === 9) {
        if (changeButtonValue === 'disabilityClaimForAnAdultWhatDisabilityDoYouThinkThePersonIsReceivingBenefitsFor') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultReceivingAnyHelpAtHome') {
            if (disabilityAdultReceivingHelpAtHome !== '') {
                if (disabilityAdultReceivingHelpAtHome === 'Yes') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeYes'
                } else if (disabilityAdultReceivingHelpAtHome === 'No') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeNo'
                } else {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'disabilityClaimForAnAdultReceivingAnyHelpAtHomeMoreDetails') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultCanTheyLookAfterThemselves') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultUsingAnyMobilityEquipment') {
            if (disabilityAdultUseMobilityEquipment !== '') {
                if (disabilityAdultUseMobilityEquipment === 'Yes') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentYes'
                } else if (disabilityAdultUseMobilityEquipment === 'No') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentNo'
                } else {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'disabilityClaimForAnAdultUsingAnyMobilityEquipmentMoreDetails') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultIsThePersonEmployed') {
            if (disabilityAdultIsThePersonEmployed !== '') {
                if (disabilityAdultIsThePersonEmployed === 'Yes') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedYes'
                } else if (disabilityAdultIsThePersonEmployed === 'No') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedNo'
                } else {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'disabilityClaimForAnAdultPersonEmployedTypeOfWorkTheyDo') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultPersonEmployedHowLongEmployedFor') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultPersonEmployedWhoIsTheirEmployer') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultPersonEmployedDoesTheEmployerKnowAboutTheirDisability') {
            if (disabilityAdultDoesTheEmployerKnowAboutTheirDisability !== '') {
                if (disabilityAdultDoesTheEmployerKnowAboutTheirDisability === 'Yes') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityYes'
                } else if (disabilityAdultDoesTheEmployerKnowAboutTheirDisability === 'No') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityNo'
                } else {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'disabilityClaimForAnAdultPersonEmployedDidTheEmployerMakeAnyAdjustmentsToSupportWithTheirDisability') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultPersonEmployedDoTheyWorkFromHome') {
            if (disabilityAdultDoTheyWorkFromHome !== '') {
                if (disabilityAdultDoTheyWorkFromHome === 'Yes') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeYes'
                } else if (disabilityAdultDoTheyWorkFromHome === 'No') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeNo'
                } else {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'disabilityClaimForAnAdultPersonEmployedDoesNotWorkFromHomeHowDoTheyTravelToWork') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultDailyRoutineNormalDay') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultDailyRoutineNormalDayNotEligibleForBenefit') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultDoTheyBelongToClubsTeamsSocialGroups') {
            if (disabilityAdultDoTheyBelongToAnyClubTeamSocialGroup !== '') {
                if (disabilityAdultDoTheyBelongToAnyClubTeamSocialGroup === 'Yes') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupYes'
                } else if (disabilityAdultDoTheyBelongToAnyClubTeamSocialGroup === 'No') {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupNo'
                } else {
                    return 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'disabilityClaimForAnAdultMainFormsOfTransportation') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.transportationForms'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultDetailsOfTheirTransportation') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation'
        } else if (changeButtonValue === 'disabilityClaimForAnAdultFurtherInformation') {
            return 'reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation'
        }
    } else if (currentStep === 10) {
        if (changeButtonValue.startsWith("doubtfulDisabilityClaimForChildrenChild", 0)) {
            let splitArr = changeButtonValue.split('-')
            return 'reportedPersonBenefitPaymentForDisabilityChildren.'.concat(splitArr[1]).concat('.firstName')
        } else if (changeButtonValue === 'disabilityClaimForChildrenDisabilityReceivingBenefitsFor') {
            return 'reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor'
        } else if (changeButtonValue === 'disabilityClaimForChildrenDisabilityEligibilityDoubtingReason') {
            return 'reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason'
        } else if (changeButtonValue === 'disabilityClaimForChildrenDisabilityBenefitFraudDuration') {
            return 'reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration'
        } else if (changeButtonValue === 'disabilityClaimForChildrenUseAnyMobilityVehicles') {
            if (disabilityClaimForChildrenUseAnyMobilityVehicles !== '') {
                if (disabilityClaimForChildrenUseAnyMobilityVehicles === 'Yes') {
                    return 'reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesYes'
                } else if (disabilityClaimForChildrenUseAnyMobilityVehicles === 'No') {
                    return 'reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesNo'
                } else {
                    return 'reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'disabilityClaimForChildrenMobilityVehicleDetails') {
            return 'reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails'
        } else if (changeButtonValue === 'disabilityClaimForChildrenOtherInformation') {
            return 'reportedPersonBenefitPaymentForDisabilityInformation.otherInformation'
        }
    } else if (currentStep === 12) {
        if (changeButtonValue.startsWith("paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonCurrentlyCaringForChildOrChildrenChild", 0)) {
            let splitArr = changeButtonValue.split('-')
            if (pregnancyOrCaringBenefitCurrentlyCaringChildKnowDetails !== '') {
                if (pregnancyOrCaringBenefitCurrentlyCaringChildKnowDetails === 'Yes') {
                    return 'reportedPersonBenefitPaymentForCaringChildren.'.concat(splitArr[1]).concat('.knowDetailsYes')
                } else if (pregnancyOrCaringBenefitCurrentlyCaringChildKnowDetails === 'No') {
                    return 'reportedPersonBenefitPaymentForCaringChildren.'.concat(splitArr[1]).concat('.knowDetailsNo')
                }
            }
        } else if (changeButtonValue === 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonCurrentlyCaringForChildOrChildrenMoreDetails') {
            return 'reportedPersonBenefitPaymentForChildrenCommentBox'
        }
    } else if (currentStep === 13) {
        if (changeButtonValue.startsWith("paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonNoLongerCaresForChildOrChildrenChild", 0)) {
            let splitArr = changeButtonValue.split('-')
            if (pregnancyOrCaringBenefitNoLongerCaringChildKnowDetails !== '') {
                if (pregnancyOrCaringBenefitNoLongerCaringChildKnowDetails === 'Yes') {
                    return 'reportedPersonBenefitPaymentForNotCaringChildren.'.concat(splitArr[1]).concat('.knowDetailsYes')
                } else if (pregnancyOrCaringBenefitNoLongerCaringChildKnowDetails === 'No') {
                    return 'reportedPersonBenefitPaymentForNotCaringChildren.'.concat(splitArr[1]).concat('.knowDetailsNo')
                }
            }
        } else if (changeButtonValue === 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonNoLongerCaresForChildOrChildrenMoreDetails') {
            return 'reportedPersonBenefitPaymentForChildrenCommentBox'
        }
    } else if (currentStep === 14) {
        if (changeButtonValue === 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyPersonSaysTheyArePregnantButTheyAreNotMoreDetails') {
            return 'reportedPersonBenefitPaymentForChildrenCommentBox'
        }
    } else if (currentStep === 15) {
        if (changeButtonValue === 'paymentsForPregnancyOrChildrenUnder6TypeOfChildOrPregnancyOtherMoreDetails') {
            return 'reportedPersonBenefitPaymentForChildrenCommentBox'
        }
    } else if (currentStep === 16) {
        if (changeButtonValue === 'benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOf') {
            if (benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOf !== '') {
                if (benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOf === 'Yes') {
                    return 'reportedPersonDetailsOfPersonBeingCaredFor.knowDetailsYes'
                } else if (benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOf === 'No') {
                    return 'reportedPersonDetailsOfPersonBeingCaredFor.knowDetailsNo'
                }
            }
        } else if (changeButtonValue === 'benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOfFirstName') {
            return 'reportedPersonDetailsOfPersonBeingCaredFor.firstName'
        } else if (changeButtonValue === 'benefitsReceivedForProvidingCareKnowNameOfThePersonBeingCaredOfSurname') {
            return 'reportedPersonDetailsOfPersonBeingCaredFor.surname'
        } else if (changeButtonValue === 'benefitsReceivedForProvidingCareAddressSameAsOneOfThePersonYouAreReporting') {
            if (benefitsReceivedForProvidingCareAddressSameAsOneOfThePersonYouAreReporting !== '') {
                if (benefitsReceivedForProvidingCareAddressSameAsOneOfThePersonYouAreReporting === 'Yes') {
                    return 'reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonYes'
                } else if (benefitsReceivedForProvidingCareAddressSameAsOneOfThePersonYouAreReporting === 'No') {
                    return 'reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonNo'
                } else {
                    return 'reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'benefitsReceivedForProvidingCareAddressOfThePersonBeingCaredOf') {
            return 'reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber'
        } else if (changeButtonValue === 'benefitsReceivedForProvidingCareAnyOtherDetails') {
            return 'reportedPersonDetailsOfPersonBeingCaredFor.commentBox'
        }
    } else if (currentStep === 17) {
        if (changeButtonValue === 'jobStartPaymentAreTheyCurrentlyEmployed') {
            if (jobStartPaymentAreTheyCurrentlyEmployed !== '') {
                if (jobStartPaymentAreTheyCurrentlyEmployed === 'Yes') {
                    return 'reportedPersonJobStartPayment.currentlyEmployedYes'
                } else if (jobStartPaymentAreTheyCurrentlyEmployed === 'No') {
                    return 'reportedPersonJobStartPayment.currentlyEmployedNo'
                } else {
                    return 'reportedPersonJobStartPayment.currentlyEmployedDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'jobStartPaymentBeenEmployedForTheLast6Months') {
            if (jobStartPaymentBeenEmployedForTheLast6Months !== '') {
                if (jobStartPaymentBeenEmployedForTheLast6Months === 'Yes') {
                    return 'reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsYes'
                } else if (jobStartPaymentBeenEmployedForTheLast6Months === 'No') {
                    return 'reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsNo'
                } else {
                    return 'reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'jobStartPaymentWhereDoTheyWork') {
            return 'reportedPersonJobStartPayment.whereDoTheyWork'
        } else if (changeButtonValue === 'jobStartPaymentWhereDidTheyWork') {
            return 'reportedPersonJobStartPayment.whereDidTheyWork'
        } else if (changeButtonValue === 'jobStartPaymentExtraInfo') {
            return 'reportedPersonJobStartPayment.extraInfo'
        }
    } else if (currentStep === 18) {
        if (changeButtonValue === 'funeralSupportPaymentKnowTheNameOfTheDeceasedPerson') {
            if (funeralSupportPaymentKnowTheNameOfTheDeceasedPerson !== '') {
                if (funeralSupportPaymentKnowTheNameOfTheDeceasedPerson === 'Yes') {
                    return 'reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPersonYes'
                } else if (funeralSupportPaymentKnowTheNameOfTheDeceasedPerson === 'No') {
                    return 'reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPersonNo'
                }
            }
        } else if (changeButtonValue === 'funeralSupportPaymentDeceasedPersonFirstName') {
            return 'reportedPersonFuneralSupportPayment.deceasedPersonFirstName'
        } else if (changeButtonValue === 'funeralSupportPaymentDeceasedPersonSurname') {
            return 'reportedPersonFuneralSupportPayment.deceasedPersonSurname'
        } else if (changeButtonValue === 'funeralSupportPaymentKnowTheDateThePersonDied') {
            if (funeralSupportPaymentKnowTheDateThePersonDied !== '') {
                if (funeralSupportPaymentKnowTheDateThePersonDied === 'Yes') {
                    return 'reportedPersonFuneralSupportPayment.knowTheDateOfPersonDiedYes'
                } else if (funeralSupportPaymentKnowTheDateThePersonDied === 'No') {
                    return 'reportedPersonFuneralSupportPayment.knowTheDateOfPersonDiedNo'
                }
            }
        } else if (changeButtonValue === 'funeralSupportPaymentDateOfDeathOfPersonDied') {
            return 'reportedPersonFuneralSupportPayment.dateOfPersonDiedDay'
        } else if (changeButtonValue === 'funeralSupportPaymentApproximateDateOfPersonDied') {
            return 'reportedPersonFuneralSupportPayment.approximateDateOfPersonDied'
        } else if (changeButtonValue === 'funeralSupportPaymentKnowTheDateOfTheFuneral') {
            if (funeralSupportPaymentKnowTheDateOfTheFuneral !== '') {
                if (funeralSupportPaymentKnowTheDateOfTheFuneral === 'Yes') {
                    return 'reportedPersonFuneralSupportPayment.knowTheDateOfFuneralYes'
                } else if (funeralSupportPaymentKnowTheDateOfTheFuneral === 'No') {
                    return 'reportedPersonFuneralSupportPayment.knowTheDateOfFuneralNo'
                }
            }
        } else if (changeButtonValue === 'funeralSupportPaymentDateOfFuneral') {
            return 'reportedPersonFuneralSupportPayment.dateOfFuneralDay'
        } else if (changeButtonValue === 'funeralSupportPaymentApproximateDateOfFuneral') {
            return 'reportedPersonFuneralSupportPayment.approximateDateOfFuneral'
        } else if (changeButtonValue === 'funeralSupportPaymentWhyApplicationMadeFalsely') {
            return 'reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely'
        }
    } else if (currentStep === 19) {
        if (changeButtonValue === 'usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentity') {
            if (usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentity !== '') {
                if (usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentity === 'Yes') {
                    return 'reportedPersonFraudulentIdentity.knowDetailsYes'
                } else if (usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentity === 'No') {
                    return 'reportedPersonFraudulentIdentity.knowDetailsNo'
                }
            }
        } else if (changeButtonValue === 'usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentityFirstName') {
            return 'reportedPersonFraudulentIdentity.firstName'
        } else if (changeButtonValue === 'usingFraudulentIdentityKnowNameThePersonIsUsingWithThisIdentitySurname') {
            return 'reportedPersonFraudulentIdentity.surname'
        } else if (changeButtonValue === 'usingFraudulentIdentityKnowDateOfBirthThePersonIsUsingWithThisIdentity') {
            if (usingFraudulentIdentityKnowDateOfBirthThePersonIsUsingWithThisIdentity !== '') {
                if (usingFraudulentIdentityKnowDateOfBirthThePersonIsUsingWithThisIdentity === 'Yes') {
                    return 'reportedPersonFraudulentIdentity.knowDateOfBirthYes'
                } else if (usingFraudulentIdentityKnowDateOfBirthThePersonIsUsingWithThisIdentity === 'No') {
                    return 'reportedPersonFraudulentIdentity.knowDateOfBirthNo'
                }
            }
        } else if (changeButtonValue === 'usingFraudulentIdentityDateOfBirthThePersonIsUsingWithThisIdentity') {
            return 'reportedPersonFraudulentIdentity.dateOfBirthDay'
        } else if (changeButtonValue === 'usingFraudulentIdentityApproximateAgeThePersonIsUsingWithThisIdentity') {
            return 'reportedPersonFraudulentIdentity.approximateAge'
        } else if (changeButtonValue === 'usingFraudulentIdentityKnowAddressOrAddressesThePersonIsUsingWithThisIdentity') {
            if (usingFraudulentIdentityKnowAddressOrAddressesThePersonIsUsingWithThisIdentity !== '') {
                if (usingFraudulentIdentityKnowAddressOrAddressesThePersonIsUsingWithThisIdentity === 'Yes') {
                    return 'reportedPersonFraudulentIdentity.knowAddressYes'
                } else if (usingFraudulentIdentityKnowAddressOrAddressesThePersonIsUsingWithThisIdentity === 'No') {
                    return 'reportedPersonFraudulentIdentity.knowAddressNo'
                }
            }
        } else if (changeButtonValue === 'usingFraudulentIdentityAddressUsedWithThisIdentity') {
            return 'reportedPersonFraudulentIdentity.addressHouseNumber'
        } else if (changeButtonValue === 'usingFraudulentIdentityHasUsedFakeOrFalseDocumentsWithThisIdentity') {
            if (usingFraudulentIdentityHasUsedFakeOrFalseDocumentsWithThisIdentity !== '') {
                if (usingFraudulentIdentityHasUsedFakeOrFalseDocumentsWithThisIdentity === 'Yes') {
                    return 'reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsYes'
                } else if (usingFraudulentIdentityHasUsedFakeOrFalseDocumentsWithThisIdentity === 'No') {
                    return 'reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsNo'
                } else {
                    return 'reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'usingFraudulentIdentityMoreDetailsOnFakeOrFalseDocumentsUsedWithThisIdentity') {
            return 'reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox'
        } else if (changeButtonValue === 'usingFraudulentIdentityAnyOtherDetails') {
            return 'reportedPersonFraudulentIdentity.commentBox'
        }
    } else if (currentStep === 20) {
        if (changeButtonValue === 'notLivingInScotlandCountryPersonIsLiving') {
            return 'reportedPersonLivingOutsideScotland.country'
        } else if (changeButtonValue === 'notLivingInScotlandCountryPersonIsLivingPermanentlyOrTemporarily') {
            if (notLivingInScotlandCountryPersonIsLivingPermanentlyOrTemporarily !== '') {
                if (notLivingInScotlandCountryPersonIsLivingPermanentlyOrTemporarily === 'Permanently') {
                    return 'reportedPersonLivingOutsideScotland.movePermanentOrTemporaryPermanent'
                } else if (notLivingInScotlandCountryPersonIsLivingPermanentlyOrTemporarily === 'Temporarily') {
                    return 'reportedPersonLivingOutsideScotland.movePermanentOrTemporaryTemporary'
                } else {
                    return 'reportedPersonLivingOutsideScotland.movePermanentOrTemporaryDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'notLivingInScotlandKnowPersonCurrentAddress') {
            if (notLivingInScotlandKnowPersonCurrentAddress !== '') {
                if (notLivingInScotlandKnowPersonCurrentAddress === 'Yes') {
                    return 'reportedPersonLivingOutsideScotland.knowCurrentAddressAbroadYes'
                } else if (notLivingInScotlandKnowPersonCurrentAddress === 'No') {
                    return 'reportedPersonLivingOutsideScotland.knowCurrentAddressAbroadNo'
                }
            }
        } else if (changeButtonValue === 'notLivingInScotlandHaveYouAlreadyGivenTheirAddressAbroad') {
            if (notLivingInScotlandHaveYouAlreadyGivenTheirAddressAbroad !== '') {
                if (notLivingInScotlandHaveYouAlreadyGivenTheirAddressAbroad === 'Yes') {
                    return 'reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroadYes'
                } else if (notLivingInScotlandHaveYouAlreadyGivenTheirAddressAbroad === 'No') {
                    return 'reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroadNo'
                }
            }
        } else if (changeButtonValue === 'notLivingInScotlandCurrentAddressAbroad') {
            return 'reportedPersonLivingOutsideScotland.addressAbroadHouseNumber'
        } else if (changeButtonValue === 'notLivingInScotlandKnowWhenTheyMovedToThisAddress') {
            return 'reportedPersonLivingOutsideScotland.knowWhenTheyMoved'
        } else if (changeButtonValue === 'notLivingInScotlandKnowAddressInScotland') {
            if (notLivingInScotlandKnowAddressInScotland !== '') {
                if (notLivingInScotlandKnowAddressInScotland === 'Yes') {
                    return 'reportedPersonLivingOutsideScotland.knowAddressInScotlandYes'
                } else if (notLivingInScotlandKnowAddressInScotland === 'No') {
                    return 'reportedPersonLivingOutsideScotland.knowAddressInScotlandNo'
                }
            }
        } else if (changeButtonValue === 'notLivingInScotlandHaveYouAlreadyGivenTheirAddressInScotland') {
            if (notLivingInScotlandHaveYouAlreadyGivenTheirAddressInScotland !== '') {
                if (notLivingInScotlandHaveYouAlreadyGivenTheirAddressInScotland === 'Yes') {
                    return 'reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGivenYes'
                } else if (notLivingInScotlandHaveYouAlreadyGivenTheirAddressInScotland === 'No') {
                    return 'reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGivenNo'
                }
            }
        } else if (changeButtonValue === 'notLivingInScotlandCurrentAddressInScotland') {
            return 'reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber'
        } else if (changeButtonValue === 'notLivingInScotlandAnyOtherDetails') {
            return 'reportedPersonLivingOutsideScotland.extraInfo'
        }
    } else if (currentStep === 21) {
        if (changeButtonValue === 'additionalInformationHappyToActOnInformationProvided') {
            if (additionalInformationHappyToActOnInformationProvided !== '') {
                if (additionalInformationHappyToActOnInformationProvided === 'Yes') {
                    return 'reportAdditionalInformation.happyToActOnInformationProvidedYes'
                } else if (additionalInformationHappyToActOnInformationProvided === 'No') {
                    return 'reportAdditionalInformation.happyToActOnInformationProvidedNo'
                } else {
                    return 'reportAdditionalInformation.happyToActOnInformationProvidedDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'additionalInformationOnlyPersonWhoKnowsTheInformation') {
            if (additionalInformationOnlyPersonWhoKnowsTheInformation !== '') {
                if (additionalInformationOnlyPersonWhoKnowsTheInformation === 'Yes') {
                    return 'reportAdditionalInformation.onlyPersonWhoKnowsTheInformationYes'
                } else if (additionalInformationOnlyPersonWhoKnowsTheInformation === 'No') {
                    return 'reportAdditionalInformation.onlyPersonWhoKnowsTheInformationNo'
                } else {
                    return 'reportAdditionalInformation.onlyPersonWhoKnowsTheInformationDoNotKnow'
                }
            }
        } else if (changeButtonValue === 'additionalInformationWhenDidYouLastKnowItToBeTrue') {
            return 'reportAdditionalInformation.whenDidYouLastKnowItToBeTrue'
        } else if (changeButtonValue === 'additionalInformationWhenDidYouFirstLearnOfThisInformation') {
            return 'reportAdditionalInformation.whenDidYouFirstLearnTheInformation'
        } else if (changeButtonValue === 'additionalInformationHowDoYouKnowTheInformation') {
            return 'reportAdditionalInformation.howDoYouKnowTheInformation'
        }
    }
}