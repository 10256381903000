import React from 'react';

import BenefitNotCaringChild from "../utils/BenefitNotCaringChild";
import AddIcon from "@material-ui/icons/Add";

/**
 * Component representing 'Child or Pregnancy benefit --> Not Caring for Child' part of the fourth page (see 'Page four: Full Form' at https://10t3ux.axshare.com/)
 *      , it shows up when 'A person who no longer cares for a child or children' is selected in step11.js
 *
 * @param prop
 * @constructor
 */
function Step13(props) {

    if (props.currentStep !== 13) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-13">

                <h1>Receiving benefit(s) for child responsibility</h1>
                <div>
                    <p>This section applies to a person who is applying for or getting benefit payments for a child or children they're not responsible for. Or for a pregnancy. But does not qualify for this. If you’re providing details for more than five children, add this in the free text box below.</p>
                    <p>If you do not know the answer to a question, please leave it blank and move on to the next question.</p>
                </div>

                {
                    props.reportedPersonBenefitPaymentForNotCaringChildren.map((child, index) => {
                        return (
                            <BenefitNotCaringChild key={index}
                                                   autoFocusYes={props.focusElementId}
                                                   autoFocusNo={props.focusElementId}
                                                   maxLengthForText={props.maxLengthForText}
                                                   currentStep={props.currentStep}
                                                   listName="reportedPersonBenefitPaymentForNotCaringChildren"
                                                   listLength={props.reportedPersonBenefitPaymentForNotCaringChildren.length}
                                                   handleChildRemoval={props.handleChildRemoval}
                                                   handleObjectInsideListChange={props.handleObjectInsideListChange} {...child} />
                        )
                    })
                }

                <button type="button"
                        className="add-child--button"
                        id="reportedPersonBenefitPaymentForNotCaringChildren-add-child"
                        name="reportedPersonBenefitPaymentForNotCaringChildren"
                        disabled={props.reportedPersonBenefitPaymentForNotCaringChildren.length > 11}
                        onClick={props.handleNewChildAddition}>
                    Add another child
                    <AddIcon className="add-child--button-icon" />
                </button>

                <label htmlFor="reportedPersonBenefitPaymentForChildrenCommentBox" className="form-text-tell-us-what-u-think">Tell us what you think this person has done that means they have committed fraud, in the box below.</label>
                <span>This might include if the person:
                    <ul>
                        <li>is still receiving benefit payments for a child that is not in their care – if you know, tell us who the child is currently living with and if this is a temporary or permanent arrangement</li>
                        <li>has re-applied for benefit payments after the child has been removed from their care</li>
                        <li>has provided false information about their child responsibilities</li>
                    </ul>
                </span>
                <textarea name="reportedPersonBenefitPaymentForChildrenCommentBox"
                          id="reportedPersonBenefitPaymentForChildrenCommentBox"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForChildrenCommentBox}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForChildrenCommentBox'}
                          onChange={props.handleChange} />

            </div>
        </React.Fragment>
    )

}

export default Step13