import React from "react";

import CloseIcon from "@material-ui/icons/Close";

/**
 * Custom component for use in cases of children dynamic list (child benefits sections - step14.js)
 */
class BenefitNotCaringChild extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.id || '1',
            knowDetails: props.knowDetails || '',
            firstName: props.firstName || '',
            surname: props.surname || '',
            whenLeftPersonCare: props.whenLeftPersonCare || '',
            removedByLocalCouncil: props.removedByLocalCouncil || '',
            removedByLocalCouncilArea: props.removedByLocalCouncilArea || '',
            maxLengthForText: props.maxLengthForText || '',
            currentStep: props.currentStep || '',
            listName: props.listName || '',
            listLength: props.listLength || '',
            handleChildRemoval: props.handleChildRemoval,
            handleObjectInsideListChange: props.handleObjectInsideListChange,
            autoFocusYes: props.autoFocusYes === 'reportedPersonBenefitPaymentForNotCaringChildren.'.concat(props.id).concat('.knowDetailsYes'),
            autoFocusNo: props.autoFocusNo === 'reportedPersonBenefitPaymentForNotCaringChildren.'.concat(props.id).concat('.knowDetailsNo')
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.firstName !== prevState.firstName) {
            return {firstName: nextProps.firstName}
        } else if (nextProps.surname !== prevState.surname) {
            return {surname: nextProps.surname}
        } else if (nextProps.knowDetails !== prevState.knowDetails) {
            return {knowDetails: nextProps.knowDetails}
        } else if (nextProps.whenLeftPersonCare !== prevState.whenLeftPersonCare) {
            return {whenLeftPersonCare: nextProps.whenLeftPersonCare}
        } else if (nextProps.removedByLocalCouncil !== prevState.removedByLocalCouncil) {
            return {removedByLocalCouncil: nextProps.removedByLocalCouncil}
        } else if (nextProps.removedByLocalCouncilArea !== prevState.removedByLocalCouncilArea) {
            return {removedByLocalCouncilArea: nextProps.removedByLocalCouncilArea}
        } else if (nextProps.listLength !== prevState.listLength) {
            return {listLength: nextProps.listLength}
        } else {
            return null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.firstName !== this.state.firstName) {
            let first_ = this.state.firstName
            this.setState({
                firstName: first_
            })
        } else if (prevState.surname !== this.state.surname) {
            let last_ = this.state.surname
            this.setState({
                surname: last_
            })
        } else if (prevState.knowDetails !== this.state.knowDetails) {
            let knowDetails_ = this.state.knowDetails
            this.setState({
                knowDetails: knowDetails_
            })
        } else if (prevState.whenLeftPersonCare !== this.state.whenLeftPersonCare) {
            let whenLeftPersonCare_ = this.state.whenLeftPersonCare
            this.setState({
                whenLeftPersonCare: whenLeftPersonCare_
            })
        } else if (prevState.removedByLocalCouncil !== this.state.removedByLocalCouncil) {
            let removedByLocalCouncil_ = this.state.removedByLocalCouncil
            this.setState({
                removedByLocalCouncil: removedByLocalCouncil_
            })
        } else if (prevState.removedByLocalCouncilArea !== this.state.removedByLocalCouncilArea) {
            let removedByLocalCouncilArea_ = this.state.removedByLocalCouncilArea
            this.setState({
                removedByLocalCouncilArea: removedByLocalCouncilArea_
            })
        } else if (prevState.listLength !== this.state.listLength) {
            let divToFocus
            /*if (prevState.listLength > this.state.listLength) {
                divToFocus = "reportedPersonBenefitPaymentForNotCaringChildren-add-child"
            } else {
                divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".knowDetailsYes")
            }*/
            if (prevState.listLength > this.state.listLength) {
                if (this.state.knowDetails === 'Yes' || this.state.knowDetails === '') {
                    divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".knowDetailsYes")
                } else {
                    divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".knowDetailsNo")
                }
            } else {
                divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".knowDetailsYes")
            }
            document.getElementById(divToFocus).focus()
        }
    }

    render() {
        return (
            <React.Fragment>

                <div className="mobile-grid">
                    <h2 className="h2--child-repeater">Child {this.state.id}</h2>

                    <div hidden={this.state.listLength <= 1}>
                        <button type="button"
                                className="link--button remove-child--button"
                                name="reportedPersonBenefitPaymentForNotCaringChildrenRemove"
                                value={this.state.id}
                                onClick={this.state.handleChildRemoval}>
                            <CloseIcon className="remove-child--button-icon" /> remove child <span className="visually-hidden">{this.state.id}</span>
                        </button>
                    </div>
                </div>

                <fieldset aria-labelledby={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetails")} className="vertical-inputs">
                    <legend id={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetails")}>Do you know the name of the child?</legend>
                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetails")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetailsYes")}
                           value="Yes"
                           checked={this.state.knowDetails === 'Yes'}
                           autoFocus={this.state.autoFocusYes}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetailsYes")} className="form-radio">Yes</label>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetails")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetailsNo")}
                           value="No"
                           checked={this.state.knowDetails === 'No'}
                           autoFocus={this.state.autoFocusNo}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetailsNo")} className="form-radio">No</label>
                </fieldset>
                <br />

                <div hidden={this.state.knowDetails !== 'Yes'}>

                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")} className="form-text">First name</label>
                    <input type="text"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.firstName}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".surname")} className="form-text">Surname</label>
                    <input type="text"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".surname")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".surname")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.surname}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>

                </div>

                <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".whenLeftPersonCare")} className="form-text">When did the child/children leave the person's care?</label>
                <select name={this.state.listName.concat(".").concat(this.state.id).concat(".whenLeftPersonCare")}
                        id={this.state.listName.concat(".").concat(this.state.id).concat(".whenLeftPersonCare")}
                        value={this.state.whenLeftPersonCare}
                        onChange={this.state.handleObjectInsideListChange}>
                    <option value="Please select time period"
                            disabled={this.state.listName.concat(".").concat(this.state.id).concat(".whenLeftPersonCare") !== 'Please select time period'
                                        && this.state.listName.concat(".").concat(this.state.id).concat(".whenLeftPersonCare") !== ''}>
                        Please select time period
                    </option>
                    <option value="I don't know">I don't know</option>
                    <option value="Less than 4 weeks">Less than 4 weeks</option>
                    <option value="1-2 months">1-2 months</option>
                    <option value="2-3 months">2-3 months</option>
                    <option value="3-6 months">3-6 months</option>
                    <option value="6-12 months">6-12 months</option>
                    <option value="1-2 years">1-2 years</option>
                    <option value="2-3 years">2-3 years</option>
                    <option value="3-4 years">3-4 years</option>
                    <option value="4-5 years">4-5 years</option>
                    <option value="5+ years">5+ years</option>
                </select>
                <br/>

                <br/>
                <fieldset aria-labelledby={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncil")} className="vertical-inputs">
                    <legend id={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncil")}>Were they removed from the person's care by the local council?</legend>
                    <p className="hint">For example, Glasgow City Council</p>
                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncil")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncilYes")}
                           value="Yes"
                           checked={this.state.removedByLocalCouncil === 'Yes'}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncilYes")} className="form-radio">Yes</label>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncil")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncilNo")}
                           value="No"
                           checked={this.state.removedByLocalCouncil === 'No'}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncilNo")} className="form-radio">No</label>
                </fieldset>
                <br/>

                <div hidden={this.state.removedByLocalCouncil !== 'Yes'}>

                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncilArea")} className="form-text">Which council area did this take place in?</label>
                    <input type="text"
                           maxLength={this.state.maxLengthForText}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncilArea")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".removedByLocalCouncilArea")}
                           value={this.state.removedByLocalCouncilArea}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>

                </div>

                <hr className="repeater-component" />

            </React.Fragment>
        )
    }
}

export default BenefitNotCaringChild