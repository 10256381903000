import React from 'react';

import {isNumber, isValidDate, isValidHeight} from "../utils/Validators";
import {disableSelectPlaceholders} from "../utils/HelperUtils";

/**
 * Component representing 'Choose details' part of the fourth page (see 'Page four: Full Form' at https://65nfjh.axshare.com/)
 *      , it contains (required & optional) information about the suspected fraud reported person
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step7(props) {

    if (props.currentStep !== 7) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-7">

                <h1 id="suspectedPersonRequiredInformation">About the person claiming benefits or awards</h1>

                <p>Without the person's first name, surname and town where they live, we cannot look into any case. You also cannot progress with this report unless you tell us this information.</p>
                <p>If you're reporting someone who is receiving or claiming benefits for a child, please include their details here. You'll be able to provide information about the child later in the form.</p>

                <h2>The person you are reporting</h2>

                <div className={ props.errorClass.reportedPersonPersonalInfoFirstName === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <label htmlFor="reportedPersonPersonalInfo.firstName" className="form-text">First name (<span className="red">required</span>)</label>
                    { props.errorClass.reportedPersonPersonalInfoFirstName === 'error' ?
                        <span id="firstName-error" className="govuk-error-message">
                          <span className="govuk-visually-hidden">Error:</span> Enter your first name
                      </span>
                        : null }
                    <input type="text"
                           required={true}
                           id="reportedPersonPersonalInfo.firstName"
                           name="reportedPersonPersonalInfo.firstName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.firstName'}
                           value={props.reportedPersonPersonalInfo.firstName}
                           className={props.errorClass.reportedPersonPersonalInfoFirstName}
                           onChange={props.handleObjectChange} />
                </div>

                <div className={ props.errorClass.reportedPersonPersonalInfoSurname === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <label htmlFor="reportedPersonPersonalInfo.surname" className="form-text">Surname (<span className="red">required</span>)</label>
                    { props.errorClass.reportedPersonPersonalInfoSurname === 'error' ?
                        <span id="surname-error" className="govuk-error-message">
                          <span className="govuk-visually-hidden">Error:</span> Enter your surname
                      </span>
                        : null }
                    <input type="text"
                           required={true}
                           id="reportedPersonPersonalInfo.surname"
                           name="reportedPersonPersonalInfo.surname"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.surname'}
                           value={props.reportedPersonPersonalInfo.surname}
                           className={props.errorClass.reportedPersonPersonalInfoSurname}
                           onChange={props.handleObjectChange} />
                </div>

                <p></p>
                <fieldset aria-labelledby="reportedPersonPersonalInfo.hasAlternativeName" className="vertical-inputs">
                    <legend id="reportedPersonPersonalInfo.hasAlternativeName">Does the person go by any other name(s)?</legend>
                    <input type="radio"
                           name="reportedPersonPersonalInfo.hasAlternativeName"
                           id="reportedPersonPersonalInfo.hasAlternativeNameYes"
                           value="Yes"
                           checked={props.reportedPersonPersonalInfo.hasAlternativeName === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.hasAlternativeNameYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonPersonalInfo.hasAlternativeName"
                           id="reportedPersonPersonalInfo.hasAlternativeNameNo"
                           value="No"
                           checked={props.reportedPersonPersonalInfo.hasAlternativeName === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.hasAlternativeNameNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonPersonalInfo.hasAlternativeName"
                           id="reportedPersonPersonalInfo.hasAlternativeNameDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonPersonalInfo.hasAlternativeName === 'I don\'t know'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.hasAlternativeNameDoNotKnow'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.hasAlternativeNameDoNotKnow" className="form-radio">I don't know</label>
                </fieldset>

                <div hidden={props.reportedPersonPersonalInfo.hasAlternativeName !== 'Yes'}>
                    <br />
                    <p className="hint">If so, please add this/these in the box below</p>
                    <input type="text"
                           id="reportedPersonPersonalInfo.alternativeName"
                           name="reportedPersonPersonalInfo.alternativeName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonPersonalInfo.alternativeName}
                           aria-label="Person alternative name"
                           onChange={props.handleObjectChange} />
                </div>

                <h2 id="suspectedPersonRequiredInformationAddress">Address</h2>

                <div className="govuk-form-group">
                    <label htmlFor="reportedPersonPersonalInfo.addressHouseNumber" className="form-text">House number or Building name</label>
                    <input type="text"
                           id="reportedPersonPersonalInfo.addressHouseNumber"
                           name="reportedPersonPersonalInfo.addressHouseNumber"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.addressHouseNumber'}
                           value={props.reportedPersonPersonalInfo.addressHouseNumber}
                           onChange={props.handleObjectChange} />
                </div>

                <div className="govuk-form-group">
                    <label htmlFor="reportedPersonPersonalInfo.addressStreetName" className="form-text">Street name</label>
                    <input type="text"
                           id="reportedPersonPersonalInfo.addressStreetName"
                           name="reportedPersonPersonalInfo.addressStreetName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonPersonalInfo.addressStreetName}
                           onChange={props.handleObjectChange} />
                </div>

                <div className={ props.errorClass.reportedPersonPersonalInfoAddressCity === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <label htmlFor="reportedPersonPersonalInfo.addressCity" className="form-text">City or Town (<span className="red">required</span>)</label>
                    { props.errorClass.reportedPersonPersonalInfoAddressCity === 'error' ?
                        <span id="addressCity-error" className="govuk-error-message">
                          <span className="govuk-visually-hidden">Error:</span> Enter City or Town
                      </span>
                        : null }
                    <input type="text"
                           required={true}
                           id="reportedPersonPersonalInfo.addressCity"
                           name="reportedPersonPersonalInfo.addressCity"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonPersonalInfo.addressCity}
                           className={props.errorClass.reportedPersonPersonalInfoAddressCity}
                           onChange={props.handleObjectChange} />
                </div>

                <label htmlFor="reportedPersonPersonalInfo.addressPostcode" className="form-text">Postcode</label>
                <input type="text"
                       className="postcode"
                       id="reportedPersonPersonalInfo.addressPostcode"
                       name="reportedPersonPersonalInfo.addressPostcode"
                       autoComplete="off"
                       maxLength={props.maxLengthForText}
                       value={props.reportedPersonPersonalInfo.addressPostcode}
                       onChange={props.handleObjectChange} />
                <br/>

                <h2 id="suspectedPersonAdditionalInformation">Additional personal details about the person you are reporting</h2>

                <p>Only tell us the information you already know about the person. You do not need to find out anything further about them for this report.</p>
                <p>If you do not know any of the information asked, you can leave the box empty and move on to the next question.</p>

                <fieldset aria-labelledby="reportedPersonPersonalInfo.knowDateOfBirth" className="vertical-inputs">

                    <legend id="reportedPersonPersonalInfo.knowDateOfBirth">Do you know their date of birth?</legend>
                    <input type="radio"
                           name="reportedPersonPersonalInfo.knowDateOfBirth"
                           id="reportedPersonPersonalInfo.knowDateOfBirthYes"
                           value="Yes"
                           checked={props.reportedPersonPersonalInfo.knowDateOfBirth === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.knowDateOfBirthYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.knowDateOfBirthYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonPersonalInfo.knowDateOfBirth"
                           id="reportedPersonPersonalInfo.knowDateOfBirthNo"
                           value="No"
                           checked={props.reportedPersonPersonalInfo.knowDateOfBirth === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.knowDateOfBirthNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.knowDateOfBirthNo" className="form-radio">No</label>

                </fieldset>

                <div hidden={props.reportedPersonPersonalInfo.knowDateOfBirth !== 'Yes'}>

                    <br/>
                    <div className="form-text-after-yes-no">What is their date of birth?</div>
                    <p className="hint">For example, 31 03 1980</p>

                    <div className={ (props.errorClass.reportedPersonDateOfBirth === 'error'
                                        || props.errorClass.reportedPersonDateOfBirthDay === 'error'
                                        || props.errorClass.reportedPersonDateOfBirthMonth === 'error'
                                        || props.errorClass.reportedPersonDateOfBirthYear === 'error') ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>

                        {
                            (props.errorClass.reportedPersonDateOfBirth === 'error'
                                || props.errorClass.reportedPersonDateOfBirthDay === 'error'
                                || props.errorClass.reportedPersonDateOfBirthMonth === 'error'
                                || props.errorClass.reportedPersonDateOfBirthYear === 'error') ?
                                <span id="dateOfBirth-error" className="govuk-error-message">
                                        <span className="govuk-visually-hidden">Error:</span> Enter a valid Date of Birth
                                    </span>
                                : null
                        }

                        <div className="grid date">
                            <div className={(isValidDate(props.reportedPersonPersonalInfo.dateOfBirthDay, props.reportedPersonPersonalInfo.dateOfBirthMonth, props.reportedPersonPersonalInfo.dateOfBirthYear)
                                                && isNumber(props.reportedPersonPersonalInfo.dateOfBirthDay, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonPersonalInfo.dateOfBirthDay" className="form-date">Day</label>
                                <input type="number"
                                       className="twoDigit"
                                       min={1} max={31} step={1}
                                       name="reportedPersonPersonalInfo.dateOfBirthDay"
                                       id="reportedPersonPersonalInfo.dateOfBirthDay"
                                       value={props.reportedPersonPersonalInfo.dateOfBirthDay}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={(isValidDate(props.reportedPersonPersonalInfo.dateOfBirthDay, props.reportedPersonPersonalInfo.dateOfBirthMonth, props.reportedPersonPersonalInfo.dateOfBirthYear)
                                                && isNumber(props.reportedPersonPersonalInfo.dateOfBirthMonth, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonPersonalInfo.dateOfBirthMonth" className="form-date">Month</label>
                                <input type="number"
                                       className="twoDigit"
                                       min={1} max={12} step={1}
                                       name="reportedPersonPersonalInfo.dateOfBirthMonth"
                                       id="reportedPersonPersonalInfo.dateOfBirthMonth"
                                       value={props.reportedPersonPersonalInfo.dateOfBirthMonth}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={(isValidDate(props.reportedPersonPersonalInfo.dateOfBirthDay, props.reportedPersonPersonalInfo.dateOfBirthMonth, props.reportedPersonPersonalInfo.dateOfBirthYear)
                                                && isNumber(props.reportedPersonPersonalInfo.dateOfBirthYear, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonPersonalInfo.dateOfBirthYear" className="form-date">Year</label>
                                <input type="number"
                                       className="fourDigit"
                                       min={1900} max={9999} step={1}
                                       name="reportedPersonPersonalInfo.dateOfBirthYear"
                                       id="reportedPersonPersonalInfo.dateOfBirthYear"
                                       value={props.reportedPersonPersonalInfo.dateOfBirthYear}
                                       onChange={props.handleObjectChange} />
                            </div>
                        </div>
                    </div>

                </div>
                <div hidden={props.reportedPersonPersonalInfo.knowDateOfBirth !== 'No'}>

                    <br/>
                    <label htmlFor="reportedPersonPersonalInfo.ageRange" className="form-text">If you do not know their date of birth, please tell us the approximate age of the person.</label>
                    <select name="reportedPersonPersonalInfo.ageRange"
                            id="reportedPersonPersonalInfo.ageRange"
                            value={props.reportedPersonPersonalInfo.ageRange}
                            onChange={props.handleObjectChange}>
                        <option value="Please select age range"
                                disabled={disableSelectPlaceholders(props.currentStep, 'reportedPersonAgeRange', props.reportedPersonPersonalInfo.ageRange)}>
                            Please select age range
                        </option>
                        <option value="I don't know">I don't know</option>
                        <option value="16-24">16-24</option>
                        <option value="25-34">25-34</option>
                        <option value="35-44">35-44</option>
                        <option value="45-54">45-54</option>
                        <option value="55-64">55-64</option>
                        <option value="65+">65+</option>
                    </select>

                </div>

                <br/>

                <fieldset aria-labelledby="reportedPersonPersonalInfo.knowNationalInsuranceNumber" className="vertical-inputs">

                    <legend id="reportedPersonPersonalInfo.knowNationalInsuranceNumber">Do you know their National Insurance Number?</legend>
                    <input type="radio"
                           name="reportedPersonPersonalInfo.knowNationalInsuranceNumber"
                           id="reportedPersonPersonalInfo.knowNationalInsuranceNumberYes"
                           value="Yes"
                           checked={props.reportedPersonPersonalInfo.knowNationalInsuranceNumber === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.knowNationalInsuranceNumberYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.knowNationalInsuranceNumberYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonPersonalInfo.knowNationalInsuranceNumber"
                           id="reportedPersonPersonalInfo.knowNationalInsuranceNumberNo"
                           value="No"
                           checked={props.reportedPersonPersonalInfo.knowNationalInsuranceNumber === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.knowNationalInsuranceNumberNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.knowNationalInsuranceNumberNo" className="form-radio">No</label>

                </fieldset>

                <div hidden={props.reportedPersonPersonalInfo.knowNationalInsuranceNumber !== 'Yes'}>

                    <br/>
                    <div className="form-text-after-yes-no">Please enter their National Insurance number</div>
                    <p className="hint">For example, 'QQ 12 34 56 C'.</p>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonPersonalInfo.nationalInsuranceNumber"
                           id="reportedPersonPersonalInfo.nationalInsuranceNumber"
                           value={props.reportedPersonPersonalInfo.nationalInsuranceNumber}
                           aria-label="Person's National Insurance number"
                           onChange={props.handleObjectChange} />

                </div>

                <br/>

                <fieldset aria-labelledby="reportedPersonPersonalInfo.canProvidePhysicalDescription" className="vertical-inputs">

                    <legend id="reportedPersonPersonalInfo.canProvidePhysicalDescription">Can you provide a physical description?</legend>
                    <input type="radio"
                           name="reportedPersonPersonalInfo.canProvidePhysicalDescription"
                           id="reportedPersonPersonalInfo.canProvidePhysicalDescriptionYes"
                           value="Yes"
                           checked={props.reportedPersonPersonalInfo.canProvidePhysicalDescription === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.canProvidePhysicalDescriptionYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.canProvidePhysicalDescriptionYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonPersonalInfo.canProvidePhysicalDescription"
                           id="reportedPersonPersonalInfo.canProvidePhysicalDescriptionNo"
                           value="No"
                           checked={props.reportedPersonPersonalInfo.canProvidePhysicalDescription === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.canProvidePhysicalDescriptionNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.canProvidePhysicalDescriptionNo" className="form-radio">No</label>

                </fieldset>

                <div hidden={props.reportedPersonPersonalInfo.canProvidePhysicalDescription !== 'Yes'}>

                    <br/>

                    <div className="form-text-after-yes-no">Approximate height</div>

                    <div className={ (props.errorClass.reportedPersonPhysicalCharacteristics === 'error'
                                        || props.errorClass.reportedPersonPhysicalCharacteristicsFeet === 'error'
                                        || props.errorClass.reportedPersonPhysicalCharacteristicsInches === 'error') ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>

                        {
                            (props.errorClass.reportedPersonPhysicalCharacteristics === 'error'
                                || props.errorClass.reportedPersonPhysicalCharacteristicsFeet === 'error'
                                || props.errorClass.reportedPersonPhysicalCharacteristicsInches === 'error') ?
                                <span id="approximateHeight-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Enter a valid Approximate Height
                                </span>
                                : null
                        }

                        <div className="grid date">
                            <div className={(isValidHeight(props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, props.reportedPersonPersonalInfo.physicalCharacteristicsInches)
                                                && isNumber(props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, 'feet')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsFeet" className="form-text">Feet</label>
                                <input type="text"
                                       name="reportedPersonPersonalInfo.physicalCharacteristicsFeet"
                                       className="twoDigit"
                                       id="reportedPersonPersonalInfo.physicalCharacteristicsFeet"
                                       value={props.reportedPersonPersonalInfo.physicalCharacteristicsFeet}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={isValidHeight(props.reportedPersonPersonalInfo.physicalCharacteristicsFeet, props.reportedPersonPersonalInfo.physicalCharacteristicsInches)
                                                && isNumber(props.reportedPersonPersonalInfo.physicalCharacteristicsInches, 'inches')? '' : 'error'}>
                                <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsInches" className="form-text">Inches</label>
                                <input type="text"
                                       name="reportedPersonPersonalInfo.physicalCharacteristicsInches"
                                       className="twoDigit"
                                       id="reportedPersonPersonalInfo.physicalCharacteristicsInches"
                                       value={props.reportedPersonPersonalInfo.physicalCharacteristicsInches}
                                       onChange={props.handleObjectChange} />
                            </div>
                        </div>
                    </div>

                    <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsBuild" className="form-text">Build (Slim, stocky, muscular, etc)</label>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonPersonalInfo.physicalCharacteristicsBuild"
                           id="reportedPersonPersonalInfo.physicalCharacteristicsBuild"
                           value={props.reportedPersonPersonalInfo.physicalCharacteristicsBuild}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsHairColor" className="form-text">Hair colour (Brown, blonde, grey, etc)</label>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonPersonalInfo.physicalCharacteristicsHairColor"
                           id="reportedPersonPersonalInfo.physicalCharacteristicsHairColor"
                           value={props.reportedPersonPersonalInfo.physicalCharacteristicsHairColor}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="reportedPersonPersonalInfo.physicalCharacteristicsFeatures" className="form-text">Distinctive features (Tattoos, visible scars, glasses, etc)</label>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonPersonalInfo.physicalCharacteristicsFeatures"
                           id="reportedPersonPersonalInfo.physicalCharacteristicsFeatures"
                           value={props.reportedPersonPersonalInfo.physicalCharacteristicsFeatures}
                           onChange={props.handleObjectChange} />

                </div>

                <br/>

                <fieldset aria-labelledby="reportedPersonPersonalInfo.knowSocialMediaAccounts" className="vertical-inputs">

                    <legend id="reportedPersonPersonalInfo.knowSocialMediaAccounts">Do you know any of the person's social media accounts?</legend>
                    <input type="radio"
                           name="reportedPersonPersonalInfo.knowSocialMediaAccounts"
                           id="reportedPersonPersonalInfo.knowSocialMediaAccountsYes"
                           value="Yes"
                           checked={props.reportedPersonPersonalInfo.knowSocialMediaAccounts === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.knowSocialMediaAccountsYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.knowSocialMediaAccountsYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonPersonalInfo.knowSocialMediaAccounts"
                           id="reportedPersonPersonalInfo.knowSocialMediaAccountsNo"
                           value="No"
                           checked={props.reportedPersonPersonalInfo.knowSocialMediaAccounts === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonPersonalInfo.knowSocialMediaAccountsNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonPersonalInfo.knowSocialMediaAccountsNo" className="form-radio">No</label>

                </fieldset>

                <div hidden={props.reportedPersonPersonalInfo.knowSocialMediaAccounts !== 'Yes'}>

                    <br/>

                    <div className="govuk-form-group">
                        <label htmlFor="reportedPersonPersonalInfo.socialMediaFacebookName" className="form-text-after-yes-no">Facebook</label>
                        <p className="hint">For example, facebook.com/example</p>
                        <input type="text"
                               maxLength={props.maxLengthForText}
                               name="reportedPersonPersonalInfo.socialMediaFacebookName"
                               id="reportedPersonPersonalInfo.socialMediaFacebookName"
                               value={props.reportedPersonPersonalInfo.socialMediaFacebookName}
                               aria-label="Reported Person's Facebook Account"
                               onChange={props.handleObjectChange} />
                    </div>

                    <div className="govuk-form-group">
                        <label htmlFor="reportedPersonPersonalInfo.socialMediaInstagramName" className="form-text-after-yes-no">Instagram</label>
                        <p className="hint">For example, instagram.com/example</p>
                        <input type="text"
                               maxLength={props.maxLengthForText}
                               name="reportedPersonPersonalInfo.socialMediaInstagramName"
                               id="reportedPersonPersonalInfo.socialMediaInstagramName"
                               value={props.reportedPersonPersonalInfo.socialMediaInstagramName}
                               aria-label="Reported Person's Instagram Account"
                               onChange={props.handleObjectChange} />
                    </div>

                    <div className="govuk-form-group">
                        <label htmlFor="reportedPersonPersonalInfo.socialMediaTwitterName" className="form-text-after-yes-no">Twitter</label>
                        <p className="hint">For example, twitter.com/example</p>
                        <input type="text"
                               maxLength={props.maxLengthForText}
                               name="reportedPersonPersonalInfo.socialMediaTwitterName"
                               id="reportedPersonPersonalInfo.socialMediaTwitterName"
                               value={props.reportedPersonPersonalInfo.socialMediaTwitterName}
                               aria-label="Reported Person's Twitter Account"
                               onChange={props.handleObjectChange} />
                    </div>

                    <div className="govuk-form-group">
                        <label htmlFor="reportedPersonPersonalInfo.socialMediaSnapchatName" className="form-text-after-yes-no">Snapchat</label>
                        <p className="hint">For example, snapchat.com/example</p>
                        <input type="text"
                               maxLength={props.maxLengthForText}
                               name="reportedPersonPersonalInfo.socialMediaSnapchatName"
                               id="reportedPersonPersonalInfo.socialMediaSnapchatName"
                               value={props.reportedPersonPersonalInfo.socialMediaSnapchatName}
                               aria-label="Reported Person's Snapchat Account"
                               onChange={props.handleObjectChange} />
                    </div>

                    <div className="govuk-form-group">
                        <label htmlFor="reportedPersonPersonalInfo.socialMediaOtherName" className="form-text-after-yes-no">Other</label>
                        <p className="hint">For example, TikTok</p>
                        <input type="text"
                               maxLength={props.maxLengthForText}
                               name="reportedPersonPersonalInfo.socialMediaOtherName"
                               id="reportedPersonPersonalInfo.socialMediaOtherName"
                               value={props.reportedPersonPersonalInfo.socialMediaOtherName}
                               aria-label="Reported Person's Other Social Media Account"
                               onChange={props.handleObjectChange} />
                    </div>

                </div>

            </div>
        </React.Fragment>
    )
}

export default Step7;