import React from 'react';

import Collapsible from "../utils/Collapsible";
import CheckBox from "../utils/Checkbox";

/**
 * Component representing 'Benefit types' part of the fourth page (see 'Page four: Full Form' at https://65nfjh.axshare.com/)
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step4(props) {

    if (props.currentStep !== 4) {
        return null
    }

    /**
     * used to redirect to DWP homepage
     */
    /*const onDWPWebsiteClick = () => {
        window.open("https://www.gov.uk/report-benefit-fraud", "_self");
    }*/

    return (
        <React.Fragment>
            <div className="step step-4">
                <h1 id="suspectedBenefitFraudTypes">What types of suspected benefit fraud are you reporting?</h1>
                <div className={ props.errorClass.suspectedBenefitFraudTypes === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <p className="hint">Select all that apply</p>

                    {
                        props.errorClass.suspectedBenefitFraudTypes === 'error' ?
                            <span id="suspectedBenefitFraudTypes-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Select what types of benefit fraud you are reporting
                                </span>
                            : null
                    }

                    <ul className="checkBoxListNoBullets" role="group" aria-labelledby="suspectedBenefitFraudTypes">
                        {
                            props.suspectedBenefitFraudTypes.map((benefit, index) => {
                                return(
                                    <CheckBox key={index}
                                              className={props.errorClass.suspectedBenefitFraudTypes}
                                              handleChangeInMultiSelectCheckBox={props.handleChangeInMultiSelectCheckBox} {...benefit} />
                                )
                            })
                        }
                    </ul>
                    <Collapsible title="I can't find the type of benefit fraud I'm trying to report">
                        <p>If the type of benefit fraud you are looking for is not listed here, please report it to the Department for Work and Pensions.</p>
                        <p>You can report this in one of the following ways:</p>
                        <p><a target="_self" rel="noopener noreferrer" href="https://www.gov.uk/report-benefit-fraud">Online at DWP website</a></p>
                        {/*<button className="button--link" type="button" onClick={onDWPWebsiteClick}>Online at DWP website</button>*/}
                        <p><strong>By phone:</strong> Call free on <a href="tel:+440800854440">0800 854 440</a></p>
                        <p><strong>By textphone:</strong> <a href="tel:+4408003280512">0800 328 0512</a></p>
                        <p><strong>In writing to:</strong><br />
                           National Benefit Fraud Hotline<br />
                           Mail Handling Site A<br />
                           Wolverhampton<br />
                           WV98 2BP</p>
                    </Collapsible>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Step4;