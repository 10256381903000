import React from "react";
import ClubTeamSocialGroup from "../utils/ClubTeamSocialGroup";
import AddIcon from "@material-ui/icons/Add";
import CheckBox from "../utils/Checkbox";
import {multiSelectListAnyValueChecked} from "../utils/HelperUtils";

/**
 * Component showing up when 'Report a false or doubtful disability claim for an adult or a child' benefit type is selected in step4.js
 *                          in combination with 'child under 18 - No' selected in step6.js
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function Step9(props) {

    if (props.currentStep !== 9) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-9">

                <h1>Report a false or doubtful disability claim</h1>
                <div>
                    <p>This section applies to a person who is receiving adult disability benefits they're not entitled to.</p>
                    <p>Remember, not all disabilities are visible. Consider this before proceeding to complete the questions below.</p>
                    <p>If you do not know the answer, leave the questions blank and move on to the next section.</p>
                </div>

                <br/>
                <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor" className="form-text">What disability do you think the person is receiving benefits for?</label>
                <p className="hint">For example mobility disabilities, visual impairments, learning disabilities or mental health conditions. Please give us as many details as you can.</p>
                <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor"
                          id="reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.disabilityReceivingBenefitFor'}
                          onChange={props.handleObjectChange} />
                <br/>

                <h2>Home help or care information</h2>

                <br/>

                <fieldset aria-labelledby="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome" className="vertical-inputs">

                    <legend id="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome">Do they receive any help at home?</legend>
                    <p className="hint">For example from a carer or a family member.</p>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeYes"
                           value="Yes"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeNo"
                           value="No"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome === 'I don\'t know'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDoNotKnow'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDoNotKnow" className="form-radio">I don't know</label>

                </fieldset>

                <div hidden={props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome !== 'Yes'}>

                    <br />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails" className="form-text">Include details of the help they receive at home.</label>
                    <p className="hint">For example, number of days a week, type of help they get. Is the person providing help a friend or family member, or a professional carer?</p>
                    <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails"
                              id="reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails}
                              autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHomeDetails'}
                              onChange={props.handleObjectChange} />

                </div>

                <div hidden={props.reportedPersonBenefitPaymentForDisabilityAdult.receivingHelpAtHome === ''}>

                    <br/>
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves" className="form-text">Can they look after themselves?</label>
                    <p className="hint">For example, do their own basic housework or shopping by themselves, cook their own meals, manage their own finances.</p>
                    <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves"
                              id="reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves}
                              autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.canTheyLookAfterThemselves'}
                              onChange={props.handleObjectChange} />

                </div>

                <h2>Mobility information</h2>

                <br/>

                <fieldset aria-labelledby="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment" className="vertical-inputs">

                    <legend id="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment">Do they use any mobility equipment?</legend>
                    <p className="hint">For example wheelchair, walking stick, crutches, Motability cars.</p>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentYes"
                           value="Yes"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentNo"
                           value="No"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment === 'I don\'t know'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDoNotKnow'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDoNotKnow" className="form-radio">I don't know</label>

                </fieldset>

                <div hidden={props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipment !== 'Yes'}>

                    <br />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails" className="form-text">Details of mobility equipment.</label>
                    <p className="hint">For example the type of equipment they use and how often they use it. If it's a car, include model and plate number if possible.</p>
                    <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails"
                              id="reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails}
                              autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.useMobilityEquipmentDetails'}
                              onChange={props.handleObjectChange} />

                </div>

                <h2>About their work</h2>

                <br/>

                <fieldset aria-labelledby="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed" className="vertical-inputs">

                    <legend id="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed">Is the person employed?</legend>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedYes"
                           value="Yes"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedNo"
                           value="No"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed === 'I don\'t know'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedDoNotKnow'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployedDoNotKnow" className="form-radio">I don't know</label>

                </fieldset>

                <div hidden={props.reportedPersonBenefitPaymentForDisabilityAdult.isThePersonEmployed !== 'Yes'}>

                    <br/>
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo" className="form-text">What type of work do they do?</label>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo"
                           value={props.reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.whatTypeOfWorkTheyDo'}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed" className="form-text">How long have they been employed for?</label>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed"
                           value={props.reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.howLongTheyHaveBeenEmployed'}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer" className="form-text">Who is their employer? (if known)</label>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer"
                           value={props.reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.whoIsTheirEmployer'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <fieldset aria-labelledby="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability" className="vertical-inputs">

                        <legend id="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability">Does their employer know about their disability?</legend>

                        <input type="radio"
                               name="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability"
                               id="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityYes"
                               value="Yes"
                               checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability === 'Yes'}
                               autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityYes'}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityYes" className="form-radio">Yes</label>

                        <input type="radio"
                               name="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability"
                               id="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityNo"
                               value="No"
                               checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability === 'No'}
                               autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityNo'}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityNo" className="form-radio">No</label>

                        <input type="radio"
                               name="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability"
                               id="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityDoNotKnow"
                               value="I don't know"
                               checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability === 'I don\'t know'}
                               autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityDoNotKnow'}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisabilityDoNotKnow" className="form-radio">I don't know</label>

                    </fieldset>

                    <div hidden={props.reportedPersonBenefitPaymentForDisabilityAdult.doesTheEmployerKnowAboutTheirDisability !== 'Yes'}>

                        <br/>
                        <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability" className="form-text">Did the employer make any adjustments to support them with their health condition or disability?</label>
                        <p className="hint">For example installed accessible ramps, allowed flexible working hours, provided accessible working equipment.</p>
                        <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability"
                                  id="reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability"
                                  rows={10} cols={100}
                                  maxLength={props.maxLengthForTextArea}
                                  value={props.reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability}
                                  autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.didEmployerMakeAdjustmentsForDisability'}
                                  onChange={props.handleObjectChange} />

                    </div>

                    <br/>
                    <fieldset aria-labelledby="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome" className="vertical-inputs">

                        <legend id="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome">Do they work from home?</legend>

                        <input type="radio"
                               name="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome"
                               id="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeYes"
                               value="Yes"
                               checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome === 'Yes'}
                               autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeYes'}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeYes" className="form-radio">Yes</label>

                        <input type="radio"
                               name="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome"
                               id="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeNo"
                               value="No"
                               checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome === 'No'}
                               autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeNo'}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeNo" className="form-radio">No</label>

                        <input type="radio"
                               name="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome"
                               id="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeDoNotKnow"
                               value="I don't know"
                               checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome === 'I don\'t know'}
                               autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeDoNotKnow'}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHomeDoNotKnow" className="form-radio">I don't know</label>

                    </fieldset>

                    <div hidden={props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyWorkFromHome !== 'No'}>

                        <br/>
                        <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork" className="form-text">How do they travel to work?</label>
                        <p className="hint">For example, the bus route or number, picked up by a car or taxi, they travel by themselves or accompanied. How often and what time.</p>
                        <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork"
                                  id="reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork"
                                  rows={10} cols={100}
                                  maxLength={props.maxLengthForTextArea}
                                  value={props.reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork}
                                  autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.howDoTheyTravelToWork'}
                                  onChange={props.handleObjectChange} />

                    </div>

                </div>

                <h2>About their daily routine</h2>

                <br/>
                <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay" className="form-text">What do they do on a normal day?</label>
                <p className="hint">For example do they go out to meet with other people, walk their dog, go shopping or go to the gym. Please be as detailed as possible.</p>
                <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay"
                          id="reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNormalDay'}
                          onChange={props.handleObjectChange} />
                <br/>

                <br/>
                <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit" className="form-text">Thinking about the person's daily activities, what do they do to make you think they're not eligible for the benefit?</label>
                <p className="hint">For example, lift heavy bags, run or walk long distances, go out by themselves.</p>
                <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit"
                          id="reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.dailyRoutineNotEligibleForBenefit'}
                          onChange={props.handleObjectChange} />
                <br/>

                <br/>
                <fieldset aria-labelledby="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup" className="vertical-inputs">

                    <legend id="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup">Do they belong to any clubs, teams or social group?</legend>
                    <p className="hint">For example a gym, sports club or team evening classes, etc.</p>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupYes"
                           value="Yes"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupNo"
                           value="No"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup"
                           id="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup === 'I don\'t know'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupDoNotKnow'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroupDoNotKnow" className="form-radio">I don't know</label>

                </fieldset>

                <div hidden={props.reportedPersonBenefitPaymentForDisabilityAdult.doTheyBelongToAnyClubTeamSocialGroup !== 'Yes'}>

                    {
                        props.reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups.map((clubTeamSocialGroup, index) => {
                            return (
                                <ClubTeamSocialGroup key={index}
                                                     maxLengthForText={props.maxLengthForText}
                                                     maxLengthForTextArea={props.maxLengthForTextArea}
                                                     currentStep={props.currentStep}
                                                     listName="reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups"
                                                     listLength={props.reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups.length}
                                                     handleChildRemoval={props.handleChildRemoval}
                                                     handleObjectInsideListChange={props.handleObjectInsideListChange} {...clubTeamSocialGroup} />
                            )
                        })
                    }

                    <button type="button"
                            className="add-child--button"
                            id="reportedPersonBenefitPaymentForDisabilityAdultClubsTeamsSocialGroups-add-child"
                            name="reportedPersonBenefitPaymentForDisabilityAdultClubsTeamsSocialGroups"
                            disabled={props.reportedPersonBenefitPaymentForDisabilityAdult.clubsTeamsSocialGroups.length > 11}
                            onClick={props.handleNewChildAddition}>
                        Add another club, team or social group
                        <AddIcon className="add-child--button-icon" />
                    </button>

                </div>

                <h2>Transportation</h2>

                <br/>

                <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.transportationForms" className="form-text">What's their main form of transportation?</label>
                <p className="hint">Select all that apply</p>

                <ul className="checkBoxListNoBullets" role="group" aria-labelledby="reportedPersonBenefitPaymentForDisabilityAdult.transportationForms">
                    {
                        props.reportedPersonBenefitPaymentForDisabilityAdult.transportationForms.map((transportationForm, index) => {
                            return(
                                <CheckBox key={index}
                                          autoFocus={props.focusElementId}
                                          handleChangeInMultiSelectCheckBox={props.handleChangeInMultiSelectCheckBox} {...transportationForm} />
                            )
                        })
                    }
                </ul>

                <div hidden={!multiSelectListAnyValueChecked(props.reportedPersonBenefitPaymentForDisabilityAdult.transportationForms)}>

                    <br/>
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation" className="form-text">Details of their transportation</label>
                    <p className="hint">For example: car make and model, bus route or number, how far they walk.</p>
                    <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation"
                              id="reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation}
                              autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.detailsOfTransportation'}
                              onChange={props.handleObjectChange} />

                </div>

                <h2>Further information</h2>

                <br/>
                <label htmlFor="reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation" className="form-text">Is there anything else you think we should know about this?</label>
                <textarea name="reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation"
                          id="reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityAdult.furtherInformation'}
                          onChange={props.handleObjectChange} />
                <br/>

            </div>
        </React.Fragment>
    )
}

export default Step9;