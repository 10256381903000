import React from 'react';

import {isNumber, isValidDate} from "../utils/Validators";
import {disableSelectPlaceholders} from "../utils/HelperUtils";

/**
 * Component representing 'Fraudulent identity' part of the fourth page (see 'Page four: Full Form' at https://10t3ux.axshare.com/)
 *      , it shows up when 'Using a fraudulent identity when applying for benefits or payments' benefit type is selected in step4.js
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step19(props) {

    if (props.currentStep !== 19) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-19">

                <h1>Using a different identity or documents to get benefits</h1>
                <div>
                    <p>The person has applied for or gets benefits using:</p>
                    <ul>
                        <li>documents that are not their own</li>
                        <li>false documents</li>
                        <li>someone else’s name and other personal information</li>
                    </ul>
                    <p>If you do not know the answer to a question, leave it and move on to the next question.</p>
                </div>

                <br/>

                <fieldset aria-labelledby="reportedPersonFraudulentIdentity.knowDetails" className="vertical-inputs">
                    <legend id="reportedPersonFraudulentIdentity.knowDetails">Do you know the name the person is using with this identity?</legend>
                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.knowDetails"
                           id="reportedPersonFraudulentIdentity.knowDetailsYes"
                           value="Yes"
                           checked={props.reportedPersonFraudulentIdentity.knowDetails === 'Yes'}
                           autoFocus={props.focusElementId === "reportedPersonFraudulentIdentity.knowDetailsYes"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.knowDetailsYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.knowDetails"
                           id="reportedPersonFraudulentIdentity.knowDetailsNo"
                           value="No"
                           checked={props.reportedPersonFraudulentIdentity.knowDetails === 'No'}
                           autoFocus={props.focusElementId === "reportedPersonFraudulentIdentity.knowDetailsNo"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.knowDetailsNo" className="form-radio">No</label>
                </fieldset>

                <div hidden={props.reportedPersonFraudulentIdentity.knowDetails !== 'Yes'}>
                    <br/>
                    <label htmlFor="reportedPersonFraudulentIdentity.firstName" className="form-text">First name</label>
                    <input type="text"
                           id="reportedPersonFraudulentIdentity.firstName"
                           name="reportedPersonFraudulentIdentity.firstName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonFraudulentIdentity.firstName}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.firstName'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonFraudulentIdentity.surname" className="form-text">Surname</label>
                    <input type="text"
                           id="reportedPersonFraudulentIdentity.surname"
                           name="reportedPersonFraudulentIdentity.surname"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonFraudulentIdentity.surname}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.surname'}
                           onChange={props.handleObjectChange} />
                    <br/>
                </div>

                <br/>
                <fieldset aria-labelledby="reportedPersonFraudulentIdentity.knowDateOfBirth" className="vertical-inputs">
                    <legend id="reportedPersonFraudulentIdentity.knowDateOfBirth">Do you know the date of birth the person is using with this identity?</legend>
                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.knowDateOfBirth"
                           id="reportedPersonFraudulentIdentity.knowDateOfBirthYes"
                           value="Yes"
                           checked={props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.knowDateOfBirthYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.knowDateOfBirthYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.knowDateOfBirth"
                           id="reportedPersonFraudulentIdentity.knowDateOfBirthNo"
                           value="No"
                           checked={props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.knowDateOfBirthNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.knowDateOfBirthNo" className="form-radio">No</label>
                </fieldset>

                <div hidden={props.reportedPersonFraudulentIdentity.knowDateOfBirth !== 'Yes'}>

                    <br/>
                    <div className="questions-with-hint">What is their date of birth?</div>
                    <p className="hint">For example, 31 03 1980</p>

                    <div className={ (props.errorClass.reportedPersonFraudulentIdentityDateOfBirth === 'error'
                        || props.errorClass.reportedPersonFraudulentIdentityDateOfBirthDay === 'error'
                        || props.errorClass.reportedPersonFraudulentIdentityDateOfBirthMonth === 'error'
                        || props.errorClass.reportedPersonFraudulentIdentityDateOfBirthYear === 'error') ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>

                        {
                            (props.errorClass.reportedPersonFraudulentIdentityDateOfBirth === 'error'
                                || props.errorClass.reportedPersonFraudulentIdentityDateOfBirthDay === 'error'
                                || props.errorClass.reportedPersonFraudulentIdentityDateOfBirthMonth === 'error'
                                || props.errorClass.reportedPersonFraudulentIdentityDateOfBirthYear === 'error') ?
                                <span id="reportedPersonFraudulentIdentity-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Enter a valid Date of Birth
                                </span>
                                : null
                        }

                        <div className="grid date">
                            <div className={(isValidDate(props.reportedPersonFraudulentIdentity.dateOfBirthDay, props.reportedPersonFraudulentIdentity.dateOfBirthMonth, props.reportedPersonFraudulentIdentity.dateOfBirthYear)
                                && isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthDay, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFraudulentIdentity.dateOfBirthDay" className="form-date">Day</label>
                                <input type="number"
                                       className="twoDigit"
                                       min={1} max={31} step={1}
                                       name="reportedPersonFraudulentIdentity.dateOfBirthDay"
                                       id="reportedPersonFraudulentIdentity.dateOfBirthDay"
                                       value={props.reportedPersonFraudulentIdentity.dateOfBirthDay}
                                       autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.dateOfBirthDay'}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={(isValidDate(props.reportedPersonFraudulentIdentity.dateOfBirthDay, props.reportedPersonFraudulentIdentity.dateOfBirthMonth, props.reportedPersonFraudulentIdentity.dateOfBirthYear)
                                && isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthMonth, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFraudulentIdentity.dateOfBirthMonth" className="form-date">Month</label>
                                <input type="number"
                                       className="twoDigit"
                                       min={1} max={12} step={1}
                                       name="reportedPersonFraudulentIdentity.dateOfBirthMonth"
                                       id="reportedPersonFraudulentIdentity.dateOfBirthMonth"
                                       value={props.reportedPersonFraudulentIdentity.dateOfBirthMonth}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={(isValidDate(props.reportedPersonFraudulentIdentity.dateOfBirthDay, props.reportedPersonFraudulentIdentity.dateOfBirthMonth, props.reportedPersonFraudulentIdentity.dateOfBirthYear)
                                && isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthYear, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFraudulentIdentity.dateOfBirthYear" className="form-date">Year</label>
                                <input type="number"
                                       className="fourDigit"
                                       min={1900} max={9999} step={1}
                                       name="reportedPersonFraudulentIdentity.dateOfBirthYear"
                                       id="reportedPersonFraudulentIdentity.dateOfBirthYear"
                                       value={props.reportedPersonFraudulentIdentity.dateOfBirthYear}
                                       onChange={props.handleObjectChange} />
                            </div>
                        </div>
                    </div>

                </div>

                <div hidden={props.reportedPersonFraudulentIdentity.knowDateOfBirth !== 'No'}>

                    <br/>
                    <label htmlFor="reportedPersonFraudulentIdentity.approximateAge" className="form-text">Do you know the approximate age the person is using?</label>
                    <select name="reportedPersonFraudulentIdentity.approximateAge"
                            id="reportedPersonFraudulentIdentity.approximateAge"
                            value={props.reportedPersonFraudulentIdentity.approximateAge}
                            autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.approximateAge'}
                            onChange={props.handleObjectChange}>
                        <option value="Please select age range"
                                disabled={disableSelectPlaceholders(props.currentStep, 'fraudulentIdentityAgeRange', props.reportedPersonFraudulentIdentity.approximateAge)}>
                            Please select age range
                        </option>
                        <option value="I don't know">I don't know</option>
                        <option value="16-24">16-24</option>
                        <option value="25-34">25-34</option>
                        <option value="35-44">35-44</option>
                        <option value="45-54">45-54</option>
                        <option value="55-64">55-64</option>
                        <option value="65+">65+</option>
                    </select>

                </div>

                <br/>
                <fieldset aria-labelledby="reportedPersonFraudulentIdentity.knowAddress" className="vertical-inputs">
                    <legend id="reportedPersonFraudulentIdentity.knowAddress">Do you know the address or previous addresses the person is using with this identity?</legend>
                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.knowAddress"
                           id="reportedPersonFraudulentIdentity.knowAddressYes"
                           value="Yes"
                           checked={props.reportedPersonFraudulentIdentity.knowAddress === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.knowAddressYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.knowAddressYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.knowAddress"
                           id="reportedPersonFraudulentIdentity.knowAddressNo"
                           value="No"
                           checked={props.reportedPersonFraudulentIdentity.knowAddress === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.knowAddressNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.knowAddressNo" className="form-radio">No</label>
                </fieldset>

                <div hidden={props.reportedPersonFraudulentIdentity.knowAddress !== 'Yes'}>

                    <br/>
                    <label htmlFor="reportedPersonFraudulentIdentity.addressHouseNumber" className="form-text">House number or Building name</label>
                    <input type="text"
                           id="reportedPersonFraudulentIdentity.addressHouseNumber"
                           name="reportedPersonFraudulentIdentity.addressHouseNumber"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonFraudulentIdentity.addressHouseNumber}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.addressHouseNumber'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonFraudulentIdentity.addressStreetName" className="form-text">Street name</label>
                    <input type="text"
                           id="reportedPersonFraudulentIdentity.addressStreetName"
                           name="reportedPersonFraudulentIdentity.addressStreetName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonFraudulentIdentity.addressStreetName}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.addressStreetName'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonFraudulentIdentity.addressCity" className="form-text">City or Town</label>
                    <input type="text"
                           id="reportedPersonFraudulentIdentity.addressCity"
                           name="reportedPersonFraudulentIdentity.addressCity"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonFraudulentIdentity.addressCity}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.addressCity'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonFraudulentIdentity.addressPostcode" className="form-text">Postcode</label>
                    <input type="text"
                           className="postcode"
                           id="reportedPersonFraudulentIdentity.addressPostcode"
                           name="reportedPersonFraudulentIdentity.addressPostcode"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonFraudulentIdentity.addressPostcode}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.addressPostcode'}
                           onChange={props.handleObjectChange} />
                    <br/>
                </div>

                <br/>
                <fieldset aria-labelledby="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments" className="vertical-inputs">
                    <legend id="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments">Has the person used fake or false documents with this identity?</legend>
                    <p className="hint">E.g. passport, driving license, etc.</p>
                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments"
                           id="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsYes"
                           value="Yes"
                           checked={props.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments"
                           id="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsNo"
                           value="No"
                           checked={props.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments"
                           id="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments === 'I don\'t know'}
                           autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsDoNotKnow'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocumentsDoNotKnow" className="form-radio">I don't know</label>
                </fieldset>

                <div hidden={props.reportedPersonFraudulentIdentity.hasUsedFakeOrFalseDocuments !== 'Yes'}>

                    <br/>
                    <label htmlFor="reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox" className="form-text">If yes, use the box below to provide more details.</label>
                    <textarea name="reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox"
                              id="reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox}
                              autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.fakeOrFalseDocumentsCommentBox'}
                              onChange={props.handleObjectChange} />

                </div>

                <br/>
                <label htmlFor="reportedPersonFraudulentIdentity.commentBox" className="form-text">Are there any more details you want to add about the person using this identity? If yes, add these to the box below.</label>
                <textarea name="reportedPersonFraudulentIdentity.commentBox"
                          id="reportedPersonFraudulentIdentity.commentBox"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonFraudulentIdentity.commentBox}
                          autoFocus={props.focusElementId === 'reportedPersonFraudulentIdentity.commentBox'}
                          onChange={props.handleObjectChange} />

            </div>
        </React.Fragment>
    )
}

export default Step19;