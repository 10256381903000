import React from 'react';

/**
 * Component representing third page (see 'Page three: Country Check' at https://65nfjh.axshare.com/)
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step3(props) {

    if (props.currentStep !== 3) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-3">
                <h1>Receiving benefits in Scotland</h1>
                <div className={ props.errorClass.personReceivingOrReceivedBenefit === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <fieldset aria-labelledby="personReceivingOrReceivedBenefit" className="vertical-inputs">
                        <legend id="personReceivingOrReceivedBenefit">Does the person in the report currently get or have they received benefits in Scotland?</legend>

                        {
                            props.errorClass.personReceivingOrReceivedBenefit === 'error' ?
                                <span id="personReceivingOrReceivedBenefit-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Select yes if the person gets or has received benefits in Scotland
                                </span>
                                : null
                        }

                        <input type="radio"
                               name="personReceivingOrReceivedBenefit"
                               id="personReceivingOrReceivedBenefitYes"
                               value="Yes"
                               checked={props.personReceivingOrReceivedBenefit === 'Yes'}
                               className={props.errorClass.personReceivingOrReceivedBenefit}
                               onChange={props.handleChange} />
                        <label htmlFor="personReceivingOrReceivedBenefitYes" className="form-radio">Yes</label>
                        <input type="radio"
                               name="personReceivingOrReceivedBenefit"
                               id="personReceivingOrReceivedBenefitNo"
                               value="No"
                               checked={props.personReceivingOrReceivedBenefit === 'No'}
                               className={props.errorClass.personReceivingOrReceivedBenefit}
                               onChange={props.handleChange} />
                        <label htmlFor="personReceivingOrReceivedBenefitNo" className="form-radio">No</label>
                        <input type="radio"
                               name="personReceivingOrReceivedBenefit"
                               id="personReceivingOrReceivedBenefitDoNotKnow"
                               value="I don't know"
                               checked={props.personReceivingOrReceivedBenefit === 'I don\'t know'}
                               className={props.errorClass.personReceivingOrReceivedBenefit}
                               onChange={props.handleChange} />
                        <label htmlFor="personReceivingOrReceivedBenefitDoNotKnow" className="form-radio">I don't know</label>
                    </fieldset>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Step3;