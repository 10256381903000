import React from "react";

/**
 * Component representing 'Page six: Fraud report has been sent' (see https://10t3ux.axshare.com/)
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step23(props) {

    if (props.currentStep !== 23) {
        return null
    }

    /**
     * used to redirect to myGov homepage
     */
    const onClick = () => {
        window.open("https://www.mygov.scot/", "_self");
    }

    return (
        <React.Fragment>
            <div>
                <h1>Report sent to Social Security Scotland</h1>
                <h2>Thank you for sending this information to Social Security Scotland.</h2>
                <h3>What happens next?</h3>
                <p>Our team will review the information. They'll then decide if there is a case for further investigation of the report.</p>
                <p>We will not tell you the outcome of this as all reporting is anonymous. This means we will not provide further details about the case.</p>
                <p>You do not need to do anything else.</p>
                <button type="button" onClick={onClick}>Return to MyGov homepage</button>
            </div>
        </React.Fragment>
    )
}

export default Step23;