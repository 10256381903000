import React from "react";

import AddIcon from '@material-ui/icons/Add';

import BenefitDisabilityChild from "../utils/BenefitDisabilityChild";

/**
 * Component showing up when 'Report a false or doubtful disability claim for an adult or a child' benefit type is selected in step4.js
 *                          in combination with 'child under 18 - Yes' selected in step6.js
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step10(props) {

    if (props.currentStep !== 10) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-10">

                <h1>Report a false or doubtful disability relating to a child</h1>
                <div>
                    <p>This section applies to a person who is receiving child disability benefits they’re not entitled to.</p>
                    <p>Remember, not all disabilities are visible. Consider this before proceeding to complete the questions below.</p>
                    <p>If you do not know this information, leave the questions blank and move on to the next section.</p>
                </div>

                {
                    props.reportedPersonBenefitPaymentForDisabilityChildren.map((child, index) => {
                        return (
                            <BenefitDisabilityChild key={index}
                                                    autoFocus={props.focusElementId}
                                                    maxLengthForText={props.maxLengthForText}
                                                    currentStep={props.currentStep}
                                                    listName="reportedPersonBenefitPaymentForDisabilityChildren"
                                                    listLength={props.reportedPersonBenefitPaymentForDisabilityChildren.length}
                                                    handleChildRemoval={props.handleChildRemoval}
                                                    handleObjectInsideListChange={props.handleObjectInsideListChange} {...child} />
                        )
                    })
                }

                <button type="button"
                        className="add-child--button"
                        id="reportedPersonBenefitPaymentForDisabilityChildren-add-child"
                        name="reportedPersonBenefitPaymentForDisabilityChildren"
                        disabled={props.reportedPersonBenefitPaymentForDisabilityChildren.length > 11}
                        onClick={props.handleNewChildAddition}>
                    Add another child
                    <AddIcon className="add-child--button-icon" />
                </button>

                <br/>

                <h2>Reasons for doubting eligibility for disability benefits</h2>

                <br/>
                <label htmlFor="reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor" className="form-text">What disability do you think the child/children is receiving benefit(s) for?</label>
                <p className="hint">For example, mobility disabilities, visual impairments, or learning disabilities.</p>
                <textarea name="reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor"
                          id="reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityInformation.disabilityReceivingBenefitFor'}
                          onChange={props.handleObjectChange} />
                <br/>

                <br/>
                <label htmlFor="reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason" className="form-text">What makes you doubt their eligibility for the disability benefit?</label>
                <p className="hint">For example, reasons you may have for believing the child does not have a disability, or the disability the person is claiming. Include details about any activities they might be involved in, when and where these take place.</p>
                <textarea name="reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason"
                          id="reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityInformation.eligibilityDoubtingReason'}
                          onChange={props.handleObjectChange} />
                <br/>

                <br/>
                <label htmlFor="reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration" className="form-text">How long do you suspect this benefit fraud has been going for?</label>
                <input type="text"
                       maxLength={props.maxLengthForText}
                       name="reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration"
                       id="reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration"
                       value={props.reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration}
                       autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityInformation.benefitFraudDuration'}
                       onChange={props.handleObjectChange} />

                <br/>

                <h2>Other information</h2>

                <br/>

                <fieldset aria-labelledby="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles" className="vertical-inputs">

                    <legend id="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles">Do you know if they use any mobility vehicles?</legend>
                    <p className="hint">For example a standard vehicle, Motability vehicle or a car with wheelchair accessibility.</p>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles"
                           id="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesYes"
                           value="Yes"
                           checked={props.reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles"
                           id="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesNo"
                           value="No"
                           checked={props.reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles"
                           id="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles === 'I don\'t know'}
                           autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesDoNotKnow'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehiclesDoNotKnow" className="form-radio">I don't know</label>

                </fieldset>

                <div hidden={props.reportedPersonBenefitPaymentForDisabilityInformation.usingMobilityVehicles !== 'Yes'}>

                    <br/>
                    <label htmlFor="reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails" className="form-text">Vehicle details</label>
                    <p className="hint">For example, car make and model, and registration number.</p>
                    <textarea name="reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails"
                              id="reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails}
                              autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityInformation.mobilityVehicleDetails'}
                              onChange={props.handleObjectChange} />
                    <br/>

                </div>

                <br/>
                <label htmlFor="reportedPersonBenefitPaymentForDisabilityInformation.otherInformation" className="form-text">Other information that you think we should know about the case</label>
                <p className="hint">For example, locations visited and other people involved.</p>
                <textarea name="reportedPersonBenefitPaymentForDisabilityInformation.otherInformation"
                          id="reportedPersonBenefitPaymentForDisabilityInformation.otherInformation"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonBenefitPaymentForDisabilityInformation.otherInformation}
                          autoFocus={props.focusElementId === 'reportedPersonBenefitPaymentForDisabilityInformation.otherInformation'}
                          onChange={props.handleObjectChange} />

            </div>
        </React.Fragment>
    )
}

export default Step10;