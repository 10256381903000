import React from "react";

import CloseIcon from '@material-ui/icons/Close';

/**
 * Custom component for use in cases of Clubs, Teams, Social Clubs dynamic list (disability adult benefit section - step10.js)
 */
class ClubTeamSocialGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.id || '1',
            name: props.name || '',
            location: props.location || '',
            howOftenTheyAttend: props.howOftenTheyAttend || '',
            maxLengthForText: props.maxLengthForText || '',
            maxLengthForTextArea: props.maxLengthForTextArea || '',
            listName: props.listName || '',
            listLength: props.listLength || '',
            handleChildRemoval: props.handleChildRemoval,
            handleObjectInsideListChange: props.handleObjectInsideListChange
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.name !== prevState.name) {
            return {name: nextProps.name}
        } else if (nextProps.location !== prevState.location) {
            return {location: nextProps.location}
        } else if (nextProps.howOftenTheyAttend !== prevState.howOftenTheyAttend) {
            return {howOftenTheyAttend: nextProps.howOftenTheyAttend}
        } else if (nextProps.listLength !== prevState.listLength) {
            return {listLength: nextProps.listLength}
        } else {
            return null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.name !== this.state.name) {
            let name_ = this.state.name
            this.setState({
                name: name_
            })
        } else if (prevState.location !== this.state.location) {
            let location_ = this.state.location
            this.setState({
                location: location_
            })
        } else if (prevState.howOftenTheyAttend !== this.state.howOftenTheyAttend) {
            let howOftenTheyAttend_ = this.state.howOftenTheyAttend
            this.setState({
                howOftenTheyAttend: howOftenTheyAttend_
            })
        } else if (prevState.listLength !== this.state.listLength) {
            let divToFocus
            if (prevState.listLength > this.state.listLength) {
                divToFocus = "reportedPersonBenefitPaymentForDisabilityAdultClubsTeamsSocialGroups-add-child"
            } else {
                divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".name")
            }

            document.getElementById(divToFocus).focus()
        }
    }

    render() {
        return (
            <React.Fragment>

                <div className="mobile-grid">

                    <h2 className="h2--child-repeater">Club, team or social group {this.state.id}</h2>

                    <div hidden={this.state.listLength <= 1}>
                        <button type="button"
                                className="link--button remove-child--button"
                                name="reportedPersonBenefitPaymentForDisabilityAdultClubsTeamsSocialGroupsRemove"
                                value={this.state.id}
                                onClick={this.state.handleChildRemoval}>
                            <CloseIcon className="remove-child--button-icon" />remove club, team or social group <span className="visually-hidden">{this.state.id}</span>
                        </button>
                    </div>
                </div>

                <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".name")} className="form-text">Name</label>
                <input type="text"
                       maxLength={this.state.maxLengthForText}
                       name={this.state.listName.concat(".").concat(this.state.id).concat(".name")}
                       id={this.state.listName.concat(".").concat(this.state.id).concat(".name")}
                       value={this.state.name}
                       onChange={this.state.handleObjectInsideListChange} />
                <br/>

                <br/>
                <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".location")} className="form-text">Location</label>
                <input type="text"
                       maxLength={this.state.maxLengthForText}
                       name={this.state.listName.concat(".").concat(this.state.id).concat(".location")}
                       id={this.state.listName.concat(".").concat(this.state.id).concat(".location")}
                       value={this.state.location}
                       onChange={this.state.handleObjectInsideListChange} />
                <br/>

                <br/>
                <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".howOftenTheyAttend")} className="form-text">How often do they attend?</label>
                <textarea name={this.state.listName.concat(".").concat(this.state.id).concat(".howOftenTheyAttend")}
                          id={this.state.listName.concat(".").concat(this.state.id).concat(".howOftenTheyAttend")}
                          rows={10} cols={100}
                          maxLength={this.state.maxLengthForTextArea}
                          value={this.state.howOftenTheyAttend}
                          onChange={this.state.handleObjectInsideListChange} />
                <br/>

                <hr className="repeater-component" />

            </React.Fragment>
        );
    }
}

export default ClubTeamSocialGroup