import React from 'react';

/**
 * Component representing 'Child or Pregnancy benefit' part of the fourth page (see 'Page four: Full Form' at https://10t3ux.axshare.com/)
 *      , it shows up when 'Payments relating to child responsibility or pregnancy' benefit type is selected in step4.js
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step11(props) {

    if (props.currentStep !== 11) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-11">
                <h1>Benefit payments for a child or pregnancy</h1>
                <div className={ props.errorClass.reportedPersonBenefitForChildOrPregnancy === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>

                    <fieldset aria-labelledby="reportedPersonBenefitPaymentForChildOrPregnancy" className="vertical-inputs">
                        <legend id="reportedPersonBenefitPaymentForChildOrPregnancy">Is this report about a benefit application or child payments for one of the following:</legend>

                        {
                            props.errorClass.reportedPersonBenefitForChildOrPregnancy === 'error' ?
                                <span id="reportedPersonBenefitForChildOrPregnancy-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Select what type of child or pregnancy fraud you want to report
                                </span>
                                : null
                        }

                        <p className="hint">Select the option that applies</p>
                        <input type="radio"
                               name="reportedPersonBenefitForChildOrPregnancy"
                               id="reportedPersonBenefitForChildOrPregnancy.currentlyCaring"
                               value="A person currently caring for a child or children"
                               checked={props.reportedPersonBenefitForChildOrPregnancy === 'A person currently caring for a child or children'}
                               onChange={props.handleChange} />
                        <label htmlFor="reportedPersonBenefitForChildOrPregnancy.currentlyCaring" className="form-radio">A person currently caring for a child or children</label>

                        <input type="radio"
                               name="reportedPersonBenefitForChildOrPregnancy"
                               id="reportedPersonBenefitForChildOrPregnancy.noLongerCaring"
                               value="A person who no longer cares for a child or children"
                               checked={props.reportedPersonBenefitForChildOrPregnancy === 'A person who no longer cares for a child or children'}
                               onChange={props.handleChange} />
                        <label htmlFor="reportedPersonBenefitForChildOrPregnancy.noLongerCaring" className="form-radio">A person who no longer cares for a child or children</label>

                        <input type="radio"
                               name="reportedPersonBenefitForChildOrPregnancy"
                               id="reportedPersonBenefitForChildOrPregnancy.pregnant"
                               value="A person who says they are pregnant but they’re not, in order to apply for benefit payments"
                               checked={props.reportedPersonBenefitForChildOrPregnancy === 'A person who says they are pregnant but they’re not, in order to apply for benefit payments'}
                               onChange={props.handleChange} />
                        <label htmlFor="reportedPersonBenefitForChildOrPregnancy.pregnant" className="form-radio">A person who says they are pregnant but they’re not, in order to apply for benefit payments</label>

                        <input type="radio"
                               name="reportedPersonBenefitForChildOrPregnancy"
                               id="reportedPersonBenefitForChildOrPregnancy.other"
                               value="Other"
                               checked={props.reportedPersonBenefitForChildOrPregnancy === 'Other'}
                               onChange={props.handleChange} />
                        <label htmlFor="reportedPersonBenefitForChildOrPregnancy.other" className="form-radio">Other</label>

                    </fieldset>

                </div>

            </div>
        </React.Fragment>
    )
}

export default Step11;