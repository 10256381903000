import React from 'react';

/**
 * Component representing 'Caring benefit' part of the fourth page (see 'Page four: Full Form' at https://10t3ux.axshare.com/)
 *      , it shows up when 'Payments or benefits received for providing care' benefit type is selected in step4.js
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step16(props) {

    if (props.currentStep !== 16) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-16">

                <h1>Not providing care for a person</h1>
                <div>
                    <p>This applies to a person from 16 to 18 years old who receives or has received payments for one of the following:</p>
                    <ul>
                        <li>caring for someone but they do not provide this care</li>
                        <li>providing care for less than the minimum of 16 hours per week</li>
                    </ul>
                    <p>This also applies to a person who receives or has received payments from another person or organisation for providing this care.</p>
                    <p>If you do not know the answer to a question, leave it and move on to the next question.</p>
                </div>

                <h2>Personal details of the person being cared of</h2>

                <fieldset aria-labelledby="reportedPersonDetailsOfPersonBeingCaredFor.knowDetails" className="vertical-inputs">
                    <legend id="reportedPersonDetailsOfPersonBeingCaredFor.knowDetails">Do you know the name of the person being cared for?</legend>
                    <input type="radio"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.knowDetails"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.knowDetailsYes"
                           value="Yes"
                           checked={props.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.knowDetailsYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.knowDetailsYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.knowDetails"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.knowDetailsNo"
                           value="No"
                           checked={props.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.knowDetailsNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.knowDetailsNo" className="form-radio">No</label>

                </fieldset>

                <div hidden={props.reportedPersonDetailsOfPersonBeingCaredFor.knowDetails !== 'Yes'}>
                    <br/>
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.firstName" className="form-text">First name</label>
                    <input type="text"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.firstName"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.firstName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonDetailsOfPersonBeingCaredFor.firstName}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.firstName'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.surname" className="form-text">Surname</label>
                    <input type="text"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.surname"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.surname"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonDetailsOfPersonBeingCaredFor.surname}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.surname'}
                           onChange={props.handleObjectChange} />
                    <br/>
                </div>

                <br/>
                <fieldset aria-labelledby="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson" className="vertical-inputs">
                    <legend id="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson">Is the person’s address the same as one of the person you are reporting?</legend>
                    <input type="radio"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonYes"
                           value="Yes"
                           checked={props.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonNo"
                           value="No"
                           checked={props.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson === 'I don\'t know'}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonDoNotKnow'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPersonDoNotKnow" className="form-radio">I don't know</label>

                </fieldset>

                <div hidden={props.reportedPersonDetailsOfPersonBeingCaredFor.sameAddressAsReportedPerson !== 'No'}>

                    <br/>
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber" className="form-text">House number or Building name</label>
                    <input type="text"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.addressHouseNumber'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.addressStreetName" className="form-text">Street name</label>
                    <input type="text"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.addressStreetName"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.addressStreetName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonDetailsOfPersonBeingCaredFor.addressStreetName}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.addressStreetName'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.addressCity" className="form-text">City or Town</label>
                    <input type="text"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.addressCity"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.addressCity"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonDetailsOfPersonBeingCaredFor.addressCity}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.addressCity'}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.addressPostcode" className="form-text">Postcode</label>
                    <input type="text"
                           className="postcode"
                           id="reportedPersonDetailsOfPersonBeingCaredFor.addressPostcode"
                           name="reportedPersonDetailsOfPersonBeingCaredFor.addressPostcode"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonDetailsOfPersonBeingCaredFor.addressPostcode}
                           autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.addressPostcode'}
                           onChange={props.handleObjectChange} />
                    <br/>

                </div>

                <br/>
                <label htmlFor="reportedPersonDetailsOfPersonBeingCaredFor.commentBox" className="form-text">Why do you think this person is committing fraud? Provide more information in the box below.</label>
                <textarea name="reportedPersonDetailsOfPersonBeingCaredFor.commentBox"
                          id="reportedPersonDetailsOfPersonBeingCaredFor.commentBox"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonDetailsOfPersonBeingCaredFor.commentBox}
                          autoFocus={props.focusElementId === 'reportedPersonDetailsOfPersonBeingCaredFor.commentBox'}
                          onChange={props.handleObjectChange} />

            </div>
        </React.Fragment>
    )
}

export default Step16;