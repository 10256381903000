import React from 'react';

/**
 * Component representing part of the fourth page (see 'Page four: Full Form' at https://65nfjh.axshare.com/)
 *      , it appears when the user selects 'Not caring for someone' as suspected benefit fraud type in previous step
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step5(props) {

    if (props.currentStep !== 5) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-5">
                <h1>Benefits for caring</h1>
                <div className={ props.errorClass.carerBetween16And18 === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <fieldset aria-labelledby="carerBetween16And18" className="vertical-inputs">
                        <legend id="carerBetween16And18">Is the carer between 16-18 years old?</legend>

                        {
                            props.errorClass.carerBetween16And18 === 'error' ?
                                <span id="carerBetween16And18-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Select yes if the carer is between 16 to 18 years old
                                </span>
                                : null
                        }

                        <input type="radio"
                               name="carerBetween16And18"
                               id="carerBetween16And18Yes"
                               value="Yes"
                               checked={props.carerBetween16And18 === 'Yes'}
                               className={props.errorClass.carerBetween16And18}
                               onChange={props.handleChange} />
                        <label htmlFor="carerBetween16And18Yes" className="form-radio">Yes</label>
                        <input type="radio"
                               name="carerBetween16And18"
                               id="carerBetween16And18No"
                               value="No"
                               checked={props.carerBetween16And18 === 'No'}
                               className={props.errorClass.carerBetween16And18}
                               onChange={props.handleChange} />
                        <label htmlFor="carerBetween16And18No" className="form-radio">No</label>
                    </fieldset>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Step5;