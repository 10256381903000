import React from 'react';

/**
 * Component representing 'SG Employee' part of the fourth page (see 'Page four: Full Form' at https://65nfjh.axshare.com/)
 *      , it contains information about the suspected fraud reported person (i.e. if he/she is involved with Scottish Government in some way)
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step8(props) {

    if (props.currentStep !== 8) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-8">

                <h1>Social Security Scotland Employees</h1>

                <div className={ props.errorClass.reportedPersonIsScottishGovEmployee === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <fieldset aria-describedby="reportedPersonIsScottishGovEmployee" className="vertical-inputs">
                        <legend id="reportedPersonIsScottishGovEmployee">Is the person who is claiming the benefit or award an employee of Social Security Scotland?</legend>

                        {
                            props.errorClass.reportedPersonIsScottishGovEmployee === 'error' ?
                                <span id="reportedPersonIsScottishGovEmployee-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Select yes if the person who is claiming the benefit or award is an employee of Social Security Scotland
                                </span>
                                : null
                        }

                        <input type="radio"
                               name="reportedPersonIsScottishGovEmployee"
                               value="Yes"
                               id="reportedPersonIsScottishGovEmployeeYes"
                               checked={props.reportedPersonIsScottishGovEmployee === 'Yes'}
                               autoFocus={props.focusElementId === 'reportedPersonIsScottishGovEmployeeYes'}
                               onChange={props.handleChange} />
                        <label htmlFor="reportedPersonIsScottishGovEmployeeYes" className="form-radio">Yes</label>

                        <input type="radio"
                               name="reportedPersonIsScottishGovEmployee"
                               value="No"
                               id="reportedPersonIsScottishGovEmployeeNo"
                               checked={props.reportedPersonIsScottishGovEmployee === 'No'}
                               autoFocus={props.focusElementId === 'reportedPersonIsScottishGovEmployeeNo'}
                               onChange={props.handleChange} />
                        <label htmlFor="reportedPersonIsScottishGovEmployeeNo" className="form-radio">No</label>

                        <input type="radio"
                               name="reportedPersonIsScottishGovEmployee"
                               value="I don't know"
                               id="reportedPersonIsScottishGovEmployeeDoNotKnow"
                               checked={props.reportedPersonIsScottishGovEmployee === 'I don\'t know'}
                               autoFocus={props.focusElementId === 'reportedPersonIsScottishGovEmployeeDoNotKnow'}
                               onChange={props.handleChange} />
                        <label htmlFor="reportedPersonIsScottishGovEmployeeDoNotKnow" className="form-radio">I don't know</label>

                    </fieldset>
                </div>

                <div hidden={props.reportedPersonIsScottishGovEmployee !== 'Yes'}>
                    <br/>
                    <label htmlFor="reportedPersonScottishGovEmployee.jobRoleOrTitle" className="form-text">What is their job role or title?</label>
                    <p className="hint">Please give details of their job duties, team or department they work in.</p>
                    <textarea name="reportedPersonScottishGovEmployee.jobRoleOrTitle"
                              id="reportedPersonScottishGovEmployee.jobRoleOrTitle"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonScottishGovEmployee.jobRoleOrTitle}
                              autoFocus={props.focusElementId === 'reportedPersonScottishGovEmployee.jobRoleOrTitle'}
                              onChange={props.handleObjectChange} />
                </div>

                <br/>

                <div className={ props.errorClass.scottishGovEmployeeInvolved === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <fieldset aria-describedby="scottishGovEmployeeInvolved" className="vertical-inputs">

                        {
                            props.reportedPersonIsScottishGovEmployee === 'Yes' ?
                                <legend id="scottishGovEmployeeInvolved">Is there another employee of Social Security Scotland involved with the suspected fraud?</legend>
                                : <legend id="scottishGovEmployeeInvolved">Is an employee of Social Security Scotland involved with the suspected fraud?</legend>
                        }

                        {
                            props.errorClass.scottishGovEmployeeInvolved === 'error' ?
                                <span id="scottishGovEmployeeInvolved-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Select yes if anyone from Social Security Scotland is involved with committing fraud
                                </span>
                                : null
                        }

                        <input type="radio"
                               name="scottishGovEmployeeInvolved"
                               value="Yes"
                               id="scottishGovEmployeeInvolvedYesWorksFor"
                               checked={props.scottishGovEmployeeInvolved === 'Yes'}
                               autoFocus={props.focusElementId === 'scottishGovEmployeeInvolvedYesWorksFor'}
                               onChange={props.handleChange} />
                        <label htmlFor="scottishGovEmployeeInvolvedYesWorksFor" className="form-radio">Yes</label>

                        <input type="radio"
                               name="scottishGovEmployeeInvolved"
                               value="No"
                               id="scottishGovEmployeeInvolvedNo"
                               checked={props.scottishGovEmployeeInvolved === 'No'}
                               autoFocus={props.focusElementId === 'scottishGovEmployeeInvolvedNo'}
                               onChange={props.handleChange} />
                        <label htmlFor="scottishGovEmployeeInvolvedNo" className="form-radio">No</label>

                        <input type="radio"
                               name="scottishGovEmployeeInvolved"
                               value="I don't know"
                               id="scottishGovEmployeeInvolvedDoNotKnow"
                               checked={props.scottishGovEmployeeInvolved === 'I don\'t know'}
                               autoFocus={props.focusElementId === 'scottishGovEmployeeInvolvedDoNotKnow'}
                               onChange={props.handleChange} />
                        <label htmlFor="scottishGovEmployeeInvolvedDoNotKnow" className="form-radio">I don't know</label>
                    </fieldset>
                </div>

                <div className="fieldset-wrapper" hidden={props.scottishGovEmployeeInvolved !== 'Yes'}>

                    <h2>The Social Security Scotland employee you are reporting</h2>

                    <label htmlFor="scottishGovEmployee.firstName" className="form-text">First name</label>
                    <input type="text"
                           id="scottishGovEmployee.firstName"
                           name="scottishGovEmployee.firstName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.scottishGovEmployee.firstName}
                           autoFocus={props.focusElementId === 'scottishGovEmployee.firstName'}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="scottishGovEmployee.surname" className="form-text">Surname</label>
                    <input type="text"
                           id="scottishGovEmployee.surname"
                           name="scottishGovEmployee.surname"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.scottishGovEmployee.surname}
                           autoFocus={props.focusElementId === 'scottishGovEmployee.surname'}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="scottishGovEmployee.jobTitle" className="form-text">What is their job role or title?</label>
                    <p className="hint">Please give details of their job duties, team or department they work in.</p>
                    <textarea name="scottishGovEmployee.jobTitle"
                              id="scottishGovEmployee.jobTitle"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.scottishGovEmployee.jobTitle}
                              autoFocus={props.focusElementId === 'scottishGovEmployee.jobTitle'}
                              onChange={props.handleObjectChange} />

                    <label htmlFor="scottishGovEmployee.physicalDescription" className="form-text">Physical description</label>
                    <textarea name="scottishGovEmployee.physicalDescription"
                              id="scottishGovEmployee.physicalDescription"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.scottishGovEmployee.physicalDescription}
                              autoFocus={props.focusElementId === 'scottishGovEmployee.physicalDescription'}
                              onChange={props.handleObjectChange} />

                    <h2>Address</h2>

                    <label htmlFor="scottishGovEmployee.addressHouseNumber" className="form-text">House number or Building name</label>
                    <input type="text"
                           id="scottishGovEmployee.addressHouseNumber"
                           name="scottishGovEmployee.addressHouseNumber"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.scottishGovEmployee.addressHouseNumber}
                           autoFocus={props.focusElementId === 'scottishGovEmployee.addressHouseNumber'}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="scottishGovEmployee.addressStreetName" className="form-text">Street name</label>
                    <input type="text"
                           id="scottishGovEmployee.addressStreetName"
                           name="scottishGovEmployee.addressStreetName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.scottishGovEmployee.addressStreetName}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="scottishGovEmployee.addressCity" className="form-text">City or Town</label>
                    <input type="text"
                           id="scottishGovEmployee.addressCity"
                           name="scottishGovEmployee.addressCity"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.scottishGovEmployee.addressCity}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="scottishGovEmployee.addressPostcode" className="form-text">Postcode</label>
                    <input type="text"
                           className="postcode"
                           id="scottishGovEmployee.addressPostcode"
                           name="scottishGovEmployee.addressPostcode"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.scottishGovEmployee.addressPostcode}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="scottishGovEmployee.whatMakesYouThinkPersonIsInvolved" className="form-text">What makes you think this person is involved in the fraud you are reporting?</label>
                    <textarea name="scottishGovEmployee.whatMakesYouThinkPersonIsInvolved"
                              id="scottishGovEmployee.whatMakesYouThinkPersonIsInvolved"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.scottishGovEmployee.whatMakesYouThinkPersonIsInvolved}
                              autoFocus={props.focusElementId === 'scottishGovEmployee.whatMakesYouThinkPersonIsInvolved'}
                              onChange={props.handleObjectChange} />

                    <br/>

                </div>

            </div>
        </React.Fragment>
    )
}

export default Step8;