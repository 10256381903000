import React from 'react';

import {isNumber, isValidDate} from "../utils/Validators";

/**
 * Component representing 'Funeral Support Payment' part of the fourth page (see 'Page four: Full Form' at https://65nfjh.axshare.com/)
 *      , it shows up when 'Payments for a funeral' benefit type is selected in step4.js
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step18(props) {

    if (props.currentStep !== 18) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-18">

                <h1>Funeral Support Payment</h1>
                <div>
                    <p>This section applies to a person who applies for or gets a Funeral Support Payment but does not qualify for this.</p>
                    <p>If you do not know the answer to a question, leave it and move on to the next question.</p>
                </div>

                <br/>
                <fieldset aria-labelledby="reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson" className="vertical-inputs">
                    <legend id="reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson">Do you know the name of the deceased person?</legend>
                    <input type="radio"
                           name="reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson"
                           id="reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPersonYes"
                           value="Yes"
                           checked={props.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPersonYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPersonYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson"
                           id="reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPersonNo"
                           value="No"
                           checked={props.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPersonNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPersonNo" className="form-radio">No</label>

                </fieldset>

                <div hidden={props.reportedPersonFuneralSupportPayment.knowTheNameOfDeceasedPerson !== 'Yes'}>
                    <br/>
                    <label htmlFor="reportedPersonFuneralSupportPayment.deceasedPersonFirstName" className="form-text">First name</label>
                    <input type="text"
                           id="reportedPersonFuneralSupportPayment.deceasedPersonFirstName"
                           name="reportedPersonFuneralSupportPayment.deceasedPersonFirstName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonFuneralSupportPayment.deceasedPersonFirstName}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.deceasedPersonFirstName'}
                           onChange={props.handleObjectChange} />

                    <label htmlFor="reportedPersonFuneralSupportPayment.deceasedPersonSurname" className="form-text">Surname</label>
                    <input type="text"
                           id="reportedPersonFuneralSupportPayment.deceasedPersonSurname"
                           name="reportedPersonFuneralSupportPayment.deceasedPersonSurname"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           value={props.reportedPersonFuneralSupportPayment.deceasedPersonSurname}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.deceasedPersonSurname'}
                           onChange={props.handleObjectChange} />
                    <br/>
                </div>

                <br/>
                <fieldset aria-labelledby="reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied" className="vertical-inputs">
                    <legend id="reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied">Do you know the date the person died?</legend>
                    <input type="radio"
                           name="reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied"
                           id="reportedPersonFuneralSupportPayment.knowTheDateOfPersonDiedYes"
                           value="Yes"
                           checked={props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.knowTheDateOfPersonDiedYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFuneralSupportPayment.knowTheDateOfPersonDiedYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied"
                           id="reportedPersonFuneralSupportPayment.knowTheDateOfPersonDiedNo"
                           value="No"
                           checked={props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.knowTheDateOfPersonDiedNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFuneralSupportPayment.knowTheDateOfPersonDiedNo" className="form-radio">No</label>

                </fieldset>

                <div hidden={props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied !== 'Yes'}>
                    <br/>
                    <div className="questions-with-hint">When did they pass away?</div>
                    <p className="hint">For example, 31 03 1980</p>

                    <div className={ (props.errorClass.reportedPersonFuneralSupportPaymentDateOfDeath === 'error'
                        || props.errorClass.reportedPersonFuneralSupportPaymentDateOfDeathDay === 'error'
                        || props.errorClass.reportedPersonFuneralSupportPaymentDateOfDeathMonth === 'error'
                        || props.errorClass.reportedPersonFuneralSupportPaymentDateOfDeathYear === 'error') ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>

                        {
                            (props.errorClass.reportedPersonFuneralSupportPaymentDateOfDeath === 'error'
                                || props.errorClass.reportedPersonFuneralSupportPaymentDateOfDeathDay === 'error'
                                || props.errorClass.reportedPersonFuneralSupportPaymentDateOfDeathMonth === 'error'
                                || props.errorClass.reportedPersonFuneralSupportPaymentDateOfDeathYear === 'error') ?
                                <span id="dateOfPersonDied-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Enter a valid Date of Death
                                </span>
                                : null
                        }

                        <div className="grid date">
                            <div className={(isValidDate(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear)
                                && isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFuneralSupportPayment.dateOfPersonDiedDay" className="form-date">Day</label>
                                <input type="number"
                                       className="twoDigit"
                                       min={1} max={31} step={1}
                                       name="reportedPersonFuneralSupportPayment.dateOfPersonDiedDay"
                                       id="reportedPersonFuneralSupportPayment.dateOfPersonDiedDay"
                                       value={props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay}
                                       autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.dateOfPersonDiedDay'}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={(isValidDate(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear)
                                && isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth" className="form-date">Month</label>
                                <input type="number"
                                       className="twoDigit"
                                       min={1} max={12} step={1}
                                       name="reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth"
                                       id="reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth"
                                       value={props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={(isValidDate(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear)
                                && isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFuneralSupportPayment.dateOfPersonDiedYear" className="form-date">Year</label>
                                <input type="number"
                                       className="fourDigit"
                                       min={1900} max={9999} step={1}
                                       name="reportedPersonFuneralSupportPayment.dateOfPersonDiedYear"
                                       id="reportedPersonFuneralSupportPayment.dateOfPersonDiedYear"
                                       value={props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear}
                                       onChange={props.handleObjectChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <div hidden={props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied !== 'No'}>
                    <br/>
                    <label htmlFor="reportedPersonFuneralSupportPayment.approximateDateOfPersonDied" className="form-text">Do you know approximately when the person died?</label>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonFuneralSupportPayment.approximateDateOfPersonDied"
                           id="reportedPersonFuneralSupportPayment.approximateDateOfPersonDied"
                           value={props.reportedPersonFuneralSupportPayment.approximateDateOfPersonDied}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.approximateDateOfPersonDied'}
                           onChange={props.handleObjectChange} />
                    <br/>
                </div>

                <br/>
                <fieldset aria-labelledby="reportedPersonFuneralSupportPayment.knowTheDateOfFuneral" className="vertical-inputs">
                    <legend id="reportedPersonFuneralSupportPayment.knowTheDateOfFuneral">Do you know the date the funeral took place?</legend>
                    <input type="radio"
                           name="reportedPersonFuneralSupportPayment.knowTheDateOfFuneral"
                           id="reportedPersonFuneralSupportPayment.knowTheDateOfFuneralYes"
                           value="Yes"
                           checked={props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes'}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.knowTheDateOfFuneralYes'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFuneralSupportPayment.knowTheDateOfFuneralYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonFuneralSupportPayment.knowTheDateOfFuneral"
                           id="reportedPersonFuneralSupportPayment.knowTheDateOfFuneralNo"
                           value="No"
                           checked={props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'No'}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.knowTheDateOfFuneralNo'}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonFuneralSupportPayment.knowTheDateOfFuneralNo" className="form-radio">No</label>

                </fieldset>

                <div hidden={props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral !== 'Yes'}>
                    <br/>
                    <div className="questions-with-hint">When did the funeral take place?</div>
                    <p className="hint">For example, 31 03 1980</p>

                    <div className={ (props.errorClass.reportedPersonFuneralSupportPaymentDateOfFuneral === 'error'
                        || props.errorClass.reportedPersonFuneralSupportPaymentDateOfFuneralDay === 'error'
                        || props.errorClass.reportedPersonFuneralSupportPaymentDateOfFuneralMonth === 'error'
                        || props.errorClass.reportedPersonFuneralSupportPaymentDateOfFuneralYear === 'error') ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>

                        {
                            (props.errorClass.reportedPersonFuneralSupportPaymentDateOfFuneral === 'error'
                                || props.errorClass.reportedPersonFuneralSupportPaymentDateOfFuneralDay === 'error'
                                || props.errorClass.reportedPersonFuneralSupportPaymentDateOfFuneralMonth === 'error'
                                || props.errorClass.reportedPersonFuneralSupportPaymentDateOfFuneralYear === 'error') ?
                                <span id="dateOfFuneral-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Enter a valid Date of Funeral
                                </span>
                                : null
                        }

                        <div className="grid date">
                            <div className={(isValidDate(props.reportedPersonFuneralSupportPayment.dateOfFuneralDay, props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth, props.reportedPersonFuneralSupportPayment.dateOfFuneralYear)
                                && isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralDay, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFuneralSupportPayment.dateOfFuneralDay" className="form-date">Day</label>
                                <input type="number"
                                       className="twoDigit"
                                       min={1} max={31} step={1}
                                       name="reportedPersonFuneralSupportPayment.dateOfFuneralDay"
                                       id="reportedPersonFuneralSupportPayment.dateOfFuneralDay"
                                       value={props.reportedPersonFuneralSupportPayment.dateOfFuneralDay}
                                       autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.dateOfFuneralDay'}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={(isValidDate(props.reportedPersonFuneralSupportPayment.dateOfFuneralDay, props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth, props.reportedPersonFuneralSupportPayment.dateOfFuneralYear)
                                && isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFuneralSupportPayment.dateOfFuneralMonth" className="form-date">Month</label>
                                <input type="number"
                                       className="twoDigit"
                                       min={1} max={12} step={1}
                                       name="reportedPersonFuneralSupportPayment.dateOfFuneralMonth"
                                       id="reportedPersonFuneralSupportPayment.dateOfFuneralMonth"
                                       value={props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth}
                                       onChange={props.handleObjectChange} />
                            </div>
                            <div className={(isValidDate(props.reportedPersonFuneralSupportPayment.dateOfFuneralDay, props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth, props.reportedPersonFuneralSupportPayment.dateOfFuneralYear)
                                && isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralYear, 'dob')) ? '' : 'error'}>
                                <label htmlFor="reportedPersonFuneralSupportPayment.dateOfFuneralYear" className="form-date">Year</label>
                                <input type="number"
                                       className="fourDigit"
                                       min={1900} max={9999} step={1}
                                       name="reportedPersonFuneralSupportPayment.dateOfFuneralYear"
                                       id="reportedPersonFuneralSupportPayment.dateOfFuneralYear"
                                       value={props.reportedPersonFuneralSupportPayment.dateOfFuneralYear}
                                       onChange={props.handleObjectChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <div hidden={props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral !== 'No'}>
                    <br/>
                    <label htmlFor="reportedPersonFuneralSupportPayment.approximateDateOfFuneral" className="form-text">Do you know approximately when funeral took place?</label>
                    <input type="text"
                           maxLength={props.maxLengthForText}
                           name="reportedPersonFuneralSupportPayment.approximateDateOfFuneral"
                           id="reportedPersonFuneralSupportPayment.approximateDateOfFuneral"
                           value={props.reportedPersonFuneralSupportPayment.approximateDateOfFuneral}
                           autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.approximateDateOfFuneral'}
                           onChange={props.handleObjectChange} />
                    <br/>
                </div>

                <label htmlFor="reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely" className="form-text-tell-us-what-u-think">Tell us why you think this application has been made falsely.</label>
                <p className="hint">This might include if the person:</p>
                <ul className="hint">
                    <li>has used false information when applying</li>
                    <li>has received money or has money from another source to pay for the funeral</li>
                    <li>is not responsible for arranging and/or paying for the funeral</li>
                    <li>has worked with someone to help them with the fraud when applying. For example, a funeral director or family member.</li>
                </ul>

                <textarea name="reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely"
                          id="reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely}
                          autoFocus={props.focusElementId === 'reportedPersonFuneralSupportPayment.whyApplicationMadeFalsely'}
                          onChange={props.handleObjectChange} />

            </div>
        </React.Fragment>
    )
}

export default Step18;