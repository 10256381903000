import React from "react";

import CloseIcon from '@material-ui/icons/Close';

/**
 * Custom component for use in cases of children dynamic list (disability child benefits section - step11.js)
 */
class BenefitDisabilityChild extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.id || '1',
            firstName: props.firstName || '',
            surname: props.surname || '',
            hasAlternativeName: props.hasAlternativeName || '',
            alternativeName: props.alternativeName || '',
            sameAddressAsReportedPerson: props.sameAddressAsReportedPerson || '',
            addressHouseNumber: props.addressHouseNumber || '',
            addressStreetName: props.addressStreetName || '',
            addressCity: props.addressCity || '',
            addressPostcode: props.addressPostcode || '',
            maxLengthForText: props.maxLengthForText || '',
            listName: props.listName || '',
            listLength: props.listLength || '',
            handleChildRemoval: props.handleChildRemoval,
            handleObjectInsideListChange: props.handleObjectInsideListChange,
            autoFocus: props.autoFocus === 'reportedPersonBenefitPaymentForDisabilityChildren.'.concat(props.id).concat('.firstName')
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.firstName !== prevState.firstName) {
            return {firstName: nextProps.firstName}
        } else if (nextProps.surname !== prevState.surname) {
            return {surname: nextProps.surname}
        } else if (nextProps.hasAlternativeName !== prevState.hasAlternativeName) {
            return {hasAlternativeName: nextProps.hasAlternativeName}
        } else if (nextProps.alternativeName !== prevState.alternativeName) {
            return {alternativeName: nextProps.alternativeName}
        } else if (nextProps.sameAddressAsReportedPerson !== prevState.sameAddressAsReportedPerson) {
            return {sameAddressAsReportedPerson: nextProps.sameAddressAsReportedPerson}
        } else if (nextProps.addressHouseNumber !== prevState.addressHouseNumber) {
            return {addressHouseNumber: nextProps.addressHouseNumber}
        } else if (nextProps.addressStreetName !== prevState.addressStreetName) {
            return {addressStreetName: nextProps.addressStreetName}
        } else if (nextProps.addressCity !== prevState.addressCity) {
            return {addressCity: nextProps.addressCity}
        } else if (nextProps.addressPostcode !== prevState.addressPostcode) {
            return {addressPostcode: nextProps.addressPostcode}
        } else if (nextProps.listLength !== prevState.listLength) {
            return {listLength: nextProps.listLength}
        } else {
            return null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.firstName !== this.state.firstName) {
            let first_ = this.state.firstName
            this.setState({
                firstName: first_
            })
        } else if (prevState.surname !== this.state.surname) {
            let last_ = this.state.surname
            this.setState({
                surname: last_
            })
        } else if (prevState.hasAlternativeName !== this.state.hasAlternativeName) {
            let hasAlternativeName_ = this.state.hasAlternativeName
            this.setState({
                hasAlternativeName: hasAlternativeName_
            })
        } else if (prevState.alternativeName !== this.state.alternativeName) {
            let alternativeName_ = this.state.alternativeName
            this.setState({
                alternativeName: alternativeName_
            })
        } else if (prevState.sameAddressAsReportedPerson !== this.state.sameAddressAsReportedPerson) {
            let sameAddressAsReportedPerson_ = this.state.sameAddressAsReportedPerson
            this.setState({
                sameAddressAsReportedPerson: sameAddressAsReportedPerson_
            })
        } else if (prevState.addressHouseNumber !== this.state.addressHouseNumber) {
            let addressHouseNumber_ = this.state.addressHouseNumber
            this.setState({
                addressHouseNumber: addressHouseNumber_
            })
        } else if (prevState.addressStreetName !== this.state.addressStreetName) {
            let addressStreetName_ = this.state.addressStreetName
            this.setState({
                addressStreetName: addressStreetName_
            })
        } else if (prevState.addressCity !== this.state.addressCity) {
            let addressCity_ = this.state.addressCity
            this.setState({
                addressCity: addressCity_
            })
        } else if (prevState.addressPostcode !== this.state.addressPostcode) {
            let addressPostcode_ = this.state.addressPostcode
            this.setState({
                addressPostcode: addressPostcode_
            })
        } else if (prevState.listLength !== this.state.listLength) {
            let divToFocus
            /*if (prevState.listLength > this.state.listLength) {
                divToFocus = "reportedPersonBenefitPaymentForDisabilityChildren-add-child"
            } else {
                divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".firstName")
            }*/
            divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".firstName")
            document.getElementById(divToFocus).focus()
        }
    }

    render() {
        return (
            <React.Fragment>

                <div className="mobile-grid">
                    <h2 className="h2--child-repeater">Child {this.state.id}</h2>

                    <div hidden={this.state.listLength <= 1}>
                        <button type="button"
                                className="link--button remove-child--button"
                                name="reportedPersonBenefitPaymentForDisabilityChildrenRemove"
                                value={this.state.id}
                                onClick={this.state.handleChildRemoval}>
                            <CloseIcon className="remove-child--button-icon" />remove child <span className="visually-hidden">{this.state.id}</span>
                        </button>
                    </div>
                </div>

                <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")} className="form-text">First name</label>
                <input type="text"
                       id={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")}
                       name={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")}
                       autoComplete="off"
                       maxLength={this.state.maxLengthForText}
                       value={this.state.firstName}
                       autoFocus={this.state.autoFocus}
                       onChange={this.state.handleObjectInsideListChange} />
                <br/>

                <br/>
                <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".surname")} className="form-text">Surname</label>
                <input type="text"
                       id={this.state.listName.concat(".").concat(this.state.id).concat(".surname")}
                       name={this.state.listName.concat(".").concat(this.state.id).concat(".surname")}
                       autoComplete="off"
                       maxLength={this.state.maxLengthForText}
                       value={this.state.surname}
                       onChange={this.state.handleObjectInsideListChange} />
                <br/>

                <br/>

                <fieldset aria-labelledby={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeName")} className="vertical-inputs">

                    <legend id={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeName")}>Does the child go by any other name(s)?</legend>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeName")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeNameYes")}
                           value="Yes"
                           checked={this.state.hasAlternativeName === 'Yes'}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeNameYes")} className="form-radio">Yes</label>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeName")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeNameNo")}
                           value="No"
                           checked={this.state.hasAlternativeName === 'No'}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeNameNo")} className="form-radio">No</label>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeName")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeNameDoNotKnow")}
                           value="I don't know"
                           checked={this.state.hasAlternativeName === 'I don\'t know'}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".hasAlternativeNameDoNotKnow")} className="form-radio">I don't know</label>

                </fieldset>
                <br/>

                <div hidden={this.state.hasAlternativeName !== 'Yes'}>
                    <p className="hint">If so, please add this/these in the box below</p>
                    <input type="text"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".alternativeName")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".alternativeName")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.alternativeName}
                           aria-label="Child alternative name"
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>
                </div>

                <fieldset aria-labelledby={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPerson")} className="vertical-inputs">

                    <legend id={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPerson")}>Is the child's address the same as the person reported?</legend>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPerson")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPersonYes")}
                           value="Yes"
                           checked={this.state.sameAddressAsReportedPerson === 'Yes'}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPersonYes")} className="form-radio">Yes</label>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPerson")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPersonNo")}
                           value="No"
                           checked={this.state.sameAddressAsReportedPerson === 'No'}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPersonNo")} className="form-radio">No</label>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPerson")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPersonDoNotKnow")}
                           value="I don't know"
                           checked={this.state.sameAddressAsReportedPerson === 'I don\'t know'}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".sameAddressAsReportedPersonDoNotKnow")} className="form-radio">I don't know</label>

                </fieldset>
                <br/>

                <div hidden={this.state.sameAddressAsReportedPerson !== 'No'}>

                    <br/>
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".addressHouseNumber")} className="form-text">House number or Building name</label>
                    <input type="text"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".addressHouseNumber")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".addressHouseNumber")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.addressHouseNumber}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".addressStreetName")} className="form-text">Street name</label>
                    <input type="text"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".addressStreetName")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".addressStreetName")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.addressStreetName}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".addressCity")} className="form-text">City or Town</label>
                    <input type="text"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".addressCity")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".addressCity")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.addressCity}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".addressPostcode")} className="form-text">Postcode</label>
                    <input type="text"
                           className="postcode"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".addressPostcode")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".addressPostcode")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.addressPostcode}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>

                </div>

                <hr className="repeater-component" />

            </React.Fragment>
        )
    }
}

export default BenefitDisabilityChild