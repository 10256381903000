import React from 'react';

import {multiSelectListAnyValueChecked} from "./HelperUtils";
import {isNumber, isValidDate} from "./Validators";

/**
 * Custom component for use in cases of message boxes (see 'Fraudulent identity' (i.e. step19.js) of 'Page four: Full Form' at https://10t3ux.axshare.com/)
 *                      OR in cases of error messages (e.g. error in submit, step23.js)
 *
 * @param props
 * @constructor
 */
function MessageBox(props) {

    if (props.currentStep !== 3 && props.currentStep !== 4 && props.currentStep !== 5
            && props.currentStep !== 6 && props.currentStep !== 7 && props.currentStep !== 8
            && props.currentStep !== 11 && props.currentStep !== 12 && props.currentStep !== 13
            && props.currentStep !== 14 && props.currentStep !== 18 && props.currentStep !== 19
            && props.currentStep !== 22) {
        return null
    }

    if (props.currentStep === 3) {

        if (props.message === 'Error in Step 3' && props.personReceivingOrReceivedBenefit === '') {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    props.personReceivingOrReceivedBenefit === ''
                                        ?
                                        <li>
                                            <a href="#personReceivingOrReceivedBenefit-error">Select yes if the person gets or has received benefits in Scotland</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return null
        }

    } else if (props.currentStep === 4) {

        if (props.message === 'Error in Step 4' && !multiSelectListAnyValueChecked(props.suspectedBenefitFraudTypes)) {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    !multiSelectListAnyValueChecked(props.suspectedBenefitFraudTypes)
                                        ?
                                        <li>
                                            <a href="#suspectedBenefitFraudTypes-error">What types of suspected benefit fraud are you reporting?</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return null
        }

    } else if (props.currentStep === 5) {

        if (props.message === 'Error in Step 5' && props.carerBetween16And18 === '') {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    props.carerBetween16And18 === ''
                                        ?
                                        <li>
                                            <a href="#carerBetween16And18-error">What age is the carer</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else {
            return null
        }

    } else if (props.currentStep === 6) {

        if (props.message === 'Error in Step 6' && props.childDisabilityUnder18 === '') {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    props.childDisabilityUnder18 === ''
                                        ?
                                        <li>
                                            <a href="#childDisabilityUnder18-error">What age is the child related to the disability benefit</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else {
            return null
        }

    } else if (props.currentStep === 7) {

        if (props.message === 'Error in Step 7'
                && (props.reportedPersonPersonalInfo.firstName === ''
                        || props.reportedPersonPersonalInfo.surname === ''
                        || props.reportedPersonPersonalInfo.addressCity === '')) {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    props.reportedPersonPersonalInfo.firstName === '' ?
                                        <li>
                                            <a href="#firstName-error">Enter your first name</a>
                                        </li>
                                        : null
                                }
                                {
                                    props.reportedPersonPersonalInfo.surname === '' ?
                                        <li>
                                            <a href="#surname-error">Enter your surname</a>
                                        </li>
                                        : null
                                }
                                {
                                    props.reportedPersonPersonalInfo.addressCity === '' ?
                                        <li>
                                            <a href="#addressCity-error">Enter your city/town</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else {
            return null
        }

    } else if (props.currentStep === 8) {

        if (props.message === 'Error in Step 8' && props.scottishGovEmployeeInvolved === '') {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    props.reportedPersonIsScottishGovEmployee === ''
                                        ?
                                        <li>
                                            <a href="#reportedPersonIsScottishGovEmployee-error">Is the person who is claiming the benefit or award an employee of Social Security Scotland</a>
                                        </li>
                                        : null
                                }
                                {
                                    props.reportedPersonIsScottishGovEmployee !== '' && props.scottishGovEmployeeInvolved === ''
                                        ?
                                        <li>
                                            <a href="#scottishGovEmployeeInvolved-error">Is there another employee of Social Security Scotland employee involved with the fraud</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else {
            return null
        }

    } else if (props.currentStep === 11) {

        if (props.message === 'Error in Step 11' && props.reportedPersonBenefitForChildOrPregnancy === '') {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    props.reportedPersonBenefitForChildOrPregnancy === ''
                                        ?
                                        <li>
                                            <a href="#reportedPersonBenefitForChildOrPregnancy-error">What type of child or pregnancy fraud are you reporting?</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return null
        }

    } else if (props.currentStep === 12) {

        {/**
         * Commented based on the agreement for 1st live release (see Helen's latest email, Friday 31/7/2020)
         */}
        /*if (props.message === 'Error in Step 12'
                && ((!isValidDate(props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthYear)
                            && props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                        || (!isValidDate(props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthYear)
                                && props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                        || (!isValidDate(props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthYear)
                                && props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.knowDateOfBirth === 'Yes'))) {

                return (
                    <React.Fragment>
                        <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                            <h2 className="govuk-error-summary__title" id="error-summary-title">
                                There is a problem
                            </h2>
                            <div className="govuk-error-summary__body">
                                <ul className="govuk-list govuk-error-summary__list">
                                    {
                                        ((!isValidDate(props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthYear)
                                                    && props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenFirstChild.knowDateOfBirth === 'Yes'))
                                            ?
                                            <li>
                                                <a href="#reportedPersonBenefitPaymentForCaringChildrenFirstChildDateOfBirth-error">First Child: Enter a valid Date of Birth</a>
                                            </li>
                                            : null
                                    }
                                    {
                                        ((!isValidDate(props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthYear)
                                                    && props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenSecondChild.knowDateOfBirth === 'Yes'))
                                            ?
                                            <li>
                                                <a href="#reportedPersonBenefitPaymentForCaringChildrenSecondChildDateOfBirth-error">Second Child: Enter a valid Date of Birth</a>
                                            </li>
                                            : null
                                    }
                                    {
                                        ((!isValidDate(props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthYear)
                                                    && props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForCaringChildrenThirdChild.knowDateOfBirth === 'Yes'))
                                            ?
                                            <li>
                                                <a href="#reportedPersonBenefitPaymentForCaringChildrenThirdChildDateOfBirth-error">Third Child: Enter a valid Date of Birth</a>
                                            </li>
                                            : null
                                    }
                                </ul>
                            </div>
                        </div>
                    </React.Fragment>
                )
        } {*/
            return null
        //}

    } else if (props.currentStep === 13) {

        {/**
         * Commented based on the agreement for 1st live release (see Helen's latest email, Friday 31/7/2020)
         */}
        /*if (props.message === 'Error in Step 13'
                && ((!isValidDate(props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthYear)
                            && props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                        || (!isValidDate(props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthYear)
                                && props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                        || (!isValidDate(props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthYear)
                                && props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                        || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.knowDateOfBirth === 'Yes'))) {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    ((!isValidDate(props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthYear)
                                                && props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenFirstChild.knowDateOfBirth === 'Yes'))
                                        ?
                                        <li>
                                            <a href="#reportedPersonBenefitPaymentForNotCaringChildrenFirstChildDateOfBirth-error">First Child: Enter a valid Date of Birth</a>
                                        </li>
                                        : null
                                }
                                {
                                    ((!isValidDate(props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthYear)
                                                && props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenSecondChild.knowDateOfBirth === 'Yes'))
                                        ?
                                        <li>
                                            <a href="#reportedPersonBenefitPaymentForNotCaringChildrenSecondChildDateOfBirth-error">Second Child: Enter a valid Date of Birth</a>
                                        </li>
                                        : null
                                }
                                {
                                    ((!isValidDate(props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthDay, props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthMonth, props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthYear)
                                                && props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthDay, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthMonth, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.knowDateOfBirth === 'Yes')
                                            || (!isNumber(props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.dateOfBirthYear, 'dob') && props.reportedPersonBenefitPaymentForNotCaringChildrenThirdChild.knowDateOfBirth === 'Yes'))
                                        ?
                                        <li>
                                            <a href="#reportedPersonBenefitPaymentForNotCaringChildrenThirdChildDateOfBirth-error">Third Child: Enter a valid Date of Birth</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else {*/
            return null
        //}

    } else if (props.currentStep === 18) {

        if (props.message === 'Error in Step 18'
            && ((!isValidDate(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear)
                    && props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes')
                || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes')
                || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes')
                || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes')
                || (!isValidDate(props.reportedPersonFuneralSupportPayment.dateOfFuneralDay, props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth, props.reportedPersonFuneralSupportPayment.dateOfFuneralYear) && props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes')
                || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralDay, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes')
                || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes')
                || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralYear, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes'))
        ) {

            return (
                <React.Fragment>
                    <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                        <h2 className="govuk-error-summary__title" id="error-summary-title">
                            There is a problem
                        </h2>
                        <div className="govuk-error-summary__body">
                            <ul className="govuk-list govuk-error-summary__list">
                                {
                                    ((!isValidDate(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth, props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear)
                                            && props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes')
                                        || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedDay, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes')
                                        || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedMonth, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes')
                                        || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfPersonDiedYear, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfPersonDied === 'Yes')) ?
                                        <li>
                                            <a href="#dateOfPersonDied-error">Enter a valid Date of Death</a>
                                        </li>
                                        : null
                                }
                                {
                                    ((!isValidDate(props.reportedPersonFuneralSupportPayment.dateOfFuneralDay, props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth, props.reportedPersonFuneralSupportPayment.dateOfFuneralYear) && props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes')
                                        || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralDay, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes')
                                        || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralMonth, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes')
                                        || (!isNumber(props.reportedPersonFuneralSupportPayment.dateOfFuneralYear, 'dob') && props.reportedPersonFuneralSupportPayment.knowTheDateOfFuneral === 'Yes')) ?
                                        <li>
                                            <a href="#dateOfFuneral-error">Enter a valid Date of Funeral</a>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )

        } else {
            return null
        }

    } else if (props.currentStep === 19) {

        return (
            <React.Fragment>
                {
                    (props.message === 'Error in Step 19'
                        && ((!isValidDate(props.reportedPersonFraudulentIdentity.dateOfBirthDay, props.reportedPersonFraudulentIdentity.dateOfBirthMonth, props.reportedPersonFraudulentIdentity.dateOfBirthYear)
                                && props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes')
                                || (!isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthDay, 'dob') && props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes')
                                || (!isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthMonth, 'dob') && props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes')
                                || (!isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthYear, 'dob') && props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes'))
                    ) ?
                        <div className="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1" data-module="govuk-error-summary">
                            <h2 className="govuk-error-summary__title" id="error-summary-title">
                                There is a problem
                            </h2>
                            <div className="govuk-error-summary__body">
                                <ul className="govuk-list govuk-error-summary__list">
                                    {
                                        ((!isValidDate(props.reportedPersonFraudulentIdentity.dateOfBirthDay, props.reportedPersonFraudulentIdentity.dateOfBirthMonth, props.reportedPersonFraudulentIdentity.dateOfBirthYear)
                                                    && props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthDay, 'dob') && props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthMonth, 'dob') && props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes')
                                                || (!isNumber(props.reportedPersonFraudulentIdentity.dateOfBirthYear, 'dob') && props.reportedPersonFraudulentIdentity.knowDateOfBirth === 'Yes'))
                                            ?
                                            <li>
                                                <a href="#reportedPersonFraudulentIdentity-error">Enter a valid Date of Birth</a>
                                            </li>
                                            : null
                                    }
                                </ul>
                            </div>
                        </div>
                    : null
                }
                <div className="message-box">
                    <span className="govuk-warning-text__icon" aria-hidden="true">!</span>
                    <span className="visually-hidden">Warning</span>
                    <p>You should not use this online report if you think someone has used your identity to apply for a benefit.</p>
                    <p>Instead, report this to us using the freephone number 0800 158 2071.</p>
                </div>
            </React.Fragment>
        )

    } else {

        if (props.status !== 0 && props.message !== ''
                && props.message !== 'Error in Step 3' && props.message !== 'Error in Step 4'
                && props.message !== 'Error in Step 5' && props.message !== 'Error in Step 6'
                && props.message !== 'Error in Step 7' && props.message !== 'Error in Step 8'
                && props.message !== 'Error in Step 11' && props.message !== 'Error in Step 12'
                && props.message !== 'Error in Step 13' && props.message !== 'Error in Step 18'
                && props.message !== 'Error in Step 19') {

            return (
                <React.Fragment>
                    <div className="message-box error-message">
                        <span class="govuk-warning-text__icon" aria-hidden="true">X</span>
                        <span class="visually-hidden">Error</span>
                        <p>{props.message}</p>
                    </div>
                </React.Fragment>
            )
        } else {
            return null
        }
    }

}

export default MessageBox