
/**
 * Helper function to check if date values (given by the user) are valid (or not)
 *
 * @param day
 * @param month
 * @param year
 * @returns {boolean}
 */
export function isValidDate(day, month, year) {
    let isValid = true
    let currentYear = new Date().getFullYear()

    /** check: year */
    if (Number.parseInt(year) > currentYear || Number.parseInt(year) < 1900) {
        isValid = false
    }
    /** check: month */
    if (Number.parseInt(month) > 12) {
        isValid = false
    }
    /** check: day */
    if (Number.parseInt(day) > 31) {
        isValid = false
    }
    /** check: day & month (except February) */
    if (Number.parseInt(day) > 30
        && (Number.parseInt(month) === 4 || Number.parseInt(month) === 6 || Number.parseInt(month) === 9 || Number.parseInt(month) === 11)) {
        isValid = false
    }
    /** check: day & month (February (if "special" February, return to valid)) */
    if (Number.parseInt(day) > 28 && Number.parseInt(month) === 2) {
        isValid = false
        if (Number.parseInt(day) === 29 && Number.parseInt(year)%4 === 0) {
            isValid = true
        }
    }

    return isValid
}

/**
 * Helper function to check if date values (without day, given by the user) are valid (or not)
 *
 * @param month
 * @param year
 * @returns {boolean}
 */
export function isValidDateNoDay(month, year) {
    let isValid = true
    let currentYear = new Date().getFullYear()
    /** check: year */
    if (Number.parseInt(year) > currentYear || Number.parseInt(year) < 1900) {
        isValid = false
    }
    /** check: month */
    if (Number.parseInt(month) > 12) {
        isValid = false
    }
    return isValid
}

/**
 * Helper function to check if height (feet/inches) values (given by the user) are valid (or not)
 *
 * @param feet
 * @param inches
 * @returns {boolean}
 */
export function isValidHeight(feet, inches) {
    let isValid = true
    if (feet !== '' || inches !== '') {
        if (Number.parseInt(feet) <= 0 || Number.parseInt(inches) < 0) {
            isValid = false
        }
    }
    return isValid
}

/**
 * Helper function to check if amount value (given by the user) is valid (or not)
 *
 * @param amount
 * @returns {boolean}
 */
export function isValidAmount(amount) {
    let isValid = true
    if (Number.parseFloat(amount) !== 0) {
        if (Number.parseFloat(amount) < 0 || isNaN(Number.parseFloat(amount))) {
            isValid = false
        }
    }
    return isValid
}

/**
 * Helper function to check if a number input (e.g. height (feet, inches)) is valid (i.e. contains only numbers)
 *
 * @param input
 * @param inputName
 * @returns {boolean}
 */
export function isNumber(input, inputName) {
    let isNumber = true
    if (inputName === 'dob') {
        if (input === '') {  /** this case is added as JS interprets some inputs (e.g. 15zy) as empty Strings (& therefore as 0) */
            isNumber = false
        } else {
            if (isNaN(input)) {
                isNumber = false
            }
        }
    } else {
        if (input !== '') {
            if (isNaN(input)) {
                isNumber = false
            }
        }
    }
    return isNumber
}