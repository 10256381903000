import React from 'react';

/**
 * Component representing second page (see 'Page two: Information page about reporting benefit fraud' at https://65nfjh.axshare.com/)
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step2(props) {

    if (props.currentStep !== 2) {
        return null
    }

    return (
        <div className="step step-2">
            <h1>How to report suspected benefit fraud in Scotland</h1>
            <h2>Anonymous reporting</h2>
            <p>This report is anonymous and confidential. You will not provide any of your own personal details.</p>
            <p>For your own safety, you should not:</p>
            <ul>
                <li>tell anyone you have made this report</li>
                <li>try to find out more information about the fraud</li>
            </ul>
            <h2>Providing information about the person</h2>
            <p>Please give as much information as you can about the person you're reporting in the form.</p>
            <p>This should include the following information, but is not limited to:</p>
            <ul>
                <li>their first name</li>
                <li>their surname</li>
                <li>the town where they live</li>
                <li>what you think the person has done</li>
            </ul>
            <p>Without the person's first name, surname and town, we will not be able to look at this case.</p>
            <h2>Answering the questions</h2>
            <p>If you're not sure about how to fill in a section of the report, use the guidance notes at the top of each page.</p>
            <p>Questions that are mandatory have a ‘required’ label. You cannot complete the form without answering them. The rest of the questions are optional.</p>
            <h2>Sharing information with other government agencies</h2>
            <p>We may share the information in this report with other Government agencies. This might include:</p>
            <ul>
                <li>His Majesty's Revenue and Customs (HMRC)</li>
                <li>Department for Work and Pensions (DWP)</li>
                <li>local councils</li>
                <li>other government agencies</li>
            </ul>
            <p>We'll only do this if we need to investigate any case that comes from this report.</p>
            <h2>Reviewing information and submitting the form</h2>
            <p>You cannot save your progress in the form, so you'll need to complete it in one go. You'll have a chance to review and change answers and information at the end of the report before you submit it. Completing the form takes between 15 to 30 minutes.</p>
        </div>
    )
}

export default Step2;