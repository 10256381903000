import React from "react";

import './../css/checkbox.css';

/**
 * Custom component for use in cases of checkbox list sections
 */
class CheckBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.id || '',
            checked: props.isChecked,
            name: props.name || '',
            value: props.value || '',
            subTextUnderCheckBox: props.subTextUnderCheckBox || '',
            handleChangeInMultiSelectCheckBox: props.handleChangeInMultiSelectCheckBox,
            autoFocus: props.autoFocus === 'reportedPersonBenefitPaymentForDisabilityAdult.transportationForms' && props.isChecked
        }
    }

    render() {
        return (
            <li>
                <React.Fragment>
                    <input key={this.state.name.concat(this.state.id)}
                           type="checkbox"
                           autoFocus={this.state.autoFocus}
                           disabled={disableCheckBox(this.state.name, this.state.value)}
                           defaultChecked={this.state.checked}
                           checked={this.state.isChecked}
                           name={this.state.name}
                           id={this.state.name.concat(this.state.value.replace(/\s/g, ""))}
                           value={this.state.value}
                           onChange={this.state.handleChangeInMultiSelectCheckBox} />
                    <label htmlFor={this.state.name.concat(this.state.value.replace(/\s/g, ""))} className="form-checkbox">{this.state.value}</label>
                    <div className="checkBoxSubText"
                         hidden={!this.state.subTextUnderCheckBox}>
                        {addCheckBoxSubText(this.state.name, this.state.value)}
                    </div>
                </React.Fragment>
            </li>
        )
    }
}

/**
 *
 * @param name
 * @param value
 * @returns {*}
 */
function addCheckBoxSubText(name, value) {

    if (name === "suspectedBenefitFraudTypes") {

        if (value === "Payments for a funeral") {
            return (
                <React.Fragment>
                    <div>When a person who applies for or gets a Funeral Support Payment but does not qualify for this.</div>
                </React.Fragment>
            )
        } else if (value === "Report a false or doubtful disability claim for an adult or a child") {
            return (
                <React.Fragment>
                    <div>When a person applies for or is getting benefits for a disability, but the claim is false, or their condition has improved and they are no longer eligible for the benefit. This can be for adult or child disability payment.</div>
                </React.Fragment>
            )
        } else if (value === "Payments for pregnancy, or child responsibility") {
            return (
                <React.Fragment>
                    <div>When a person is getting benefits for children under the age of 16, but they're not responsible for the child. Or, they say they're pregnant but they’re not, in order to apply for benefit payments.</div>
                </React.Fragment>
            )
        } else if (value === "Payments or benefits received for providing care") {
            return (
                <React.Fragment>
                    <div>When a person is getting benefits for providing care for a person but is not caring for them. Or, they’re not providing the expected level of care. This grant is only available for people aged 16 to 18.</div>
                </React.Fragment>
            )
        } else if (value === "Using a fraudulent identity when applying for benefits or payments") {
            return (
                <React.Fragment>
                    <div>When a person has applied for or gets benefits using false documents or documents that are not their own. Or, they have used someone else’s name and other personal information when applying. </div>
                </React.Fragment>
            )
        } else if (value === "Payments for getting a job offer") {
            return (
                <React.Fragment>
                    <div>When a person gets or applies for Job Start Payment but does not qualify for this. This grant is only available for people aged 16 to 24, or 16 to 25 if they're a care leaver.</div>
                </React.Fragment>
            )
        } else if (value === "Receiving benefits in Scotland but not living in Scotland") {
            return (
                <React.Fragment>
                    <div>When a person is getting specific benefits from Social Security Scotland while living in a country outside of Scotland. For example, the rest of the United Kingdom or abroad, such as France or Canada.</div>
                </React.Fragment>
            )
        }
    }
}

/**
 *
 * @param name
 * @param value
 * @returns {boolean}
 */
function disableCheckBox(name, value) {

    if (name === 'suspectedPersonRequiredInformation') {
        if (value === 'Name' || value === 'City or Town') {
            return true
        }
    }
    return false
}

export default CheckBox;