import React from "react";

import CloseIcon from "@material-ui/icons/Close";

/**
 * Custom component for use in cases of children dynamic list (child benefits section - step13.js)
 */
class BenefitCaringChild extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.id || '1',
            knowDetails: props.knowDetails || '',
            firstName: props.firstName || '',
            surname: props.surname || '',
            maxLengthForText: props.maxLengthForText || '',
            listName: props.listName || '',
            listLength: props.listLength || '',
            handleChildRemoval: props.handleChildRemoval,
            handleObjectInsideListChange: props.handleObjectInsideListChange,
            autoFocusYes: props.autoFocusYes === 'reportedPersonBenefitPaymentForCaringChildren.'.concat(props.id).concat('.knowDetailsYes'),
            autoFocusNo: props.autoFocusNo === 'reportedPersonBenefitPaymentForCaringChildren.'.concat(props.id).concat('.knowDetailsNo')
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.firstName !== prevState.firstName) {
            return {firstName: nextProps.firstName}
        } else if (nextProps.surname !== prevState.surname) {
            return {surname: nextProps.surname}
        } else if (nextProps.knowDetails !== prevState.knowDetails) {
            return {knowDetails: nextProps.knowDetails}
        } else if (nextProps.listLength !== prevState.listLength) {
            return {listLength: nextProps.listLength}
        } else {
            return null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.firstName !== this.state.firstName) {
            let first_ = this.state.firstName
            this.setState({
                firstName: first_
            })
        } else if (prevState.surname !== this.state.surname) {
            let last_ = this.state.surname
            this.setState({
                surname: last_
            })
        } else if (prevState.knowDetails !== this.state.knowDetails) {
            let knowDetails_ = this.state.knowDetails
            this.setState({
                knowDetails: knowDetails_
            })
        } else if (prevState.listLength !== this.state.listLength) {
            let divToFocus
            /*if (prevState.listLength > this.state.listLength) {
                divToFocus = "reportedPersonBenefitPaymentForCaringChildren-add-child"
            } else {
                divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".knowDetailsYes")
            }*/
            if (prevState.listLength > this.state.listLength) {
                if (this.state.knowDetails === 'Yes' || this.state.knowDetails === '') {
                    divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".knowDetailsYes")
                } else {
                    divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".knowDetailsNo")
                }
            } else {
                divToFocus = this.state.listName.concat(".").concat(this.state.listLength).concat(".knowDetailsYes")
            }
            document.getElementById(divToFocus).focus()
        }
    }

    /**
     * NOTICE the following is commented since it will be deprecated from version 17 and on
     *      , getDerivedStateFromProps & componentDidUpdate will be used instead
     */
    /*UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.firstName !== this.props.firstName) {
            this.setState({
                firstName: nextProps.firstName
            })
        }
    }*/

    render() {
        return (
            <React.Fragment>

                <div className="mobile-grid">
                    <h2 className="h2--child-repeater">Child {this.state.id}</h2>

                    <div hidden={this.state.listLength <= 1}>
                        <button type="button"
                                className="link--button remove-child--button"
                                name="reportedPersonBenefitPaymentForCaringChildrenRemove"
                                value={this.state.id}
                                onClick={this.state.handleChildRemoval}>
                            <CloseIcon className="remove-child--button-icon" /> remove child <span className="visually-hidden">{this.state.id}</span>
                        </button>
                    </div>
                </div>

                <fieldset aria-labelledby={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetails")} className="vertical-inputs">
                    <legend id={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetails")}>Do you know the name of the child?</legend>
                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetails")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetailsYes")}
                           value="Yes"
                           checked={this.state.knowDetails === 'Yes'}
                           autoFocus={this.state.autoFocusYes}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetailsYes")} className="form-radio">Yes</label>

                    <input type="radio"
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetails")}
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetailsNo")}
                           value="No"
                           checked={this.state.knowDetails === 'No'}
                           autoFocus={this.state.autoFocusNo}
                           onChange={this.state.handleObjectInsideListChange} />
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".knowDetailsNo")} className="form-radio">No</label>
                </fieldset>
                <br />

                <div hidden={this.state.knowDetails !== 'Yes'}>

                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")} className="form-text">First name</label>
                    <input type="text"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".firstName")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.firstName}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>
                    <label htmlFor={this.state.listName.concat(".").concat(this.state.id).concat(".surname")} className="form-text">Surname</label>
                    <input type="text"
                           id={this.state.listName.concat(".").concat(this.state.id).concat(".surname")}
                           name={this.state.listName.concat(".").concat(this.state.id).concat(".surname")}
                           autoComplete="off"
                           maxLength={this.state.maxLengthForText}
                           value={this.state.surname}
                           onChange={this.state.handleObjectInsideListChange} />
                    <br/>

                    <br/>

                </div>

                <hr className="repeater-component" />

            </React.Fragment>
        )
    }
}

export default BenefitCaringChild