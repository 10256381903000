import React from 'react';

/**
 * Component representing the speed bump page for ADP/CDP split question
 *  , shows up when 'Report a false or doubtful disability claim for an adult or a child' is selected
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function Step6(props) {

    if (props.currentStep !== 6) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-6">
                <h1>Is this report relating to a child disability?</h1>
                <div className={ props.errorClass.childDisabilityUnder18 === 'error' ? "govuk-form-group govuk-form-group--error" : "govuk-form-group"}>
                    <fieldset aria-labelledby="childDisabilityUnder18" className="vertical-inputs">
                        <legend id="childDisabilityUnder18">Anyone under the age of 18?</legend>

                        {
                            props.errorClass.childDisabilityUnder18 === 'error' ?
                                <span id="childDisabilityUnder18-error" className="govuk-error-message">
                                    <span className="govuk-visually-hidden">Error:</span> Select yes if the child related to the disability benefit is under 18 years old
                                </span>
                                : null
                        }

                        <input type="radio"
                               name="childDisabilityUnder18"
                               id="childDisabilityUnder18Yes"
                               value="Yes"
                               checked={props.childDisabilityUnder18 === 'Yes'}
                               className={props.errorClass.childDisabilityUnder18}
                               onChange={props.handleChange} />
                        <label htmlFor="childDisabilityUnder18Yes" className="form-radio">Yes</label>
                        <input type="radio"
                               name="childDisabilityUnder18"
                               id="childDisabilityUnder18No"
                               value="No"
                               checked={props.childDisabilityUnder18 === 'No'}
                               className={props.errorClass.childDisabilityUnder18}
                               onChange={props.handleChange} />
                        <label htmlFor="childDisabilityUnder18No" className="form-radio">No</label>
                    </fieldset>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Step6;