import React from 'react';

/**
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function Step21(props) {

    if (props.currentStep !== 21) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-21">

                <h1>Additional information about this report</h1>
                <div>
                    <p>All the information you share with us is anonymous and confidential.</p>
                    <p>Please provide details of how you learned the information you gave in this report. If you selected more than one suspected benefit fraud, please provide details about all of them.</p>
                </div>

                <br/>
                <label htmlFor="reportAdditionalInformation.howDoYouKnowTheInformation" className="form-text">How do you know the information in this report?</label>
                <textarea name="reportAdditionalInformation.howDoYouKnowTheInformation"
                          id="reportAdditionalInformation.howDoYouKnowTheInformation"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportAdditionalInformation.howDoYouKnowTheInformation}
                          autoFocus={props.focusElementId === "reportAdditionalInformation.howDoYouKnowTheInformation"}
                          onChange={props.handleObjectChange} />
                <br/>

                <br/>
                <label htmlFor="reportAdditionalInformation.whenDidYouFirstLearnTheInformation" className="form-text">When did you first learn of this information?</label>
                <textarea name="reportAdditionalInformation.whenDidYouFirstLearnTheInformation"
                          id="reportAdditionalInformation.whenDidYouFirstLearnTheInformation"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportAdditionalInformation.whenDidYouFirstLearnTheInformation}
                          autoFocus={props.focusElementId === "reportAdditionalInformation.whenDidYouFirstLearnTheInformation"}
                          onChange={props.handleObjectChange} />
                <br/>

                <br/>
                <label htmlFor="reportAdditionalInformation.whenDidYouLastKnowItToBeTrue" className="form-text">If you have known this information for some time, when did you last know it to be true?</label>
                <textarea name="reportAdditionalInformation.whenDidYouLastKnowItToBeTrue"
                          id="reportAdditionalInformation.whenDidYouLastKnowItToBeTrue"
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportAdditionalInformation.whenDidYouLastKnowItToBeTrue}
                          autoFocus={props.focusElementId === "reportAdditionalInformation.whenDidYouLastKnowItToBeTrue"}
                          onChange={props.handleObjectChange} />
                <br/>

                <br/>
                <fieldset aria-labelledby="reportAdditionalInformation.onlyPersonWhoKnowsTheInformation" className="vertical-inputs">

                    <legend id="reportAdditionalInformation.onlyPersonWhoKnowsTheInformation">Apart from the person(s) you reported, are you the only person who knows this information?</legend>

                    <input type="radio"
                           name="reportAdditionalInformation.onlyPersonWhoKnowsTheInformation"
                           id="reportAdditionalInformation.onlyPersonWhoKnowsTheInformationYes"
                           value="Yes"
                           checked={props.reportAdditionalInformation.onlyPersonWhoKnowsTheInformation === 'Yes'}
                           autoFocus={props.focusElementId === "reportAdditionalInformation.onlyPersonWhoKnowsTheInformationYes"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportAdditionalInformation.onlyPersonWhoKnowsTheInformationYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportAdditionalInformation.onlyPersonWhoKnowsTheInformation"
                           id="reportAdditionalInformation.onlyPersonWhoKnowsTheInformationNo"
                           value="No"
                           checked={props.reportAdditionalInformation.onlyPersonWhoKnowsTheInformation === 'No'}
                           autoFocus={props.focusElementId === "reportAdditionalInformation.onlyPersonWhoKnowsTheInformationNo"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportAdditionalInformation.onlyPersonWhoKnowsTheInformationNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportAdditionalInformation.onlyPersonWhoKnowsTheInformation"
                           id="reportAdditionalInformation.onlyPersonWhoKnowsTheInformationDoNotKnow"
                           value="I don't know"
                           checked={props.reportAdditionalInformation.onlyPersonWhoKnowsTheInformation === 'I don\'t know'}
                           autoFocus={props.focusElementId === "reportAdditionalInformation.onlyPersonWhoKnowsTheInformationDoNotKnow"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportAdditionalInformation.onlyPersonWhoKnowsTheInformationDoNotKnow" className="form-radio">I don't know</label>

                </fieldset>

                <br/>

                <fieldset aria-labelledby="reportAdditionalInformation.happyToActOnInformationProvided" className="vertical-inputs">

                    <legend id="reportAdditionalInformation.happyToActOnInformationProvided">Are you happy for us to act on the information you provided?</legend>

                    <input type="radio"
                           name="reportAdditionalInformation.happyToActOnInformationProvided"
                           id="reportAdditionalInformation.happyToActOnInformationProvidedYes"
                           value="Yes"
                           checked={props.reportAdditionalInformation.happyToActOnInformationProvided === 'Yes'}
                           autoFocus={props.focusElementId === "reportAdditionalInformation.happyToActOnInformationProvidedYes"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportAdditionalInformation.happyToActOnInformationProvidedYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportAdditionalInformation.happyToActOnInformationProvided"
                           id="reportAdditionalInformation.happyToActOnInformationProvidedNo"
                           value="No"
                           checked={props.reportAdditionalInformation.happyToActOnInformationProvided === 'No'}
                           autoFocus={props.focusElementId === "reportAdditionalInformation.happyToActOnInformationProvidedNo"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportAdditionalInformation.happyToActOnInformationProvidedNo" className="form-radio">No</label>

                </fieldset>

                <br/>

            </div>
        </React.Fragment>
    )
}

export default Step21;