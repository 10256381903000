import React from 'react';

import './../css/collapsible.css';


/**
 * Custom component for use in cases of 'Read More' collapsible sections
 */
class Collapsible extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.togglePanel = this.togglePanel.bind(this);
    }

    togglePanel(e){
        this.setState({open: !this.state.open})
    }

    componentDidUpdate(){}

    render() {
        return (
            <div>
                <hr />
                <details className="govuk-details" data-module="govuk-details">
                    <summary className="govuk-details__summary">
                      <span className="govuk-details__summary-text">
                        {this.props.title}
                      </span>
                    </summary>
                    <div className="govuk-details__text">
                      {this.props.children}
                    </div>
                </details>
            </div>
        );
    }
}

export default Collapsible;