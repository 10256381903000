import React from 'react';

/**
 * Component used - as a helper utility - for info messages coming from specific answers in some steps
 *      (e.g. see 'Page three: Country check -> Do not Know' at https://vtyqck.axshare.com/)
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function SubStepInfo(props) {

    /**
     * used to redirect to DWP homepage
     */
    const onDWPWebsiteClick = () => {
        window.open("https://www.gov.uk/report-benefit-fraud", "_self");
    }

    /**
     * NOTE we will add all cases of steps that might have that subStepInfo
     */
    if (props.currentStep === 1) {

        return (
            <React.Fragment>
                <div>
                    <h2>Other ways you can report fraud to Social Security Scotland</h2>
                    <p>You can report fraud by telephone on the Freephone number 0800 158 2071.<br/>Or by post using the address below:</p>
                    <p>
                        Social Security Scotland<br/>
                        PO Box 10299<br/>
                        Dundee<br/>
                        DD1 9FT
                    </p>
                </div>
            </React.Fragment>
        )

    } else if (props.currentStep === 3 && props.personReceivingOrReceivedBenefit !== 'Yes') {

        if (props.personReceivingOrReceivedBenefit === 'No') {

            return (
                <React.Fragment>
                    <br/>
                    <p>You can report this case to the Department for Work and Pensions (DWP) in one of the following ways:</p>
                    {/*<p><a target="_self" rel="noopener noreferrer" href="https://www.gov.uk/report-benefit-fraud">Online at DWP website</a></p>*/}
                    <button className="button--link" type="button" onClick={onDWPWebsiteClick}>Online at DWP website</button>
                    <p><strong>By phone:</strong> Call free on <a href="tel:+440800854440">0800 854 440</a></p>
                    <p><strong>By textphone:</strong> <a href="tel:+4408003280512">0800 328 0512</a></p>
                    <p><strong>In writing to:</strong><br />
                       National Benefit Fraud Hotline<br />
                       Mail Handling Site A<br />
                       Wolverhampton<br />
                       WV98 2BP</p>
                </React.Fragment>
            )
        } else if (props.personReceivingOrReceivedBenefit === 'I don\'t know') {

            return (
                <React.Fragment>
                    <br/>
                    <p>If the person lives in Scotland, please continue with the report.</p>
                    <button className="button--link" type="button"
                            name="currentStep"
                            value="4"
                            onClick={props._alternativeNext}>
                        Continue
                    </button>
                    <br/>
                    <p>If the person lives outside of Scotland, this is a report for the Department for Work and Pensions (DWP). Outside of Scotland means England, Wales or Northern Ireland. You can report this to them in one of the following ways:</p>
                    {/*<p><a target="_self" rel="noopener noreferrer" href="https://www.gov.uk/report-benefit-fraud">Online at DWP website</a></p>*/}
                    <button className="button--link" type="button" onClick={onDWPWebsiteClick}>Online at DWP website</button>
                    <p><strong>By phone:</strong> Call free on <a href="tel:+440800854440">0800 854 440</a></p>
                    <p><strong>By textphone:</strong> <a href="tel:+4408003280512">0800 328 0512</a></p>
                    <p><strong>In writing to:</strong><br />
                       National Benefit Fraud Hotline<br />
                       Mail Handling Site A<br />
                       Wolverhampton<br />
                       WV98 2BP</p>

                </React.Fragment>
            )
        }

    } else if (props.currentStep === 5 && props.carerBetween16And18 === 'No') {

        return (
            <React.Fragment>
                <br/>
                <p>Please report this to the Department for Work and Pensions (DWP) in one of the following ways:</p>
                <p><a target="_self" rel="noopener noreferrer" href="https://www.gov.uk/report-benefit-fraud">Online at DWP website</a></p>
                {/*<button className="button--link" type="button" onClick={onDWPWebsiteClick}>Online at DWP website</button>*/}
                <p><strong>By phone:</strong> Call free on <a href="tel:+440800854440">0800 854 440</a></p>
                <p><strong>By textphone:</strong> <a href="tel:+4408003280512">0800 328 0512</a></p>
                <p><strong>In writing to:</strong><br />
                       National Benefit Fraud Hotline<br />
                       Mail Handling Site A<br />
                       Wolverhampton<br />
                       WV98 2BP</p>
            </React.Fragment>
        )

    }

    return null;
}

export default SubStepInfo;