import React from 'react';

/**
 * Component representing 'Job Start Payment'
 *      , it shows up when 'Payments for getting a job offer' is selected in step4.js
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
function Step17(props) {

    if (props.currentStep !== 17) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-17">

                <h1>Job Start Payment</h1>
                <div>
                    <p>This section applies to a person who gets or applies for Job Start Payment but may not qualify for this.</p>
                </div>

                <br/>
                <fieldset aria-labelledby="reportedPersonJobStartPayment.currentlyEmployed" className="vertical-inputs">

                    <legend id="reportedPersonJobStartPayment.currentlyEmployed">Are they currently employed?</legend>

                    <input type="radio"
                           name="reportedPersonJobStartPayment.currentlyEmployed"
                           id="reportedPersonJobStartPayment.currentlyEmployedYes"
                           value="Yes"
                           checked={props.reportedPersonJobStartPayment.currentlyEmployed === 'Yes'}
                           autoFocus={props.focusElementId === "reportedPersonJobStartPayment.currentlyEmployedYes"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonJobStartPayment.currentlyEmployedYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonJobStartPayment.currentlyEmployed"
                           id="reportedPersonJobStartPayment.currentlyEmployedNo"
                           value="No"
                           checked={props.reportedPersonJobStartPayment.currentlyEmployed === 'No'}
                           autoFocus={props.focusElementId === "reportedPersonJobStartPayment.currentlyEmployedNo"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonJobStartPayment.currentlyEmployedNo" className="form-radio">No</label>

                    <input type="radio"
                           name="reportedPersonJobStartPayment.currentlyEmployed"
                           id="reportedPersonJobStartPayment.currentlyEmployedDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonJobStartPayment.currentlyEmployed === 'I don\'t know'}
                           autoFocus={props.focusElementId === "reportedPersonJobStartPayment.currentlyEmployedDoNotKnow"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonJobStartPayment.currentlyEmployedDoNotKnow" className="form-radio">I don't know</label>

                </fieldset>

                <div hidden={props.reportedPersonJobStartPayment.currentlyEmployed !== 'Yes'}>

                    <br />
                    <label htmlFor="reportedPersonJobStartPayment.whereDoTheyWork" className="form-text">Where do they work?</label>
                    <p className="hint">For example, name and address of the company or employer.</p>
                    <textarea name="reportedPersonJobStartPayment.whereDoTheyWork"
                              id="reportedPersonJobStartPayment.whereDoTheyWork"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonJobStartPayment.whereDoTheyWork}
                              autoFocus={props.focusElementId === "reportedPersonJobStartPayment.whereDoTheyWork"}
                              onChange={props.handleObjectChange} />

                </div>

                <div hidden={props.reportedPersonJobStartPayment.currentlyEmployed !== 'No' && props.reportedPersonJobStartPayment.currentlyEmployed !== 'I don\'t know'}>

                    <br />
                    <fieldset aria-labelledby="reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months" className="vertical-inputs">

                        <legend id="reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months">Have they been employed in the last 6 months?</legend>

                        <input type="radio"
                               name="reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months"
                               id="reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsYes"
                               value="Yes"
                               checked={props.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months === 'Yes'}
                               autoFocus={props.focusElementId === "reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsYes"}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsYes" className="form-radio">Yes</label>

                        <input type="radio"
                               name="reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months"
                               id="reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsNo"
                               value="No"
                               checked={props.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months === 'No'}
                               autoFocus={props.focusElementId === "reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsNo"}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsNo" className="form-radio">No</label>

                        <input type="radio"
                               name="reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months"
                               id="reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsDoNotKnow"
                               value="I don't know"
                               checked={props.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months === 'I don\'t know'}
                               autoFocus={props.focusElementId === "reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsDoNotKnow"}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonJobStartPayment.haveBeenEmployedTheLast6MonthsDoNotKnow" className="form-radio">I don't know</label>

                    </fieldset>

                    <div hidden={props.reportedPersonJobStartPayment.haveBeenEmployedTheLast6Months !== 'Yes'}>

                        <br />
                        <label htmlFor="reportedPersonJobStartPayment.whereDidTheyWork" className="form-text">Where did they work?</label>
                        <p className="hint">For example, name and address of the company or employer.</p>
                        <textarea name="reportedPersonJobStartPayment.whereDidTheyWork"
                                  id="reportedPersonJobStartPayment.whereDidTheyWork"
                                  rows={10} cols={100}
                                  maxLength={props.maxLengthForTextArea}
                                  value={props.reportedPersonJobStartPayment.whereDidTheyWork}
                                  autoFocus={props.focusElementId === "reportedPersonJobStartPayment.whereDidTheyWork"}
                                  onChange={props.handleObjectChange} />

                    </div>

                </div>

                <div hidden={props.reportedPersonJobStartPayment.currentlyEmployed !== 'Yes'
                && props.reportedPersonJobStartPayment.currentlyEmployed !== 'No'
                && props.reportedPersonJobStartPayment.currentlyEmployed !== 'I don\'t know'}>

                    <br />
                    <label htmlFor="reportedPersonJobStartPayment.extraInfo" className="form-text">Is there anything else you think we should know?</label>
                    <p className="hint">For example, dates when they started or stopped working, how were they paid, who else was involved.</p>
                    <textarea name="reportedPersonJobStartPayment.extraInfo"
                              id="reportedPersonJobStartPayment.extraInfo"
                              rows={10} cols={100}
                              maxLength={props.maxLengthForTextArea}
                              value={props.reportedPersonJobStartPayment.extraInfo}
                              autoFocus={props.focusElementId === "reportedPersonJobStartPayment.extraInfo"}
                              onChange={props.handleObjectChange} />
                </div>

            </div>
        </React.Fragment>
    )
}

export default Step17;