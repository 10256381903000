import React from 'react';

/**
 * Component representing first page (see 'Page one (landing): Benefit Fraud' at https://65nfjh.axshare.com/)
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step1(props) {

    if (props.currentStep !== 1) {
        return null
    }

    return (
        <div className="step step-1">
            <h1>Report benefit fraud in Scotland</h1>
            <p>Benefit fraud is when a person has applied for, or is getting, a benefit they know they are not supposed to receive.</p>
            <p>This happens if a person:</p>
            <ul>
                <li>gives false details when applying for benefits</li>
                <li>does not tell the relevant organisation about changes affecting the amount of benefits to be paid.</li>
            </ul>
            <p>These agencies can include:</p>
            <ul>
                <li>Social Security Scotland</li>
                <li>His Majesty's Revenue and Customs (HMRC)</li>
                <li>Department for Work and Pensions (DWP)</li>
                <li>For some benefits, local councils</li>
            </ul>
            <p>If a person makes an honest mistake when applying for a benefit, this is not the same as fraud.</p>
            <p>An <b>honest mistake</b> can be when a person mistakenly completes an application incorrectly. Or, if they accidentally provide the wrong information asked for.</p>
            <h2>Reporting fraud against Scottish benefits and payments</h2>
            <p>Use this report to tell us about fraud against benefits and payments made by Social Security Scotland.</p>
            <p>This includes payments:</p>
            <ul>
                <li>for funerals</li>
                <li>to support people with children, pregnant people and young people providing care</li>
            </ul>
            <h2>What happens when you report suspected benefit fraud</h2>
            <p>Your report will be anonymous. You do not provide any of your own personal details when reporting suspected fraud.</p>
            <p>After you have completed the report, we will then review the information you provide.</p>
            <p>We cannot tell you the outcome of any fraud investigation because of privacy reasons.</p>
        </div>
    )
}

export default Step1;