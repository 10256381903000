import React from 'react';

/**
 * Component representing 'Receiving benefits while living abroad' part of the fourth page (see 'Page four: Full Form' at https://10t3ux.axshare.com/)
 *      , it shows up when 'Receiving benefits in Scotland but not living in Scotland' benefit type is selected in step4.js
 *
 * @param props
 * @returns {null|*}
 * @constructor
 */
function Step20(props) {

    if (props.currentStep !== 20) {
        return null
    }

    return (
        <React.Fragment>
            <div className="step step-20">

                <h1>Receiving benefits in Scotland but not living in Scotland</h1>
                <div>
                    <p>This section applies to a person who gets specific benefits from Social Security Scotland, while living in a country outside of Scotland. For example, the rest of the United Kingdom or abroad, such as France or Canada.</p>
                    <p>If you do not know the answer to a question, leave it and move on to the next question.</p>
                </div>

                <br/>
                <label htmlFor="reportedPersonLivingOutsideScotland.country" className="form-text">What country is the person living in?</label>
                <select name="reportedPersonLivingOutsideScotland.country"
                        id="reportedPersonLivingOutsideScotland.country"
                        value={props.reportedPersonLivingOutsideScotland.country}
                        autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.country"}
                        onChange={props.handleObjectChange}>
                    <option value="I don't know">I don't know</option>
                    <option value="England">England</option>
                    <option value="Northern Ireland">Northern Ireland</option>
                    <option value="Wales">Wales</option>
                    {
                        props.countries.map((country, index) =>
                            <option key={"countries".concat(index)} value={country.label}>{country.label}</option>)
                    }
                </select>

                <p></p>
                <fieldset aria-labelledby="reportedPersonLivingOutsideScotland.movePermanentOrTemporary" className="vertical-inputs">
                    <legend id="reportedPersonLivingOutsideScotland.movePermanentOrTemporary">Do they live in this country permanently or temporarily?</legend>
                    <input type="radio"
                           name="reportedPersonLivingOutsideScotland.movePermanentOrTemporary"
                           id="reportedPersonLivingOutsideScotland.movePermanentOrTemporaryPermanent"
                           value="Permanently"
                           checked={props.reportedPersonLivingOutsideScotland.movePermanentOrTemporary === 'Permanently'}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.movePermanentOrTemporaryPermanent"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonLivingOutsideScotland.movePermanentOrTemporaryPermanent" className="form-radio">Permanently</label>

                    <input type="radio"
                           name="reportedPersonLivingOutsideScotland.movePermanentOrTemporary"
                           id="reportedPersonLivingOutsideScotland.movePermanentOrTemporaryTemporary"
                           value="Temporarily"
                           checked={props.reportedPersonLivingOutsideScotland.movePermanentOrTemporary === 'Temporarily'}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.movePermanentOrTemporaryTemporary"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonLivingOutsideScotland.movePermanentOrTemporaryTemporary" className="form-radio">Temporarily</label>

                    <input type="radio"
                           name="reportedPersonLivingOutsideScotland.movePermanentOrTemporary"
                           id="reportedPersonLivingOutsideScotland.movePermanentOrTemporaryDoNotKnow"
                           value="I don't know"
                           checked={props.reportedPersonLivingOutsideScotland.movePermanentOrTemporary === 'I don\'t know'}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.movePermanentOrTemporaryDoNotKnow"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonLivingOutsideScotland.movePermanentOrTemporaryDoNotKnow" className="form-radio">I don't know</label>
                </fieldset>

                <br/>
                <fieldset aria-labelledby="reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad" className="vertical-inputs">
                    <legend id="reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad">Do you know the person’s current address?</legend>
                    <input type="radio"
                           name="reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad"
                           id="reportedPersonLivingOutsideScotland.knowCurrentAddressAbroadYes"
                           value="Yes"
                           checked={props.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad === 'Yes'}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.knowCurrentAddressAbroadYes"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonLivingOutsideScotland.knowCurrentAddressAbroadYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad"
                           id="reportedPersonLivingOutsideScotland.knowCurrentAddressAbroadNo"
                           value="No"
                           checked={props.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad === 'No'}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.knowCurrentAddressAbroadNo"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonLivingOutsideScotland.knowCurrentAddressAbroadNo" className="form-radio">No</label>
                </fieldset>

                <div hidden={props.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad !== 'Yes'}>

                    <br/>
                    <fieldset aria-labelledby="reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad" className="vertical-inputs">
                        <legend id="reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad">Have you already given their address abroad at the start of the form?</legend>
                        <input type="radio"
                               name="reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad"
                               id="reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroadYes"
                               value="Yes"
                               checked={props.reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad === 'Yes'}
                               autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroadYes"}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroadYes" className="form-radio">Yes</label>

                        <input type="radio"
                               name="reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad"
                               id="reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroadNo"
                               value="No"
                               checked={props.reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad === 'No'}
                               autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroadNo"}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroadNo" className="form-radio">No</label>
                    </fieldset>

                </div>

                <div hidden={props.reportedPersonLivingOutsideScotland.knowCurrentAddressAbroad !== 'Yes'
                                || props.reportedPersonLivingOutsideScotland.alreadyGivenAddressAbroad !== 'No'}>

                    <br/>
                    <label htmlFor="reportedPersonLivingOutsideScotland.addressAbroadHouseNumber" className="form-text">House number or Building name</label>
                    <input type="text"
                           id="reportedPersonLivingOutsideScotland.addressAbroadHouseNumber"
                           name="reportedPersonLivingOutsideScotland.addressAbroadHouseNumber"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressAbroadHouseNumber"}
                           value={props.reportedPersonLivingOutsideScotland.addressAbroadHouseNumber}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonLivingOutsideScotland.addressAbroadStreetName" className="form-text">Street name</label>
                    <input type="text"
                           id="reportedPersonLivingOutsideScotland.addressAbroadStreetName"
                           name="reportedPersonLivingOutsideScotland.addressAbroadStreetName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressAbroadStreetName"}
                           value={props.reportedPersonLivingOutsideScotland.addressAbroadStreetName}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonLivingOutsideScotland.addressAbroadCity" className="form-text">City or Town</label>
                    <input type="text"
                           id="reportedPersonLivingOutsideScotland.addressAbroadCity"
                           name="reportedPersonLivingOutsideScotland.addressAbroadCity"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressAbroadCity"}
                           value={props.reportedPersonLivingOutsideScotland.addressAbroadCity}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonLivingOutsideScotland.addressAbroadPostcode" className="form-text">Postcode</label>
                    <input type="text"
                           className="postcode"
                           id="reportedPersonLivingOutsideScotland.addressAbroadPostcode"
                           name="reportedPersonLivingOutsideScotland.addressAbroadPostcode"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressAbroadPostcode"}
                           value={props.reportedPersonLivingOutsideScotland.addressAbroadPostcode}
                           onChange={props.handleObjectChange} />
                    <br/>

                </div>

                <br/>
                <label htmlFor="reportedPersonLivingOutsideScotland.knowWhenTheyMoved" className="form-text">If you know when they moved to this address, add this in the box below</label>
                <input type="text"
                       maxLength={props.maxLengthForText}
                       name="reportedPersonLivingOutsideScotland.knowWhenTheyMoved"
                       id="reportedPersonLivingOutsideScotland.knowWhenTheyMoved"
                       autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.knowWhenTheyMoved"}
                       value={props.reportedPersonLivingOutsideScotland.knowWhenTheyMoved}
                       onChange={props.handleObjectChange} />

                <br/><br/>
                <fieldset aria-labelledby="reportedPersonLivingOutsideScotland.knowAddressInScotland" className="vertical-inputs">
                    <legend id="reportedPersonLivingOutsideScotland.knowAddressInScotland">Do you know their address in Scotland?</legend>
                    <input type="radio"
                           name="reportedPersonLivingOutsideScotland.knowAddressInScotland"
                           id="reportedPersonLivingOutsideScotland.knowAddressInScotlandYes"
                           value="Yes"
                           checked={props.reportedPersonLivingOutsideScotland.knowAddressInScotland === 'Yes'}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.knowAddressInScotlandYes"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonLivingOutsideScotland.knowAddressInScotlandYes" className="form-radio">Yes</label>

                    <input type="radio"
                           name="reportedPersonLivingOutsideScotland.knowAddressInScotland"
                           id="reportedPersonLivingOutsideScotland.knowAddressInScotlandNo"
                           value="No"
                           checked={props.reportedPersonLivingOutsideScotland.knowAddressInScotland === 'No'}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.knowAddressInScotlandNo"}
                           onChange={props.handleObjectChange} />
                    <label htmlFor="reportedPersonLivingOutsideScotland.knowAddressInScotlandNo" className="form-radio">No</label>
                </fieldset>

                <div hidden={props.reportedPersonLivingOutsideScotland.knowAddressInScotland !== 'Yes'}>

                    <br/>
                    <fieldset aria-labelledby="reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven" className="vertical-inputs">
                        <legend id="reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven">Have you already given their address in Scotland at the start of the form?</legend>
                        <input type="radio"
                               name="reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven"
                               id="reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGivenYes"
                               value="Yes"
                               checked={props.reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven === 'Yes'}
                               autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGivenYes"}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGivenYes" className="form-radio">Yes</label>

                        <input type="radio"
                               name="reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven"
                               id="reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGivenNo"
                               value="No"
                               checked={props.reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven === 'No'}
                               autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGivenNo"}
                               onChange={props.handleObjectChange} />
                        <label htmlFor="reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGivenNo" className="form-radio">No</label>
                    </fieldset>

                </div>

                <div hidden={props.reportedPersonLivingOutsideScotland.knowAddressInScotland !== 'Yes'
                                || props.reportedPersonLivingOutsideScotland.addressInScotlandAlreadyGiven !== 'No'}>

                    <br/>
                    <label htmlFor="reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber" className="form-text">House number or Building name</label>
                    <input type="text"
                           id="reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber"
                           name="reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber"}
                           value={props.reportedPersonLivingOutsideScotland.addressInScotlandHouseNumber}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonLivingOutsideScotland.addressInScotlandStreetName" className="form-text">Street name</label>
                    <input type="text"
                           id="reportedPersonLivingOutsideScotland.addressInScotlandStreetName"
                           name="reportedPersonLivingOutsideScotland.addressInScotlandStreetName"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressInScotlandStreetName"}
                           value={props.reportedPersonLivingOutsideScotland.addressInScotlandStreetName}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonLivingOutsideScotland.addressInScotlandCity" className="form-text">City or Town</label>
                    <input type="text"
                           id="reportedPersonLivingOutsideScotland.addressInScotlandCity"
                           name="reportedPersonLivingOutsideScotland.addressInScotlandCity"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressInScotlandCity"}
                           value={props.reportedPersonLivingOutsideScotland.addressInScotlandCity}
                           onChange={props.handleObjectChange} />
                    <br/>

                    <br/>
                    <label htmlFor="reportedPersonLivingOutsideScotland.addressInScotlandPostcode" className="form-text">Postcode</label>
                    <input type="text"
                           className="postcode"
                           id="reportedPersonLivingOutsideScotland.addressInScotlandPostcode"
                           name="reportedPersonLivingOutsideScotland.addressInScotlandPostcode"
                           autoComplete="off"
                           maxLength={props.maxLengthForText}
                           autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.addressInScotlandPostcode"}
                           value={props.reportedPersonLivingOutsideScotland.addressInScotlandPostcode}
                           onChange={props.handleObjectChange} />
                    <br/>

                </div>

                <br/>
                <label htmlFor="reportedPersonLivingOutsideScotland.extraInfo" className="form-text">Use the box below to tell us any other details about this.</label>
                <textarea name="reportedPersonLivingOutsideScotland.extraInfo"
                          id="reportedPersonLivingOutsideScotland.extraInfo"
                          autoFocus={props.focusElementId === "reportedPersonLivingOutsideScotland.extraInfo"}
                          rows={10} cols={100}
                          maxLength={props.maxLengthForTextArea}
                          value={props.reportedPersonLivingOutsideScotland.extraInfo}
                          onChange={props.handleObjectChange} />

            </div>
        </React.Fragment>
    )
}

export default Step20;